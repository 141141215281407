import { httpClient } from 'providers/clients/httpClient';
import { BlockLayoutResponse } from 'utility/types/dataProvider';

export const blockLayoutProvider = {
  // Create new block layout for a URL
  createBlockLayout: async (
    map_id: string,
    url_id: string,
    body: BlockLayoutResponse,
  ): Promise<BlockLayoutResponse> => {
    try {
      const path = `/core/layout/${map_id}/${url_id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(body),
      });

      return data?.data;
    } catch (error) {
      console.error('[createBlockLayout] Error', error);
      throw error;
    }
  },

  // Retrieve the active block layout for a URL
  getActiveBlockLayout: async (
    map_id: string,
    url_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const path = `/core/layout/${map_id}/${url_id}/active/`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data;
    } catch (error) {
      console.error('[getActiveBlockLayout] Error', error);
      throw error;
    }
  },

  // Retrieve a block layout for a specific URL region
  getBlockLayoutForRegion: async (
    map_id: string,
    url_id: string,
    region_name: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const path = `/core/layout/${map_id}/${url_id}/active/${region_name}/`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data;
    } catch (error) {
      console.error('[getBlockLayoutForRegion] Error', error);
      throw error;
    }
  },

  publishBlockLayout: async (
    map_id: string,
    url_id: string,
    layout_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const path = `/core/layout/${map_id}/${url_id}/${layout_id}/publish/`;

      const { data } = await httpClient({
        url: path,
        method: 'PUT',
      });

      return data;
    } catch (error) {
      console.error('[publishBlockLayout] Error', error);
      throw error;
    }
  },
};
