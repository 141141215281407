import { httpClient } from 'providers/clients/httpClient';
import { UserWallet } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ USERWALLETS PROVIDER -------------
// -----------------------------------------------
export const userWalletsProvider = {
  getUserWalletData: async (
    uuid: string,
    { nextToken, limit }: { nextToken?: string | null; limit: number },
  ): Promise<UserWallet> => {
    try {
      const path = `/pay/wallet/${uuid}/search/`;

      const params = new URLSearchParams({
        ...(nextToken && { page: nextToken }),
        limit: limit.toString(),
      });

      const stringPath = `${path}?${params.toString()}`;

      const { data } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: UserWallet = data?.data;
      return result;
    } catch (error) {
      console.error('[getUserWalletData] Error:', error);
      throw error;
    }
  },

  addPaymentMethod: async (uuid: string, params: any): Promise<UserWallet> => {
    try {
      const path = `/pay/wallet/${uuid}/payment/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      const result: UserWallet = data?.data;
      return result;
    } catch (error) {
      console.error('[addPaymentMethod] Error:', error);
      throw error;
    }
  },

  removePaymentMethod: async (uuid: string, paymentId: string): Promise<void> => {
    try {
      const path = `/pay/wallet/${uuid}/payment/${paymentId}/`;

      await httpClient({
        url: path,
        method: 'DELETE',
      });

      const result = Promise.resolve();
      return result;
    } catch (error) {
      console.error('[removePaymentMethod] Error:', error);
      throw error;
    }
  },

  editPaymentMethod: async (
    uuid: string,
    paymentId: string,
    params: any,
  ): Promise<void> => {
    try {
      const path = `/pay/wallet/${uuid}/payment/${paymentId}/`;

      await httpClient({
        url: path,
        method: 'PUT',
        data: JSON.stringify(params),
      });

      const result = Promise.resolve();
      return result;
    } catch (error) {
      console.error('[editPaymentMethod] Error:', error);
      throw error;
    }
  },
};
