import { httpClient } from 'providers/clients/httpClient';
import { ApiErrorResponse, DefaultSavedSearchResult } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SAVED SEARCHES PROVIDER ----------
// -----------------------------------------------
export const savedSearchesProvider = {
  getDefaultSavedSearch: async (app?: string): Promise<DefaultSavedSearchResult> => {
    try {
      const path = `/core/savedsearch/default/${app}/`;

      const response = await httpClient({
        url: path,
      });

      const json = await response.data;
      return json; // Return the JSON payload as the default search
    } catch (error) {
      const typedError = error as ApiErrorResponse; // We need a ticket to fix this 404 behavior asap.
      // This endpoint considers 404 as a valid response as in no default search is set.
      // Return something custom to handle 404 specifically
      if (typedError?.axiosStatus === 404) {
        return {
          status: 404,
          data: {
            payload: undefined,
          },
        };
      } else {
        console.error('[getDefaultSavedSearch] Error:', error);
        throw error;
      }
    }
  },

  setDefaultSavedSearch: async (id: string): Promise<any | null> => {
    try {
      const path = `/core/savedsearch/default/editorial/${id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
      });

      return data;
    } catch (error) {
      console.error('[setDefaultSavedSearch] Error:', error);
      throw error;
    }
  },

  removeDefaultSavedSearch: async (): Promise<any | null> => {
    try {
      const path = `/core/savedsearch/default/editorial/`;

      const { data } = await httpClient({
        url: path,
        method: 'DELETE',
      });

      return data;
    } catch (error) {
      console.error('[removeDefaultSavedSearch] Error:', error);
      throw error;
    }
  },
};
