import {
  determineUniqueId,
  flattenObject,
  getResourceDetails,
  relatedContent,
  stripAttributes,
} from 'providers/functions';
import { DataProvider, fetchUtils, GetManyResult } from 'react-admin';
import { mergeDeep } from 'remeda';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// --------------- RA DATA PROVIDER --------------
// -----------------------------------------------

export const baseDataProvider: DataProvider = {
  getList: async (resource: string, params: any) => {
    try {
      const query = params?.query;
      const type = params?.type;
      const meta = params?.meta;
      const filter = params?.filter;
      const page = params?.pagination?.page;
      const perPage = params?.pagination?.perPage;
      const field = params?.sort?.field;
      const order = params?.sort?.order;

      let payload = {
        ...(query && { query: query }),
        ...(type && { type: type }),
        ...(typeof page === 'string' ? { page: page } : { page: (page - 1) * perPage }), // string for syndication endpoints (AP NEWS uses string cursor offsets)
        ...(perPage && { limit: perPage }),
        ...(order && { dir: order }),
        ...(field && { sort: field === 'id' ? 'uuid' : field }), // Use UUID for "sort by id" - note RA by default will set 'id' and 'asc' if not set.
        ...(meta && { meta: meta }),
        ...(filter && { ...filter }), // Spread filter object last into payload from RA InfListBase, etc.
      };

      const { baseResource, subResource, isGetList, additionalPayload, modifyPayload } =
        getResourceDetails(resource, params);

      if (modifyPayload) {
        payload = modifyPayload(payload);
      }

      // Flatten additional payload to bracket access syntax for query string encoding
      const bracketedAdditional = flattenObject(additionalPayload);

      payload = { ...payload, ...bracketedAdditional };

      const path = `/${baseResource}/${subResource}/${isGetList}?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      // Syndication endpoints return 'results' instead of 'items'
      const dataWithIds = (data.data.results || data.data.items).map((item: any) => ({
        ...item,
        id: determineUniqueId(item, resource),
      }));

      const formattedResult = {
        data: dataWithIds,
        total: data?.data?.total,
        pageInfo: {
          hasNextPage: Boolean(data.data.next),
          hasPreviousPage: Boolean(data.data.prev),
          nextPage: data.data.next,
          prevPage: data.data.prev,
        },
      };

      return formattedResult;
    } catch (error) {
      console.error('[getList] Error', error);
      throw error;
    }
  },

  getOne: async (resource: string, params: any) => {
    try {
      const { id, baseResource, subResource, additionalPayload, isGetOne } =
        getResourceDetails(resource, params);

      // Flatten additional payload to bracket access syntax for query string encoding
      const bracketedAdditional = flattenObject(additionalPayload);

      let payload = { ...bracketedAdditional };

      const path = `/${baseResource}/${subResource}/${
        isGetOne || id
      }?${fetchUtils.queryParameters(payload, stringifyOptions)}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const formattedResult = {
        data: { ...data, id: data?.data?.uuid },
        id: data?.data?.uuid,
      };
      return formattedResult;
    } catch (error) {
      console.error('[getOne] Error', error);
      throw error;
    }
  },

  getMany: async (resource: string, params: any) => {
    const { baseResource, subResource } = getResourceDetails(resource, params);
    const fetchSingleId = async (id: string) => {
      const path = `/${baseResource}/${subResource}/${id}/`;
      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });
      return data?.data;
    };

    try {
      const settledResponses = await Promise.allSettled(params.ids.map(fetchSingleId));
      const successfulResponses = settledResponses
        .filter((response) => response.status === 'fulfilled')
        .map((response) => (response as PromiseFulfilledResult<any>).value);
      const failedResponses = settledResponses
        .filter((response) => response.status === 'rejected')
        .map((response) => (response as PromiseRejectedResult).reason);
      // Log failed responses or handle them appropriately.
      if (failedResponses.length > 0) {
        console.warn('dataProvider.getMany - Some fetches failed:', failedResponses);
      }
      // If all fetches failed, throw an error
      if (successfulResponses.length === 0) {
        throw new Error('dataProvider.getMany - All fetches failed.');
      }

      // Only return the successful responses, any failed responses won't be included.
      const results: GetManyResult = {
        data: successfulResponses.map((item: any) => ({
          ...item,
          id: item.uuid,
        })),
      };

      return results;
    } catch (error) {
      console.error('[getMany] Error', error);
      throw error;
    }
  },

  getManyReference: async (resource: string, params: any) => {
    try {
      const { baseResource, subResource } = getResourceDetails(resource, params);
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let payload = {
        ...params.filter,
        [params.target]: params.id,
        _page: page,
        _limit: perPage,
        _sort: field,
        _order: order,
      };

      const path = `/${baseResource}/${subResource}/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const formattedResult = data?.data?.items?.map((item: any) => ({
        ...item,
        id: item?.uuid,
      }));
      return formattedResult;
    } catch (error) {
      console.error('[getManyReference] Error', error);
      throw error;
    }
  },

  create: async (resource: string, params: any) => {
    try {
      const { baseResource, subResource } = getResourceDetails(resource, params);

      const path = `/${baseResource}/${subResource}/`;

      let payload = {
        ...params.data,
      };

      // Process related content and strip TOC
      const relatedPayload = await relatedContent(payload);
      const finalPayload = await stripAttributes(relatedPayload);

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(finalPayload),
      });

      const formattedResult = {
        data: { ...data, id: data?.uuid },
      };
      return formattedResult;
    } catch (error) {
      console.error('[create] Error', error);
      throw error;
    }
  },

  update: async (resource: string, params: any) => {
    try {
      const { baseResource, subResource, additionalPayload } = getResourceDetails(
        resource,
        params,
      );

      const path = `/${baseResource}/${subResource}/${params.id}/`;

      let paramsData = {
        ...params.data,
      };

      let payload = mergeDeep(paramsData, additionalPayload);

      // Process related content and strip TOC
      const relatedPayload = await relatedContent(payload);
      const finalPayload = await stripAttributes(relatedPayload);

      const { data } = await httpClient({
        url: path,

        method: 'PUT',
        data: JSON.stringify(finalPayload),
      });

      const formattedResult = {
        data: { ...data, id: data?.uuid },
      };
      return formattedResult;
    } catch (error) {
      console.error('[update] Error', error);
      throw error;
    }
  },

  updateMany: async (resource: string, params: any) => {
    const { baseResource, subResource } = getResourceDetails(resource, params);
    try {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };

      const path = `/${baseResource}/${subResource}/?${fetchUtils.queryParameters(
        query,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: path,
        method: 'PATCH',
        data: JSON.stringify(params?.data),
      });

      const formattedResult = {
        data: data,
      };
      return formattedResult;
    } catch (error) {
      console.error('[updateMany] Error', error);
      throw error;
    }
  },

  delete: async (resource: string, params: any) => {
    const { baseResource, subResource } = getResourceDetails(resource, params);
    try {
      const path = `/${baseResource}/${subResource}/${params.id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'DELETE',
      });

      const formattedResult = {
        data: data,
      };
      return formattedResult;
    } catch (error) {
      console.error('[delete] Error', error);
      throw error;
    }
  },

  deleteMany: async (resource: string, params: any) => {
    const { baseResource, subResource } = getResourceDetails(resource, params);

    try {
      const path = `/${baseResource}/${subResource}/delete/`;
      const deleteObject = { uuid: params.ids };

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(deleteObject),
      });

      const formattedResult = {
        data: data,
      };

      return formattedResult;
    } catch (error) {
      console.error('[deleteMany] Error', error);
      throw error;
    }
  },
};
