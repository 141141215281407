import KeyIcon from '@mui/icons-material/Key';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { decodeJwt } from 'jose';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { bloxValues } from 'themes/constants';
import { emdash } from 'utility/constants';
import { capitalizeFirstLetter, clearPrefixedStorage } from 'utility/functions';
import { getSolidThemeGray } from 'utility/styles';
import { ApiErrorResponse } from 'utility/types/dataProvider';
import { create } from 'zustand';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts from '../../components/generic/ErrorAlerts';
import { isBoolean } from 'remeda';

/**
 * FILE NOTES:
 * Related Tickets: CMSADMIN-986 / CMSADMIN-1047 / CMSADMIN-1097
 * Logging can be enabled for oidc-client-ts for debug, uncomment the lines below, and put in index.tsx.
 * Additionally please see httpClient, checkAuth and checkError in CosmosRAProvidersContext
 * as there is some related functionality there regarding auth.
 *
 * import { Log } from 'oidc-client-ts';
 * Log.setLogger(console);
 * Log.setLevel(Log.DEBUG);
 *
 * Related Tickets / Docs / Known Issues:
 * https://authts.github.io/oidc-client-ts/classes/UserManagerEvents.html
 * https://authts.github.io/oidc-client-ts/classes/UserManager.html
 * https://authts.github.io/oidc-client-ts/classes/UserManagerSettingsStore.html
 * https://github.com/authts/react-oidc-context#:~:text=is%20still%20there%2C-,signinSilent,-%2D%20which%20handles%20renewing
 * https://github.com/authts/react-oidc-context?tab=readme-ov-file#adding-event-listeners
 * https://github.com/authts/react-oidc-context/issues/1324 - withAuthReq wrapper redirect
 * https://github.com/authts/react-oidc-context/issues/1253 - silent renew errors
 * https://github.com/authts/react-oidc-context/issues/390 - silent renew duplication
 * https://github.com/authts/oidc-client-ts/issues/644 - refresh token renew
 * https://github.com/authts/react-oidc-context/issues/1081 - other tabs logout
 * https://github.com/authts/oidc-client-ts/issues/543 - detecting events
 * https://github.com/authts/oidc-client-ts/issues/1648 - refresh token issues over duped tabs
 * https://github.com/authts/react-oidc-context/issues/1468 - multi tab state issues
 * https://stackoverflow.com/questions/75948978/react-query-fetches-stale-queries-with-expired-access-token
 * https://github.com/TanStack/query/issues/6734 - stale tokens
 * https://github.com/TanStack/query/discussions/931 - stale tokens
 *
 * The react-oidc-context library provides a withAuthenticationRequired
 * higher-order component to protect routes. However in some testing
 * it seems to have some issues with the states sometimes, showing a
 * loader flash when it shouldn't, not cleaning stale auth states.
 *
 * However it is a good idea to look at the source code of what it
 * does and how it determines to show the different loaders and states,
 * which we are mostly doing the same in WithAuthChecks wrapper.
 *
 * https://github.com/authts/react-oidc-context/blob/main/src/withAuthenticationRequired.tsx
 *
 * Note: looks like they copied most of auth0-react's withAuthenticationRequired
 * https://github.com/auth0/auth0-react/blob/main/src/with-authentication-required.tsx
 *
 *
 */

//----------------------------------------------------------------------
// Helper Functions and Constants
//----------------------------------------------------------------------

/**
 * Check if NODE_ENV is in development mode
 **/
export const isDevMode = () => {
  return process.env.NODE_ENV === 'development';
};

/**
 * Check if we are running with the REACT_APP_USE_ENV_VARS=true to allow
 * certain local builds to use local env vars for testing - CMSADMIN-1012
 **/
export const isUseEnvVars = () => {
  return process.env.REACT_APP_USE_ENV_VARS === 'true';
};

/** On Sign In Callback Custom Properties on User */
interface CustomUserStateProperties {
  restore_route?: string;
}

export interface EnvData {
  ENV?: string | undefined; // "development"
  API_SERVER_URL?: string | undefined; // "cms-api.us-corp-dev-3.vip.tndev.net"
  OIDC_SERVER_URL?: string | undefined; // "https://admin.us-corp-dev-3.vip.tndev.net/"
  // Adding in some formatted variants as we need, such as apiUrl
  // it will recalculate whenever the envState changes via setEnvState
  apiUrl?: string | undefined;
  [key: string]: any;
}

//----------------------------------------------------------------------
// Reauth Dialog State and Consts
//----------------------------------------------------------------------

type ReauthDialogState = { title: string; message: string } | null;

export const reauthDialogInitial: ReauthDialogState = null;

// If the silent renew fails
export const reauthDialogExpired: ReauthDialogState = {
  title: 'Session Expired',
  message: 'Your session has expired.',
};

// If a user logs out or no longer exists in storage
export const reauthDialogLogout: ReauthDialogState = {
  title: 'Logged Out',
  message: 'You have been logged out.',
};

// For API or generic errors
export const reauthDialogError: ReauthDialogState = {
  title: 'Error',
  message: 'Sorry, we ran into an error.',
};

// For 401 or 403 errors
export const reauthDialogUnauthorized: ReauthDialogState = {
  title: 'Unauthorized',
  message: 'Sorry, please try again.',
};

/**
 * Retrieve the formatted API URL from the environment state.
 * Returns empty string if any data missing.
 */
export function formatApiUrl(envState?: EnvData) {
  if (!envState?.API_SERVER_URL) {
    return '';
  }
  const apiUrlFull = `https://${envState?.API_SERVER_URL}/rest/admin`;
  return apiUrlFull;
}

/**
 * Zustand Store for Auth + Related Info (sync in useEffects) - CMSADMIN-1097
 * Sync auth and related data to a Zustand store for use in other areas
 * of the app outside of components like in the httpClient / interceptors.
 */

export interface ZustandAuthStore {
  envState: EnvData | undefined;
  setEnvState: (
    value: EnvData | undefined | ((prev: EnvData | undefined) => EnvData | undefined),
  ) => void;
  userManager: UserManager | undefined;
  setUserManager: (
    value:
      | UserManager
      | undefined
      | ((prev: UserManager | undefined) => UserManager | undefined),
  ) => void;
  lastAuthUser: User | null | undefined;
  setLastAuthUser: (
    value:
      | User
      | null
      | undefined
      | ((prev: User | null | undefined) => User | null | undefined),
  ) => void;
  currentUser: User | null | undefined;
  setCurrentUser: (
    value:
      | User
      | null
      | undefined
      | ((prev: User | null | undefined) => User | null | undefined),
  ) => void;
  reauthDialog: ReauthDialogState;
  setReauthDialog: (
    value: ReauthDialogState | ((prev: ReauthDialogState) => ReauthDialogState),
  ) => void;
  // Active Site synced in CosmosContext for use in httpclient
  // Note it may be a tick behind the useCosmos hook, prefer useCosmos for most cases.
  activeSiteZustand: string | null | undefined;
  setActiveSiteZustand: (
    value:
      | string
      | null
      | undefined
      | ((prev: string | null | undefined) => string | null | undefined),
  ) => void;
}

export const useAuthStoreZustand = create<ZustandAuthStore>((set) => ({
  envState: undefined,
  setEnvState: (value) =>
    set((state) => {
      const updatedEnvState = typeof value === 'function' ? value(state.envState) : value;
      // Derive formatted api url on set to avoid recomputing when we need it
      const fmtApiUrl = formatApiUrl(updatedEnvState);
      return {
        envState: {
          ...updatedEnvState,
          apiUrl: fmtApiUrl,
        },
      };
    }),
  userManager: undefined,
  setUserManager: (value) =>
    set((state) => ({
      userManager: typeof value === 'function' ? value(state.userManager) : value,
    })),
  lastAuthUser: undefined,
  setLastAuthUser: (value) =>
    set((state) => ({
      lastAuthUser: typeof value === 'function' ? value(state.lastAuthUser) : value,
    })),
  currentUser: undefined,
  setCurrentUser: (value) =>
    set((state) => ({
      currentUser: typeof value === 'function' ? value(state.currentUser) : value,
    })),
  reauthDialog: reauthDialogInitial,
  setReauthDialog: (value) =>
    set((state) => ({
      reauthDialog: typeof value === 'function' ? value(state.reauthDialog) : value,
    })),
  activeSiteZustand: undefined,
  setActiveSiteZustand: (value) =>
    set((state) => ({
      activeSiteZustand:
        typeof value === 'function' ? value(state.activeSiteZustand) : value,
    })),
}));

interface GetAuthQueryKeysArrParams {
  isAuthed: boolean | null | undefined;
  userId: string | null | undefined;
  site: string | null | undefined;
}

/**
 *
 * All params are optional.
 *
 * todo - CMSADMIN-1045
 * - Standardize all useQuery to the object signature
 * - Add some of these query keys so when certain states change queries update
 * - Enable/Disable the queries with auth.isAuthenticated
 *
 * Returns an array of strings to spread into the queryKey
 * `queryKey: [...authQueryKeys, (existingKeys)]`.
 *
 * useQuery, infQuery, mutations, hooks should spread
 * some of these common keys to the queryKey, such
 * as the activeSite minimally...
 *
 * Not all queries will need the auth/userid key
 * as some we would not want to refetch or unmount them
 * if a background tab logs out, but some we would want to.
 *
 * For example, dashboards and other get queries are probably fine
 * but we wouldn't want to lose the data on a form or edit page.
 * For those we can possibly set the keepPreviousData:true
 * and adjust the cache/stale times on those individual queries.
 *
 * Will need to check that on a case-by-case basis...
 *
 * Still, almost all queries will need to add:
 * enabled: auth?.isAuthenticated && (other conditions)
 * to enabled prop, as to stop request attempts when not authenticated.
 *
 * Note that although the auth library claims `auth?.isAuthenticated`
 * is true while the access_token is valid, I have seen this
 * be incorrect in some cases, such as refreshes to auth when
 * storage events load the new user data, but the auth hook
 * didn't always seem to pick up on this change... so
 * we may need to add some more checks or implement
 * our own version of `isAuthenticated` hook. The useAuth hook
 * only seems to actually detect if a user exists in storage
 * and if they were valid upon first load, but not if they
 * are still valid after the initial load.
 *
 */

export function getAuthQueryKeysArr({
  isAuthed,
  userId,
  site,
}: GetAuthQueryKeysArrParams) {
  const keyArray = [];
  // Store active site in the query key
  if (site) {
    keyArray.push(`site:${site}`);
  }
  // Note - if user logged out, userId will be empty
  if (userId) {
    keyArray.push(`userId:${userId}`);
  }
  // Note - if user logged out, authed will be false
  if (isBoolean(isAuthed)) {
    keyArray.push(`authed:${isAuthed}`);
  }
  return keyArray;
}

/**
 * Helper to get userManager via zustand store
 * for use in httpclient interceptor.
 */
export function getUserManagerViaZustand() {
  const userManager = useAuthStoreZustand?.getState()?.userManager;
  return userManager;
}

/**
 * Get the apiUrl from zustand store...
 * Returns empty string if any data missing.
 */
export function getApiUrlViaZustand() {
  const envState = useAuthStoreZustand?.getState()?.envState;
  const apiUrlFormatted = envState?.apiUrl;
  return apiUrlFormatted;
}

/**
 * Validates the environment variables for required data.
 * Returns true if passes validation.
 * Throws an error if needed any data is missing.
 */
export function validateEnvVars(envVars: Partial<EnvData>) {
  if (!envVars?.API_SERVER_URL || !envVars?.OIDC_SERVER_URL || !envVars?.ENV) {
    throw new Error('Environment vars missing required data.');
  }
  return true;
}

/**
 * Generates a random integer between a given min and max value.
 * @param min The minimum value e.g. seconds.
 * @param max The maximum value e.g. seconds.
 * @returns A random integer between min and max.
 */
export function getRandomIntInRange(min: number, max: number) {
  if (min > max) {
    throw new Error('Min value should not be greater than max value.');
  }

  if (
    min > Number.MAX_SAFE_INTEGER ||
    max > Number.MAX_SAFE_INTEGER ||
    min < Number.MIN_SAFE_INTEGER ||
    max < Number.MIN_SAFE_INTEGER
  ) {
    throw new Error('Values are out of range.');
  }

  const random = Math.random(); // Random 'float' between 0 and 1
  const result = Math.floor(random * (max - min + 1)) + min; // Random 'int' between min and max
  return result;
}

/**
 * Builds the end session URL for the OIDC server.
 * Maybe removed once we swap to end_session_endpoint? TNCMS-554796
 * Returns empty string if any data missing.
 */
export function formatEndSessionUrl(oidcServerUrl?: string) {
  if (!oidcServerUrl) {
    return '';
  }
  const endSessionUrl = `${oidcServerUrl}api/-/logout`;
  return endSessionUrl;
}

/**
 * Pass in access_token to check if JWT is or is nearing expiry.
 * @returns `True if the token is expired or nearing expiry (based on threshold)`
 * @param tok The JWT string, "Bearer" prefix will be stripped if included
 * @param thresholdInSeconds (Optional) Threshold in seconds to check if the token is nearing expiry
 * @throws Error if the token has no exp claim or decoding fails
 */
export function isJwtExpired(
  tok: string | null | undefined,
  thresholdInSeconds?: number,
): boolean {
  try {
    if (!tok) {
      throw new Error('[isJwtExpired] No token provided.');
    }

    // Remove the "Bearer" prefix if exists and trim whitespace.
    const tokenNoBearer = tok.startsWith('Bearer') ? tok.slice(6) : tok;
    const cleanToken = tokenNoBearer.trim();

    // Decode the JWT payload
    const { exp } = decodeJwt(cleanToken);

    // Throw error if exp is not present
    if (!exp) {
      throw new Error('[isJwtExpired] Token missing exp claim.');
    }

    // Compare the expiration time with the current time
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    // Check if the token is fully expired
    if (currentTimeInSeconds >= exp) {
      return true; // Token has fully expired
    }

    // If threshold is provided, check if the token is within expiry threshold
    if (thresholdInSeconds && currentTimeInSeconds >= exp - thresholdInSeconds) {
      return true; // Consider it as expired for caller
    }

    return false; // Token is valid
  } catch (error) {
    console.error('[isJwtExpired] Error:', error);
    throw error;
  }
}

/**
 * Format token type and access token to "Bearer eyJ..."
 * Returns empty string if any data missing.
 */
export function formatBearerToken(tokenType?: string, accessToken?: string) {
  if (!tokenType || !accessToken) {
    return '';
  }
  const bearerToken = `${capitalizeFirstLetter(tokenType)} ${accessToken}`;
  return bearerToken;
}

/**
 * Get the default site via the user jwt profile.
 * Returns empty string if any data missing.
 */
export function getUserDefaultSite(user: User | null | undefined) {
  if (!user?.profile['bloxcms.default_site']) {
    return '';
  }
  const oidcDefaultSite = String(user?.profile['bloxcms.default_site']);
  const formatResult = oidcDefaultSite.toLowerCase().trim();
  return formatResult;
}

/**
 * Get the subject (user uuid) via the user jwt profile
 * Returns empty string if any data missing.
 */
export function getUserId(user: User | null | undefined) {
  if (!user?.profile?.sub) {
    return '';
  }
  const subject = String(user?.profile?.sub);
  return subject;
}

/**
 * Get token string from passed in via the user profile - e.g. "Bearer eyJ..."
 * Returns empty string if any data missing.
 */
export function getAuthTokenViaUser(user: User | null | undefined) {
  const tokenType = user?.token_type;
  const accessToken = user?.access_token;
  if (!tokenType || !accessToken) {
    return '';
  }
  const bearerToken = formatBearerToken(tokenType, accessToken);
  return bearerToken;
}

/**
 * Retrieve the OIDC Client ID ("cosmos-admin") from jwt user profile
 * Default is set in the UserManager's client_id: 'cosmos-admin'
 * Returns empty string if any data missing.
 */
export function getUserClientId(user: User | null | undefined) {
  if (!user?.profile?.aud) {
    return '';
  }
  const clientId = user?.profile?.aud;
  return clientId;
}

// Default auth lib prefix for storage keys
export const AUTH_PREFIX = 'oidc.';

/**
 * Builds the "oidc.user:{OIDC_SERVER_URL}:{CLIENT_ID}" storage string
 * Returns empty string if any data missing.
 */
export function buildAuthStorageKey(user: User | null | undefined) {
  const issuer = String(user?.profile?.iss || '').trim();
  const clientId = getUserClientId(user);
  if (!issuer || !clientId) {
    return '';
  }
  const storageKey = `${AUTH_PREFIX}user:${issuer}:${clientId}`;
  return storageKey;
}

/**
 * Auth library storage key is built dynamically based on the issuer and clientid...
 * so we will store a static key to reference and use for lookup in
 * storage, so if you didn't have access to the useAuth hook
 * you may use this const key to get access the stored auth data, if any exists.
 * A useEffect will update the value of this key if user changes.
 * e.g. K:V = 'AUTH_STORAGE_KEYREF':'oidc.user:https://...:cosmos-admin'
 */
export const AUTH_STORAGE_KEYREF = 'AUTH_STORAGE_KEYREF';

/**
 * Returns the auth storage key reference using AUTH_STORAGE_KEYREF.
 * e.g. "oidc.user:https://...:cosmos-admin"
 * Returns empty string if key is missing.
 */
export function getAuthStorageKeyViaKeyRef() {
  const storageKey = localStorage.getItem(AUTH_STORAGE_KEYREF);

  if (!storageKey) {
    return '';
  }

  return storageKey;
}

/**
 * Tries to get the auth user from localStorage via the AUTH_STORAGE_KEYREF.
 * Returns a User object if found.
 * Returns null if no data found or parse error.
 */
export async function getAuthUserViaStorageKeyRef() {
  const storageKey = getAuthStorageKeyViaKeyRef();

  if (!storageKey) {
    return null;
  }

  // Use the storageKey to fetch the actual auth user data
  const storedData = localStorage.getItem(storageKey);

  if (!storedData) {
    return null;
  }

  // Parse the stored user JSON data
  try {
    const parsedData: User = await JSON.parse(storedData);
    return parsedData;
  } catch (err) {
    console.error('[getAuthViaStorageKeyRef] Error:', storedData);
    return null;
  }
}

/**
 * Sets the value of AUTH_STORAGE_KEYREF to the built user storage key.
 * e.g. K:V = AUTH_STORAGE_KEYREF : 'oidc.user:https://...:cosmos-admin'
 * Returns the true if string was built and was set.
 * Returns false if the information was not set.
 */
export async function setAuthUserKeyRefValue(user: User | null | undefined) {
  if (!user) {
    return false;
  }

  const storageKey = buildAuthStorageKey(user);

  if (!storageKey) {
    return false;
  }

  // Store the auth storage key reference
  try {
    localStorage.setItem(AUTH_STORAGE_KEYREF, storageKey);
    return true;
  } catch (err) {
    console.error('[setAuthUserViaStorageKeyRef] Error:', err);
    return false;
  }
}

/**
 * Success should return "Bearer eyJ...", pass the key to use or
 * it will try to get the auth user from the storage keyref.
 *
 * Returns an empty string on any error or if missing data.
 */
export async function getAuthTokenViaStorage() {
  try {
    // Get user from storage via keyref...
    const storedUser = await getAuthUserViaStorageKeyRef();

    // Extract token_type and access_token
    const tokenType = storedUser?.token_type;
    const accessToken = storedUser?.access_token;

    // If token data is missing return empty string
    if (!tokenType || !accessToken) {
      return '';
    }

    // Build token string ("Bearer eyJ...")
    const bearerToken = formatBearerToken(tokenType, accessToken);

    return bearerToken;
  } catch (err) {
    return '';
  }
}

/**
 * Get the active site from zustand store.
 * This is synced with CosmosContext for use in httpclient.
 * Note it may be a tick behind the useCosmos hook.
 * Returns empty string if any data missing.
 */
export function getActiveSiteViaZustand() {
  const zustActiveSite = useAuthStoreZustand?.getState()?.activeSiteZustand;
  return zustActiveSite;
}

//----------------------------------------------------------------------
// CosmosAuthEnvContext
//----------------------------------------------------------------------

interface CosmosAuthEnvContextType {
  /** internal for auth proivder setup, see function for details  */
  authLogin: () => Promise<void>;
  /** internal for auth proivder setup, see function for details  */
  authLogout: () => Promise<void>;
}

export const CosmosAuthEnvContext = createContext<CosmosAuthEnvContextType | undefined>(
  undefined,
);

export const useCosmosAuthEnv = () => {
  const context = useContext(CosmosAuthEnvContext);
  if (!context) {
    throw new Error('useCosmosAuthEnv must be used within a WithAuthEnvChecksProvider!');
  }
  return context;
};

//----------------------------------------------------------------------
// Random Refresh Timer for UserManager Settings
// Set a random refresh time for each usermanager instance per tab
// as to try and workaround the issue of multiple tabs trying to
// refresh the token at the same time and the auth server rejecting the
// other refresh token (as if synced up, will cause race conditions)...
// With this each tab should have a slightly different refresh time.
// When a tab refreshes, we must notify the other tabs
// that the user has changed and restart services so the underlying
// auth library can re-configures its internal timers and update the hook.
//
// We currently do that sync via storage events, but we have also tried
// broadcastchannel (e.g. old: 160bf758864383faace7473d650b42eeeb791f36)
//
// Picked 90-240 seconds, should be wide enough to avoid race conds...
// Additionally: see httpClient for usage of randomRefreshTime and others.
// Note randomRefreshTime must always be less than access_token duration.
//----------------------------------------------------------------------
export const AUTH_MIN_RF_SEC = 90;
export const AUTH_MAX_RF_SEC = 240;
export const randomRefreshTime = getRandomIntInRange(AUTH_MIN_RF_SEC, AUTH_MAX_RF_SEC);

//----------------------------------------------------------------------
// SetupAuthentication - gets env and config usermanager for auth ctx
//----------------------------------------------------------------------
export const SetupAuthentication = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const devMode = isDevMode();
  const useEnvVars = isUseEnvVars();
  const useLocalEnvVars = useEnvVars || devMode;
  const { envState, setEnvState, setUserManager, userManager } = useAuthStoreZustand();

  const { data: fetchedEnvData, error: fetchedEnvError } = useQuery<
    EnvData,
    ApiErrorResponse
  >({
    queryKey: ['SetupAuthentication', 'envState', `localenv:${useLocalEnvVars}`],
    queryFn: async () => {
      // Fetches environment variables via the <link> tags href in index.html
      // or the process.env if running locally in development mode or prefer use env vars.
      if (useLocalEnvVars) {
        const envVars = {
          ENV: process.env.REACT_APP_ENV,
          API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL,
          OIDC_SERVER_URL: process.env.REACT_APP_OIDC_SERVER_URL,
        };
        validateEnvVars(envVars);
        return envVars;
      } else {
        const envHref = window.document.getElementById('env-id')?.getAttribute('href');

        if (!envHref) {
          throw new Error("Element with 'env-id' not found.");
        }

        try {
          const envUrl = new URL(envHref, window.location.origin).toString();
          const response = await fetch(envUrl);

          if (!response.ok) {
            throw new Error(`Env not ok: ${response.status} ${response.statusText}`);
          }

          const result: EnvData = await response.json();
          const envVars = {
            ENV: result.ENV,
            API_SERVER_URL: result.API_SERVER_URL,
            OIDC_SERVER_URL: result.OIDC_SERVER_URL,
            ...result,
          };

          validateEnvVars(envVars);
          return envVars;
        } catch (error) {
          throw error;
        }
      }
    },
    enabled: !Boolean(envState), // Disable once fetched
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to zustand when fetched or changes.
  useLayoutEffect(() => {
    if (!fetchedEnvData) {
      return;
    }
    setEnvState(fetchedEnvData);
  }, [setEnvState, fetchedEnvData]);

  // UserManager instance for AuthProvider Context and httpClient methods
  useLayoutEffect(() => {
    // Await required state before setting up the usermanager
    if (!envState?.OIDC_SERVER_URL) {
      return;
    }

    const userManagerSetup = new UserManager({
      // The docs say either handle the silent manually in a useEffect or set here, but do not do both.
      // If you set both, you will get double invocations of renew and the timing could
      // potentially cause the auth server to potentially reject the next refresh token call.
      // This may lead to various api errors and logouts.
      // For our setup we need to manually control the silent renews at httpclient on demand
      // And additionally sync that state to the auth library between tabs.
      automaticSilentRenew: false,
      includeIdTokenInSilentRenew: true,
      includeIdTokenInSilentSignout: true,
      // Use a random refresh time to try and prevent race conditions with
      // token refresh timing calls between tabs when using shared storages.
      accessTokenExpiringNotificationTimeInSeconds: randomRefreshTime,
      // signInPopup requires a shared cross tab storage like localStorage to work properly
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
      // If the user is in sessionStorage, there is an edge case where "duplicating" a chrome tab
      // would copy all sessionStorage, including the refresh_token, and could cause invalid_grant issue...
      // Additionally, the InMemoryWebStorage method worked but you'd effectively hit SSO each load
      // So for our current purposes will use localStorage and attempt to workaround refresh issues.
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      filterProtocolClaims: false,
      authority: envState?.OIDC_SERVER_URL,
      client_id: 'cosmos-admin',
      redirect_uri: window.location.origin,
      post_logout_redirect_uri: window.location.origin,
      scope: 'openid email offline_access',
      refreshTokenAllowedScope: 'openid email offline_access',
      fetchRequestCredentials: 'include',
      response_type: 'code',
      response_mode: 'query',
      requestTimeoutInSeconds: 10,
      silentRequestTimeoutInSeconds: 10,
      // Monitors OP listening for events
      // MonitorSession is required for some events to fire
      // TNCMS-554796 ? Need to know if/when we should enable this...
      monitorSession: false,
      checkSessionIntervalInSeconds: 60,
      monitorAnonymousSession: false,
      metadataSeed: {
        end_session_endpoint: formatEndSessionUrl(envState?.OIDC_SERVER_URL),
      },
    });

    // Set the usermanager in state for the AuthProvider and keep it stable
    setUserManager(userManagerSetup);
  }, [envState, setUserManager]);

  // Show error component if fetching environment data fails.
  if (fetchedEnvError) {
    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        showDetails={true}
        errors={[
          {
            message: 'Error fetching environment data.',
            status: fetchedEnvError?.status,
            fullDetails: fetchedEnvError,
          },
        ]}
        buttonOnClick={() => {
          // Hard reset if things fail this early
          clearPrefixedStorage(AUTH_PREFIX, ['localStorage']);
          window.sessionStorage.clear();
          window.location.reload();
        }}
      />
    );
  }

  // ensure derived apiUrl state is set for use in httpclient as well
  const notReady =
    !envState ||
    !envState?.OIDC_SERVER_URL ||
    !envState?.API_SERVER_URL ||
    !envState?.ENV ||
    !envState?.apiUrl ||
    !userManager;

  if (notReady) {
    return <BloxLoadingSplash message="Building Authentication..." />;
  }

  /**
   * onSignInCallback
   * Here you can remove the code and state parameters from
   * the url when you are redirected from the authorize page.
   * as well as restore any routing state for the user
   */
  const onSignInCallbackFn = async (user: User | void) => {
    if (!user) {
      console.warn('[onSignInCallbackFn] No User passed to sign in CB!');
    }

    // Pulling from the signinRedirectArgs state to redirect user back to where they were.
    const userState = user?.state as CustomUserStateProperties;
    // Default to root, and clear code/query params from url to enable silent renew.
    // e.g restore: "/client-site.net/.../search?query=test" or fresh: "/"
    const navUserToRoute = userState?.restore_route || '/';
    navigate(navUserToRoute, { replace: true });
  };

  return (
    <AuthProvider
      userManager={userManager}
      onSigninCallback={onSignInCallbackFn}
      children={<WithAuthEnvChecksProvider>{children}</WithAuthEnvChecksProvider>}
    />
  );
};

interface WithAuthEnvChecksProviderProps {
  children: React.ReactNode;
}

//----------------------------------------------------------------------------------------
// WithAuthEnvChecksProvider - silent renew, login, logout, redir, etc...
//----------------------------------------------------------------------------------------
export const WithAuthEnvChecksProvider = ({
  children,
}: WithAuthEnvChecksProviderProps) => {
  const auth = useAuth();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const {
    envState,
    reauthDialog,
    setReauthDialog,
    lastAuthUser,
    setLastAuthUser,
    setCurrentUser,
    currentUser,
  } = useAuthStoreZustand();
  const reauthDialogOpen = Boolean(reauthDialog);

  // login and logout moved up to centralize functions that are
  // provided to CosmosRAProvidersContext authprovider via context
  const authLogin = useCallback(async () => {
    try {
      const redirUri = window.location.origin;
      const cosmosRoute = location.pathname + location.search;
      await auth.signinRedirect({
        redirect_uri: redirUri,
        state: { restore_route: cosmosRoute },
      });
      // Nothing after signinRedirect will run as this navs offsite to the blox sso
      // but resolve promise type for react-admin provider and typescript.
      return Promise.resolve();
    } catch (error) {
      console.error('[authLogin] Error:', error);
      return Promise.reject(error);
    }
  }, [auth, location]);

  const authLogout = useCallback(async () => {
    try {
      // Temp until TNCMS-554796 - use this when we have the official endpoint?
      // const redirUri = window.location.origin;
      // const cosmosRoute = location.pathname + location.search;
      // const idTokenHint = auth?.user?.access_token;
      // await auth.signoutRedirect({
      //   post_logout_redirect_uri: redirUri,
      //   state: { restore_route: cosmosRoute },
      //   id_token_hint: idTokenHint,
      // });

      // For now we manually hit the logout via endpoint ourselves...
      const endSessionUrl = formatEndSessionUrl(envState?.OIDC_SERVER_URL);
      const response = await fetch(endSessionUrl, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`[authLogout] response not ok: ${response}`);
      }
    } catch (error) {
      console.error('[authLogout] Error:', error);
    } finally {
      try {
        // Client-side cleanup should be done now until we have the official endpoint
        // Remove user should emit a StorageEvent to sync other tabs
        await auth.removeUser();
        // Once storage is cleared reload the page - temp until TNCMS-554796
        // Should cause a sign in redirect upon reload to the SSO login page.
        window.location.reload();
      } catch (error) {
        console.error('[authLogout] Cleanup Error:', error);
      }
      // Resolve for react-admin types
      return Promise.resolve();
    }
  }, [auth, envState]);

  // Sync last user, current user, stores, keyrefs, and initialize etc...
  useLayoutEffect(() => {
    const syncAndInitAuth = async () => {
      // Sync user from auth hook with zustand (user/null/undefined)
      setCurrentUser(auth?.user);

      // Keep track of the last authed user and store the built keyref
      if (auth?.user) {
        try {
          const wasSet = await setAuthUserKeyRefValue(auth.user);
          setLastAuthUser(auth.user);
          if (!wasSet) {
            throw new Error('Failed to set user via storage keyref.');
          }
        } catch (error) {
          console.error('[syncAndInitAuth]', error);
        }
      }

      // If not yet initialized, confirm user in storage, zustand, and is authenticated
      // per the library hook, then we can set initialized and continue to render...
      if (!initialized) {
        const storageExists = await getAuthUserViaStorageKeyRef();
        if (storageExists && currentUser && auth.isAuthenticated) {
          setInitialized(true);
        }
      }
    };

    syncAndInitAuth();
  }, [auth, setLastAuthUser, currentUser, initialized, setCurrentUser]);

  // Effect listens and responds to auth events and initial login setup.
  // https://github.com/authts/react-oidc-context?tab=readme-ov-file#automatic-sign-in
  const [hasTriedSignIn, setHasTriedSignIn] = useState(false);
  useLayoutEffect(() => {
    const checkAuthAsync = async () => {
      // If already initialized can skip this effect
      if (initialized) {
        return;
      }

      // Ensure auth context hook is ready
      if (!auth) {
        return;
      }

      // If there is an error log it and return...
      // Note: Have seen this infinite loop of error logging without the return
      // not sure if that was just a one-off event, so added return to be safe...
      if (auth.error) {
        console.error('[checkAuthAsync] Auth error:', auth.error);
        // Edge case: if we errored with auth params in url, we should clear the params.
        // Usually e.g. somehow matching sign in state was cleared out of the app storage while
        // the user was redirected to the SSO login page, rarely happens but for safety hard navigate...
        if (hasAuthParams()) {
          console.error('[checkAuthAsync] Auth error and has auth params, reloading...');
          // Hard navigate to clear auth params
          window.location.assign(window.location.origin);
        }
        return;
      }

      // Wait for auth to load before checking anything
      if (auth.isLoading) {
        return;
      }

      // Startup without auth and nothing in progress - redirect to SSO login.
      if (
        !initialized &&
        !hasAuthParams() &&
        !auth.isAuthenticated &&
        !auth.activeNavigator &&
        !hasTriedSignIn
      ) {
        await authLogin();
        setHasTriedSignIn(true);
        return;
      }
    };

    // Run on mount and when state changes.
    checkAuthAsync();

    // Event listeners for silent renew, token expiry, etc.
    // There are more event listeners that can be added here.
    // See auth.events in oidc-client-ts docs: https://authts.github.io/oidc-client-ts/
    // Some events are dependent on monitorSession:true in userManager settings.
    // Also be mindful of side effects, eg if you call removeUser somewhere else in the app
    // that could trigger an event listener here causing unintended side effects.
    // In testing all of the events fire reliably but are not emitted across tabs
    // so we have to sync them via storage listeners or broadcast channels manually.

    // Note on many events, e.g. accessTokenExpiring, they seem to be raised
    // regardless of if the silent renew or other services are enabled or not
    // So if you si:silent in that event handler and also silent renew elsewhere
    // then it can cause a double /token refresh fetch..
    // We refresh the auth on-demand in the http interceptor to avoid this
    // and any sign in via the reauth dialog should also refresh the token.

    // User has been loaded to auth library...
    const unsubUserLoaded = auth.events.addUserLoaded(async (user) => {
      try {
        // Clear any stale state on user load
        await auth.clearStaleState();
        // Confirm the access token on user is valid
        const bearerToken = getAuthTokenViaUser(user);
        const tokenExpired = isJwtExpired(bearerToken);
        // If all good then close any dialogs and set the user
        if (!tokenExpired) {
          // NOTE: The only way I was able to find to get the auth library to correct
          // internal event timers and raise these events properly after a storage event
          // across tabs is to manually load the user in the storage event effect and then
          // start and stop the silent renew service. That seems to make it restart
          // and raise these events again, eg, accessTokenExpiring and other events.
          // This is a bit of a hack but it seems to work for now.
          // We do not actually use the auto silent renew service, as we handle
          // refreshing the token ourselves in the httpclient interceptor as needed on demand.
          // The auto renew here should always be off to ensure double token fetches dont happen.
          auth.startSilentRenew();
          auth.stopSilentRenew();
          // Token is valid so close any reauth dialogs that may have been open.
          setReauthDialog(reauthDialogInitial);
        }

        // Safety check: If somehow user loaded is expired then show reauth dialog
        if (tokenExpired) {
          setReauthDialog(reauthDialogExpired);
        }
      } catch (error) {
        console.error('[addUserLoaded] Error:', error);
      }
    });

    // The silent renew service encountered an error.
    const unsubSilentRenewError = auth.events.addSilentRenewError(async (error) => {
      console.error('[addSilentRenewError] Error:', error);
      try {
        setReauthDialog(reauthDialogError);
      } catch (error) {
        console.error('[addSilentRenewError] Caught Error:', error);
      }
    });

    // Cleanup listeners
    return () => {
      unsubUserLoaded();
      unsubSilentRenewError();
    };
  }, [
    auth,
    auth.events,
    setLastAuthUser,
    location,
    setReauthDialog,
    reauthDialogOpen,
    reauthDialog,
    initialized,
    hasTriedSignIn,
    authLogin,
    authLogout,
  ]);

  // Sync auth library across tabs using StorageEvents
  // Note that the tab emitting the event will not receive it.
  useEffect(() => {
    const onStorageChange = async (event: StorageEvent) => {
      // Retrieve the key pointing to the user data in storage
      const userStorageKey = getAuthStorageKeyViaKeyRef();

      if (!userStorageKey) {
        console.warn('[StorageSync] No storage keyref value.');
        return;
      }

      // Only handle events for the auth storage key we are concerned with
      if (!event?.key || event?.key !== userStorageKey) {
        return;
      }

      // Attempt to handle the storage event and update the auth library state
      try {
        // Try to get the storage data that was just updated...
        const userViaStorage = await getAuthUserViaStorageKeyRef();

        // Fully logged out - user no longer exists in storage
        if (!userViaStorage) {
          // Remove user to sync lib internals
          await auth.removeUser();
          // Show logout dialog if no dialog currently open
          if (!reauthDialogOpen) {
            setReauthDialog(reauthDialogLogout);
          }
        }

        // User Exists - auth was updated in storage (new login or refreshed token)
        if (userViaStorage) {
          // Note potential edge case:
          // A situation could potentially happen where you could be logged in
          // on an account with access to a domain and then via the reauth modal
          // login to a lower privilged or other account that doesnt have access
          // potentially causing 403 errors... so at this point the app effectively
          // needs to reinitialize if the user account changed... for now we reload
          // the client if detected that new authed user is not the last authed user.
          const lastAuthUserId = getUserId(lastAuthUser);
          const newUserId = getUserId(userViaStorage);
          const isSameUser = lastAuthUserId === newUserId;

          // This check must happen before the user is loaded into the auth library
          // as once it is loaded the lastAuthUser will be updated to the current user.
          if (!isSameUser) {
            console.warn('[StorageSync] Account changed, must hard reload.');
            window.location.reload();
            return;
          }

          // Manually re-load stored user into auth library...
          // basically an attempt to correct the auth library internal timers.
          // The useEffect containing addUserLoaded event listener should
          // respond and handle restarting the services, clearing dialogs, etc.
          try {
            await auth.events.load(userViaStorage, true);
          } catch (error) {
            console.error('[StorageSync] Error loading user.', error);
          }
        }
      } catch (error) {
        console.error('[StorageSync] Error storage event:', error);
      }
    };

    // Subscribe to storage events
    window.addEventListener('storage', onStorageChange);

    // Unsubscribe from storage events
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [
    auth,
    initialized,
    setReauthDialog,
    reauthDialogOpen,
    setLastAuthUser,
    lastAuthUser,
  ]);

  /**
   * Determine splash message based on activeNavigator and auth state.
   * When we had just auth.isLoading checks, sometimes during silent refreshes
   * the react tree would re-render and show the splash screen for a split second.
   * So use initialized state to prevent this unmouting and remounting of the react tree.
   */
  const determinedSplash = useMemo(() => {
    if (!initialized) {
      return 'Checking Authentication...'; // Await initialization
    }

    if (auth.isAuthenticated) {
      // Authenticated and initialized so a user exists in storage.
      // Other event handlers will show the reauth dialog if needed.
      return null;
    }

    // auth.activeNavigator - Tracks the status of most recent signin/signout request method.
    // undefined | "signinRedirect" | "signinSilent" | "signinPopup" | "signoutSilent", etc...
    // Note certain activeNavigator states could cause the splash to show, causing the
    // provider to unmount the react tree and lose component state, so be mindful of those
    // and instead handle those situations by showing the reauth dialog.
    switch (auth.activeNavigator) {
      case 'signinRedirect': {
        return 'Redirecting to Signin...';
      }

      case 'signinSilent': {
        return null; // Silent refreshing, continue rendering
      }

      case 'signoutPopup':
      case 'signoutRedirect':
      case 'signoutSilent': {
        return 'Signing you out...';
      }

      default: {
        return null; // Nothing in progress or unhandled, continue rendering
      }
    }
  }, [auth, initialized]);

  // Context value for the provider
  const contextValue = useMemo(() => {
    return {
      authLogin: authLogin,
      authLogout: authLogout,
    };
  }, [authLogin, authLogout]);

  // Splash Screen for certain auth states
  if (determinedSplash) {
    return <BloxLoadingSplash message={determinedSplash} />;
  }

  return (
    <CosmosAuthEnvContext.Provider value={contextValue}>
      <ReauthDialog />
      {children}
    </CosmosAuthEnvContext.Provider>
  );
};

/**
 * Reauthenticate Dialog
 * This will be a better UX than redirecting the users current tab to a login page, or
 * unmounting the entire react tree to show the splash which causes loss of react state.
 */
export const ReauthDialog = () => {
  const auth = useAuth();
  const { setReauthDialog, reauthDialog } = useAuthStoreZustand();
  const dialogOpen = Boolean(reauthDialog);
  const [error, setError] = useState<string | null>(null);

  const handleSignInPopup = async () => {
    try {
      await auth.clearStaleState(); // Clear any stale state before popup
      const result = await auth.signinPopup(); // Success returns a user
      if (result) {
        // Clear stale auth states and close the dialog
        await auth.clearStaleState();
        setReauthDialog(reauthDialogInitial);
        setError(null);
      } else {
        // User could have closed the popup or other network failure.
        throw new Error('Signin popup failed:', result);
      }
    } catch (error) {
      console.error('[handleSignInPopup] Error:', error);
      setError('There was an error. Please try again.');
    }
  };

  const handleOnClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    // Modal showing state is controlled by showReauthDialog.
    // User should not be able to close this modal without reauthenticating.
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
  };

  return (
    <Dialog
      id="reauth-dialog"
      open={dialogOpen}
      keepMounted={true}
      TransitionProps={{
        timeout: {
          enter: 0,
          exit: 0,
        },
      }}
      onClose={handleOnClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="reauth-dialog-title"
      aria-describedby="reauth-dialog-description"
      aria-live="assertive"
      sx={(theme) => ({
        zIndex: 999999,
        '& .MuiDialog-paper': {
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          height: 225,
        },
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(4px) grayscale(100%);',
          backgroundColor: '#00000099',
        },
      })}
    >
      <DialogTitle
        id="reauth-dialog-title"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          backgroundColor: getSolidThemeGray(theme),
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <WarningAmberRoundedIcon
          sx={(theme) => ({
            color: theme.palette.warning.main,
            fontSize: bloxValues.iconSizeRegular,
          })}
        />
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightMedium}
        >
          {reauthDialog?.title}
        </Typography>
      </DialogTitle>
      <DialogContent
        id="reauth-dialog-description"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          gap: 1,
        }}
      >
        <Typography
          pt={1}
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
        >
          {reauthDialog?.message}
        </Typography>
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
        >
          Please log in to continue.
        </Typography>
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
          color="error"
          display={error ? 'block' : 'none'}
        >
          {error}
        </Typography>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          title="Opens a window to re authenticate."
          onClick={handleSignInPopup}
          startIcon={<KeyIcon />}
          endIcon={<OpenInNewIcon />}
        >
          Log In {emdash} New Window
        </Button>
      </DialogContent>
    </Dialog>
  );
};
