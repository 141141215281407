import { httpClient } from 'providers/clients/httpClient';

export const dynamicFieldDataProvider = {
  // Retrieve dynamic enumeration data for a field
  getDynamicEnumerationData: async (
    app: string,
    resolver: string,
    limit: number = 10,
    page: number = 0,
    params: Record<string, string> = {},
  ) => {
    try {
      // Build the query string with limit, page, and additional params
      const queryParams = {
        limit: limit.toString(),
        page: page.toString(),
        ...params,
      };
      const queryString = new URLSearchParams(queryParams).toString();

      const url = `/core/field/denum/${app}/${resolver}/?${queryString}`;

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error('[getDynamicEnumerationData] Error', error);
      throw error;
    }
  },

  // Retrieve dynamic tree data for a field
  getDynamicTreeData: async (
    app: string,
    resolver: string,
    parent?: string,
    params: Record<string, string> = {},
  ) => {
    try {
      const resolvedParent = parent || ','; // Ensure parent defaults to ',' if not provided.
      const queryParams = { ...params };
      const queryString = new URLSearchParams(queryParams).toString();

      const url = `/core/field/dtree/${app}/${resolver}/${resolvedParent}/${
        queryString ? `?${queryString}` : ''
      }`;
      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error('[getDynamicTreeData] Error', error);
      throw error;
    }
  },
};
