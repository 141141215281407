import gravatarUrl from 'gravatar-url';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { AuthProvider, UserIdentity } from 'react-admin';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import { ApiErrorResponse, BloxCustomDataProviderType } from 'utility/types/dataProvider';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import {
  useCosmosAuthEnv,
  useAuthStoreZustand,
  reauthDialogUnauthorized,
} from './CosmosAuthEnvContext';
import { useCosmos } from './CosmosContext';
import { accessControlProvider } from './extensions/AccessControl';
import { artificalIntelligenceProvider } from './extensions/ArtificalIntelligence';
import { baseDataProvider } from './extensions/BaseDataProvider';
import { blockLayoutProvider } from './extensions/BlockLayout';
import { blockLibraryProvider } from './extensions/BlockLibrary';
import { dashboardsProvider } from './extensions/Dashboards';
import { editorialProvider } from './extensions/Editorial';
import { experimentsProvider } from './extensions/Experiments';
import { pagetrackerProvider } from './extensions/PageTracker';
import { previewsProvider } from './extensions/Previews';
import { savedSearchesProvider } from './extensions/SavedSearches';
import { sessionsProvider } from './extensions/Sessions';
import { siteTaxonomyProvider } from './extensions/SiteTaxonomy';
import { subscriptionsProvider } from './extensions/Subscriptions';
import { templateInfoProvider } from './extensions/TemplateInfo';
import { urlMapProvider } from './extensions/UrlMap';
import { usersProvider } from './extensions/Users';
import { userWalletsProvider } from './extensions/UserWallets';
import { wireSyndicationProvider } from './extensions/Wire';
import { workflowsProvider } from './extensions/Workflows';
import { dynamicFieldDataProvider } from './extensions/DynamicFieldData';
import { relatedContentStylesProvider } from './extensions/RelatedContentStyles';

export interface CosmosDataProviderContextType {
  dataProvider: BloxCustomDataProviderType;
  authProvider: AuthProvider;
}

export const CosmosRAProvidersContext = createContext<
  CosmosDataProviderContextType | undefined
>(undefined);

export const useCosmosRAProviders = () => {
  const context = useContext(CosmosRAProvidersContext);
  if (!context) {
    throw new Error(
      'useCosmosRAProviders must be used within a CosmosRAProvidersContext!',
    );
  }
  return context;
};

/** Builds the Auth Provider and Data Provider for React-Admin **/
export const CosmosRAProviders = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const { user } = useAuth();
  const { authLogin, authLogout } = useCosmosAuthEnv();
  const { reauthDialog, setReauthDialog, envState } = useAuthStoreZustand();
  const { activeSite } = useCosmos();
  const apiUrl = envState?.apiUrl;
  const queryClient = useQueryClient();

  // -----------------------------------------------------------------------
  // Data Providers Note:
  // IMPORTANT: If you pass params through the a createXProviderFunction,
  // you may end up with stale closures / stale variables even if context
  // here claims that those values are updating. So it is best to pass
  // the paramaters as needed, directly to the individual functions within
  // the data providers, rather than passing them through to the createXProviderFn...
  // For some variables we can get them in the axios interceptor.
  // See httpClient.tsx for more details.
  // -----------------------------------------------------------------------

  // -----------------------------------------------
  // ---------------- AUTH PROVIDER ----------------
  // -----------------------------------------------
  const baseAuthProvider: AuthProvider = useMemo(() => {
    const authProvider: AuthProvider = {
      // https://marmelab.com/react-admin/AuthProviderWriting.html
      // Note: CosmosAuthEnvContext has effects which handles
      // redirecting to login page when not authenticated.
      // The 'login' here is likely unused, I dont see it called anywhere outside our auth contexts.
      // however the react-admin type for AuthProvider requires it
      // Note some functions we use a lot like useDataProvider or useGetIdentity will
      // invoke checkAuth/checkError before making the requests, but if they return error
      // react admin will implicitly call logout() for us, which we likely do not want.

      login: async (params) => {
        await authLogin();
      },

      logout: async (params) => {
        await authLogout();
      },

      checkAuth: async (params) => {
        // Intuitively we would want to show the reauth dialog here
        // but noticed that during a manual logout this fn is called by
        // some other part of react admin and can cause a flash of the reauth dialog
        // while the browser is redirecting to the login page.
        // So for the dialog/auth states, we'll handle that in the WithAuthEnvChecksProvider.

        if (!auth.isAuthenticated) {
          console.warn('[checkAuth] Deauthed.');
        }

        // Resolve to not have RA hook automatically call logout()
        return Promise.resolve();
      },

      checkError: async (error: ApiErrorResponse) => {
        // CMSADMIN-1045
        // For now we can minimally stop the requests at the httpClient level
        // but in 1045 we'll add (auth.isAuthenticated && (conds)) to all useQuery enabled prop
        // Note that checkError only checks when being called through useDataProvider();
        // So we may possibly move this check to the httpclient level later on if needed.
        // Only need to note the possible CosmosContext init process interaction
        // if we eventually move this to the httpClient level.

        // Request status code...
        const getStatus = error?.axiosStatus || error?.status || error?.response?.status;
        const unauthorizedStatus = getStatus === 401 || getStatus === 403;

        if (unauthorizedStatus) {
          console.error('[checkError] Recieved 401/403.');
          if (!reauthDialog) {
            setReauthDialog(reauthDialogUnauthorized);
          }
        }

        // Resolve to not have RA hook automatically call logout()
        return Promise.resolve();
      },

      getIdentity: async (): Promise<UserIdentity> => {
        try {
          if (!user) {
            throw new Error('[getIdentity] User not found.');
          }
          if (!user?.profile?.email) {
            throw new Error('[getIdentity] User has no email.');
          }
          const gravatarIconUrl = gravatarUrl(user?.profile?.email, {
            size: 64,
            default: 'mm',
          });
          return Promise.resolve({
            id: String(user?.profile?.email),
            avatar: gravatarIconUrl,
            uuid: user?.profile?.sub ?? '',
          });
        } catch (error) {
          console.error('[getIdentity] Error :', error);
          return Promise.reject(error);
        }
      },

      getPermissions: async (params) => {
        /* Stubbed in to satisfy types - see notes */
        return Promise.resolve();
      },
    };
    return authProvider;
  }, [auth, authLogin, authLogout, reauthDialog, setReauthDialog, user]);

  // Ensure providers are available before providing ctx...
  const contextValue = useMemo(() => {
    if (
      !auth ||
      !apiUrl ||
      !activeSite ||
      !queryClient ||
      !baseAuthProvider ||
      !baseDataProvider ||
      !accessControlProvider ||
      !artificalIntelligenceProvider ||
      !blockLayoutProvider ||
      !blockLibraryProvider ||
      !dashboardsProvider ||
      !editorialProvider ||
      !experimentsProvider ||
      !previewsProvider ||
      !savedSearchesProvider ||
      !sessionsProvider ||
      !siteTaxonomyProvider ||
      !subscriptionsProvider ||
      !templateInfoProvider ||
      !urlMapProvider ||
      !usersProvider ||
      !userWalletsProvider ||
      !workflowsProvider
    ) {
      return undefined;
    }

    const combinedProviders: CosmosDataProviderContextType = {
      authProvider: baseAuthProvider,
      dataProvider: {
        ...baseDataProvider,
        ...accessControlProvider,
        ...artificalIntelligenceProvider,
        ...blockLayoutProvider,
        ...blockLibraryProvider,
        ...dashboardsProvider,
        ...dynamicFieldDataProvider,
        ...editorialProvider,
        ...experimentsProvider,
        ...pagetrackerProvider,
        ...previewsProvider,
        ...relatedContentStylesProvider,
        ...savedSearchesProvider,
        ...sessionsProvider,
        ...siteTaxonomyProvider,
        ...subscriptionsProvider,
        ...templateInfoProvider,
        ...urlMapProvider,
        ...usersProvider,
        ...userWalletsProvider,
        ...workflowsProvider,
        ...wireSyndicationProvider,
      },
    };

    return combinedProviders;
  }, [auth, apiUrl, activeSite, queryClient, baseAuthProvider]);

  if (!contextValue) {
    return <BloxLoadingSplash message="Building Providers..." />;
  }

  return (
    <CosmosRAProvidersContext.Provider value={contextValue}>
      {children}
    </CosmosRAProvidersContext.Provider>
  );
};

/**
 * File Notes:
 *
 * --- Message ---
 * (pre-hooks/component version) - RA Correspondence on withLifecycleCallbacks + custom methods in dataprovider:
 *
 * Response:
 * withLifecycleCallbacks seems to only accept a basic dataProvider
 * (in the sense of without any custom methods) and returns a basic dataProvider as well.
 * However this is just a limitation of the TypeScript type currently in use, in fact you can
 * also provide an augmented dataProvider (including custom methods) and it will work too.
 *
 * withLifecycleCallbacks is only meant to override standard react-admin methods (getOne, getList, ...) so we
 * figured typing the base dataProvider as DataProvider was enough, but your question makes me
 * realize we could add support for generic types there.
 *
 * Anyways, TLDR is, the code would work both ways (regardless of what TypeScript says),
 * and the approach you described is perfectly valid.
 * Cheers, Jean-Baptiste, React Admin Support Team
 *
 *
 * --- Message ---
 * RA Correspondence on adding custom lifecycle callbacks to our custom methods, if ever needed.
 *
 * To achieve that, you can take a look at withLifecycleCallbacks() definition
 * in ra-core/src/dataProvider/withLifecycleCallbacks.ts and take inspiration from the ResourceCallbacks[] type.
 *
 * --- Here is an implementation example ---
 * const baseDataProvider = simpleRestProvider("http://path.to.my.api/");
 * const transformData = (data: any[]) => {
 *   return data; // do something with data
 * };
 *
 * interface CustomResourceCallbacks extends ResourceCallbacks {
 *   binaryUpload?: (params: any) => Promise<any>;
 *   binaryConvert?: (params: any) => Promise<any>;
 * }
 *
 * // override withLifecycleCallbacks definition
 * const customWithLifecycleCallbacks = (
 *   dataProvider: DataProvider,
 *   handlers: CustomResourceCallbacks[]
 * ) => withLifecycleCallbacks(dataProvider, handlers);
 *
 * export const dataProvider = customWithLifecycleCallbacks(baseDataProvider, [
 *   {
 *     resource: "posts",
 *     afterGetManyReference: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     afterGetList: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     binaryUpload: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *     binaryConvert: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *   },
 * ]);
 *
 */
