import { fetchUtils } from 'react-admin';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';

import {
  GetAssetPublicationsResult,
  PublicationListSearchParams,
  GetPublicationListResult,
  PublicationListItemResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ PAGETRACKER PROVIDER -------------
// -----------------------------------------------
export const pagetrackerProvider = {
  getAssetPublications: async (asset_id: string): Promise<GetAssetPublicationsResult> => {
    try {
      const url = `/pagetracker/publication/asset/${asset_id}/`;

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      return data;
    } catch (error) {
      console.error('[getAssetPublications] error:' + error?.toString());
      throw error;
    }
  },

  getPublicationList: async (
    source: string,
    params: PublicationListSearchParams,
  ): Promise<GetPublicationListResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.page && { page: params.page }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
      };

      const url = `/pagetracker/${source}/search/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      const result: GetPublicationListResult = {
        status: data.status,
        data: {
          total: data.data.total,
          next: data.data.next,
          prev: data.data.prev,
          items: data.data.items.map((item: any) => ({
            ...item,
          })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getSectionList] error:' + error?.toString());
      throw error;
    }
  },

  createPublication: async (
    asset_id: string,
    data: any,
  ): Promise<PublicationListItemResult> => {
    try {
      const url = `/pagetracker/publication/asset/${asset_id}/`;

      const { data: response } = await httpClient({
        url: url,
        method: 'POST',
        data: JSON.stringify(data),
      });

      return response;
    } catch (error) {
      console.error('[createPublication] error:' + error?.toString());
      throw error;
    }
  },

  updatePublication: async (
    asset_id: string,
    data: any,
  ): Promise<PublicationListItemResult> => {
    try {
      const url = `/pagetracker/publication/asset/${asset_id}/`;

      const { data: response } = await httpClient({
        url: url,
        method: 'PUT',
        data: JSON.stringify(data),
      });

      return response;
    } catch (error) {
      console.error('[updatePublication] error:' + error?.toString());
      throw error;
    }
  },
};
