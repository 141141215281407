import { fetchUtils } from 'react-admin';
import {
  ChatQueryParams,
  ChatQueryResult,
  ChatRequest,
  OmnibarGPTQueryParams,
  OmnibarGPTQueryResult,
} from 'utility/types/dataProvider';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// ------------ AI PROVIDER ---------------------
// -----------------------------------------------
export const artificalIntelligenceProvider = {
  // Open AI chat
  openAIChat: async ({ messages }: ChatQueryParams): Promise<ChatQueryResult> => {
    try {
      const payload: ChatRequest = {
        messages: messages,
      };

      const path = `/core/openai/chat/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(payload),
      });

      const responseData = data;
      const result: ChatQueryResult = {
        status: 'success',
        data: responseData.choices[0].message.content,
      };
      return result;
    } catch (error) {
      console.error('[openAIChat] Error', error);
      throw error;
    }
  },

  // Query the Omnibar AI API Endpoint
  getOmnibarGPTQuery: async ({
    query,
  }: OmnibarGPTQueryParams): Promise<OmnibarGPTQueryResult> => {
    try {
      const payload = {
        ...(query && { query: query }),
      };

      const path = `/core/openai/omnibar/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: OmnibarGPTQueryResult = {
        status: data?.status || status,
        data: {
          function: data?.data?.function,
          args: data?.data?.args,
        },
      };
      return result;
    } catch (error) {
      console.error('[getOmnibarGPTQuery] Error', error);
      throw error;
    }
  },
};
