import { fetchUtils, UserIdentity } from 'react-admin';
import {
  AvailabilityResponse,
  GetAudienceByIdResult,
  GetUserAccountByIdResult,
  GetUserAccountSearchResult,
  GetUserGroupsByIdParams,
  GetUserGroupsByIdResult,
  GetUserMeFullResponse,
  UpdateUserGroupsByIdResult,
  UserAccountGroups,
  UserAccountSearchParams,
  UserAccountSummary,
} from 'utility/types/dataProvider';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// ------------ USERS PROVIDER -------------------
// -----------------------------------------------
export const usersProvider = {
  // [GET] /user/me/ Retrieve the active users profile
  getUserMe: async (): Promise<GetUserMeFullResponse> => {
    try {
      const path = `/user/me/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetUserMeFullResponse = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getUserMe] Error:', error);
      throw error;
    }
  },

  // [GET] user/account/search
  getUserAccountSearch: async (
    params: UserAccountSearchParams,
  ): Promise<GetUserAccountSearchResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.account_type && { account_type: params.account_type }),
        ...(params.account_status && { account_status: params.account_status }),
        ...(params.auth_type && { auth_type: params.auth_type }),
        ...(params.banned && { banned: params.banned }),
        ...(params.page && { page: params.page }),
        ...(params.sort && { sort: params.sort }),
        ...(params.dir && { dir: params.dir }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }), // Limit capped to 100 by API.
      };

      const path = `/user/account/search/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetUserAccountSearchResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data?.items?.map((item: any) => ({
            uuid: item?.uuid,
            account_type: item?.account_type,
            email: item?.email,
            screen_name: item?.screen_name,
            avatar: item?.avatar,
            first_name: item?.first_name,
            last_name: item?.last_name,
            title: item?.title,
            department: item?.department,
            ...item,
          })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getUserAccountSearch] Error:', error);
      throw error;
    }
  },

  // Get user/author account summary by ID
  getUserAccountSummaryById: async (uuid: string): Promise<GetUserAccountByIdResult> => {
    try {
      const path = `/user/account/${uuid}/summary/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetUserAccountByIdResult = {
        status: data?.status || status,
        data: {
          uuid: data?.data?.uuid,
          account_type: data?.data?.account_type,
          email: data?.data?.email,
          screen_name: data?.data?.screen_name,
          avatar: data?.data?.avatar,
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          title: data?.data?.title,
          department: data?.data?.department,
          ...data?.data,
        },
      };
      return result;
    } catch (error) {
      console.error('[getUserAccountSummaryById] Error:', error);
      throw error;
    }
  },

  getUserAccountById: async (uuid: string): Promise<UserIdentity> => {
    try {
      const path = `/user/account/${uuid}/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: UserIdentity = data?.data;
      return result;
    } catch (error) {
      console.error('[getUserAccountById] Error:', error);
      throw error;
    }
  },

  // Get the group names the active user is assigned to
  getActiveUserGroups: async (): Promise<UserAccountGroups> => {
    try {
      const path = `/user/me/groups/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error('[getActiveUserGroups] Error:', error);
      throw error;
    }
  },

  // Retrieve a light summary of the active user's account
  getActiveUserSummary: async (): Promise<UserAccountSummary> => {
    try {
      const path = `/user/me/summary/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: UserAccountSummary = {
        uuid: data?.data?.uuid,
        account_type: data?.data?.account_type,
        email: data?.data?.email,
        screen_name: data?.data?.screen_name,
        avatar: data?.data?.avatar,
        first_name: data?.data?.first_name,
        last_name: data?.data?.last_name,
        title: data?.data?.title,
        department: data?.data?.department,
        subscription: data?.data?.subscription,
        ...data?.data,
      };
      return result;
    } catch (error) {
      console.error('[getActiveUserSummary] Error:', error);
      throw error;
    }
  },

  // User: Send instant login email
  sendInstantLogin: async (uuid: string, params?: any) => {
    try {
      const path = `/user/account/${uuid}/instant/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      return data;
    } catch (error) {
      console.error('[sendInstantLogin] Error:', error);
      throw error;
    }
  },

  // User: Send password reset email
  sendPasswordReset: async (uuid: string, params?: any) => {
    try {
      const path = `/user/account/${uuid}/auth/password/reset/`;

      const response = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      return response;
    } catch (error) {
      console.error('[sendPasswordReset] Error:', error);
      throw error;
    }
  },

  // User: Set password
  setPassword: async (uuid: string, params?: any) => {
    try {
      const path = `/user/account/${uuid}/auth/password/`;

      const response = await httpClient({
        url: path,
        method: 'PUT',
        data: JSON.stringify(params),
      });

      return response;
    } catch (error) {
      console.error('[setPassword] Error:', error);
      throw error;
    }
  },

  // User: check availability of screen_name or email
  checkAvailability: async (
    name: 'screen_name' | 'email',
    value: string,
  ): Promise<AvailabilityResponse> => {
    try {
      const encodedValue = encodeURIComponent(value);
      const path = `/user/account/available/${name}/?${name}=${encodedValue}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const formattedResult = {
        status: data?.status || status,
        available: data?.data?.available,
      };
      return formattedResult;
    } catch (error) {
      console.error('[checkAvailability] Error:', error);
      return {
        status: 'error',
      };
    }
  },

  // Get /user/group/names/
  getUserGroupNames: async (): Promise<string[] | null> => {
    try {
      const path = `/user/group/names/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const groupNames: string[] = Object.values(data?.data || {}).filter(
        (value): value is string => typeof value === 'string',
      );
      return groupNames;
    } catch (error) {
      console.error('[getUserGroupNames] Error:', error);
      throw error;
    }
  },

  // Update /user/admin/{id}/groups/
  updateUserGroupsById: async (uuid: string, groups: string[]) => {
    try {
      const path = `/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
        {},
        stringifyOptions,
      )}`;

      const requestBody = JSON.stringify(groups);

      const { data, status } = await httpClient({
        url: path,
        method: 'PUT',
        data: requestBody,
      });

      const result: UpdateUserGroupsByIdResult = {
        status: data?.status || status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[updateUserGroupsById] Error:', error);
      throw error;
    }
  },

  // Get /user/admin/{id}/groups/
  getUserGroupsById: async ({
    uuid,
  }: GetUserGroupsByIdParams): Promise<GetUserGroupsByIdResult> => {
    try {
      const path = `/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
        {},
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetUserGroupsByIdResult = {
        status: data?.status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[getUserGroupsById] Error:', error);
      throw error;
    }
  },

  updateUserData: async (uuid: string, params: any): Promise<UserAccountSummary> => {
    try {
      const path = `/user/account/${uuid}/`;

      const { data } = await httpClient({
        url: path,
        method: 'PUT',
        data: JSON.stringify(params),
      });

      const result: UserAccountSummary = {
        uuid: data?.data?.uuid,
        account_type: data?.data?.account_type,
        email: data?.data?.email,
        screen_name: data?.data?.screen_name,
        avatar: data?.data?.avatar,
        first_name: data?.data?.first_name,
        last_name: data?.data?.last_name,
        title: data?.data?.title,
        department: data?.data?.department,
        subscription: data?.data?.subscription,
        ...data?.data,
      };

      return result;
    } catch (error) {
      console.error('[updateUserData] Error:', error);
      throw error;
    }
  },

  // Get /dmp/audiences/{id}/
  getAudienceById: async (id: string): Promise<GetAudienceByIdResult> => {
    try {
      const path = `/dmp/audiences/${id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetAudienceByIdResult = {
        status: data?.status,
        data: { ...data?.data },
      };
      return result;
    } catch (error) {
      console.error('[getAudienceById] Error:', error);
      throw error;
    }
  },

  // User: Send administrator invitation email.
  postAdminInvite: async (payload: { invitee_email: string; groups?: string[] }) => {
    try {
      const path = `/user/admin/invite/`;

      const response = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(payload),
      });

      return response;
    } catch (error) {
      console.error('[postAdminInvite] Error:', error);
      throw error;
    }
  },

  // [GET] /user/account/{id}/linked/
  getLinkedAccounts: async (userId: string) => {
    try {
      const path = `/user/account/${userId}/linked/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      return data;
    } catch (error) {
      console.error('[getLinkedAccounts] Error:', error);
      throw error;
    }
  },

  // [POST] /user/account/{id}/linked/{linked_user_id}/
  linkUser: async (primaryUserId: string, linkedUserId: string) => {
    try {
      const path = `/user/account/${primaryUserId}/linked/${linkedUserId}/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
      });

      return data;
    } catch (error) {
      console.error('[linkUser] Error:', error);
      throw error;
    }
  },

  // [DELETE] /user/account/{id}/linked/{linked_user_id}/
  unlinkUser: async (primaryUserId: string, linkedUserId: string) => {
    try {
      const path = `/user/account/${primaryUserId}/linked/${linkedUserId}/`;

      const { data } = await httpClient({
        url: path,
        method: 'DELETE',
      });

      return data;
    } catch (error) {
      console.error('[unlinkUser] Error:', error);
      throw error;
    }
  },

  // [POST] /user/account/{id}/linked/{linked_user_id}/approve/
  approveLinkedUser: async (primaryUserId: string, linkedUserId: string) => {
    try {
      const path = `/user/account/${primaryUserId}/linked/${linkedUserId}/approve/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
      });

      return data;
    } catch (error) {
      console.error('[approveLinkedUser] Error:', error);
      throw error;
    }
  },
};
