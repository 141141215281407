import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';
import { fetchUtils } from 'react-admin';
import {
  GetDefaultSiteResult,
  GetSitesParams,
  GetSitesResult,
  SessionConfigResult,
  SiteItem,
  StatusOnlyResponse,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SESSION PROVIDER -----------------
// -----------------------------------------------
export const sessionsProvider = {
  // Get user's default site
  getDefaultSite: async (): Promise<GetDefaultSiteResult> => {
    try {
      const path = `/core/session/sites/default/`;

      const { data, status } = await httpClient({
        url: path,
      });

      const result: GetDefaultSiteResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getDefaultSite] Error:', error);
      throw error;
    }
  },

  // Set user's default site
  setDefaultSite: async (domain: string): Promise<StatusOnlyResponse> => {
    try {
      const path = `/core/session/sites/default/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify({ domain: domain }),
      });

      // Return the status and data. Should be Status 204 if successful.
      const result = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[setDefaultSite] Error:', error);
      throw error;
    }
  },

  // Get user's session config via the httpClient function
  // Please note a similar function is in the CosmosContext, if you update
  // it here, you may need to update it there as well.
  getSessionConfig: async (): Promise<SessionConfigResult> => {
    try {
      const path = `/core/session/config/`;

      const { data, status } = await httpClient({
        url: path,
      });

      const result: SessionConfigResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getSessionConfig] Error:', error);
      throw error;
    }
  },

  // Get site access list
  getSites: async (params: GetSitesParams): Promise<GetSitesResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
        ...(params.page && { page: params.page }),
      };

      const path = `/core/session/sites/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetSitesResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data?.items?.map((item: SiteItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getSites] Error:', error);
      throw error;
    }
  },
};
