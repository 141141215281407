import { stringify } from 'query-string';
import { TGetWidgetDataByIdParams } from 'utility/types/dataProvider';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// ----------- DASHBOARDS PROVIDER ---------------
// -----------------------------------------------
export const dashboardsProvider = {
  // Dashboards.
  getDashboardById: async ({ id }: { id: string }) => {
    const path = `/insights/dashboard/${id}/`;

    try {
      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      return {
        status: data?.status || status,
        data: data?.data,
      };
    } catch (error) {
      console.error('[getDashboardById] Error', error);
    }
  },

  getWidgetDataById: async ({ id, assetUUID }: TGetWidgetDataByIdParams) => {
    try {
      let path = `/insights/widget/${id}/data/`;

      if (!!assetUUID) {
        const query = stringify({ assetUUID });
        path += `?${query}`;
      }

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error(`[getWidgetDataById] Error (Widget ID): ${id} Error:`, error);
      throw error;
    }
  },
};
