import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import BloxLoadingSplash from './components/generic/BloxLoadingSplash';
import { SetupAuthentication } from './providers/contexts/CosmosAuthEnvContext';
import { CosmosProvider } from './providers/contexts/CosmosContext';
import { CosmosRAProviders } from './providers/contexts/CosmosRAProvidersContext';
import { globalStyles } from './themes/constants';
import { CosmosHistoryProvider } from './providers/contexts/CosmosHistoryContext';
import { CosmosQueryClientWrapper } from 'providers/clients/CosmosQueryClientWrapper';

const LazyApp = lazy(() => import('./App'));

const hoistedGlobalStyles = <GlobalStyles styles={globalStyles} />;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<BloxLoadingSplash message="Initializing..." />}>
    {hoistedGlobalStyles}
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <CosmosHistoryProvider>
        <CosmosQueryClientWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SetupAuthentication>
              <React.StrictMode>
                <CosmosProvider>
                  <CosmosRAProviders>
                    <LazyApp />
                  </CosmosRAProviders>
                </CosmosProvider>
              </React.StrictMode>
            </SetupAuthentication>
          </LocalizationProvider>
        </CosmosQueryClientWrapper>
      </CosmosHistoryProvider>
    </BrowserRouter>
  </Suspense>,
);

// These lines can be used for debugging, oidc, query-client.
// import { Log } from 'oidc-client-ts';
// Log.setLogger(console);  Log.setLevel(Log.DEBUG);
// import { ReactQueryDevtools } from 'react-query/devtools';
// <ReactQueryDevtools initialIsOpen={true} />
// Hoisted the <GlobalStyles> per MUI Recommendation
// https://mui.com/material-ui/customization/how-to-customize/#:~:text=hoist%20the%20%3CGlobalStyles%20/%3E
