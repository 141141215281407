import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { SxProps, Theme, alpha, useTheme } from '@mui/material/styles';
import { capitalizeFirstLetter } from '../../utility/functions';
import { ApiErrorResponse } from 'utility/types/dataProvider';

export interface ErrorDetails {
  status?: number | string | null | undefined;
  message?: number | string | null | undefined;
  fullDetails?: ApiErrorResponse | Record<string, string> | null | undefined; // pass full error in slot for showDetails
  [key: string]: any;
}

export interface ErrorAlertsProps {
  severity?: AlertProps['severity'];
  showSeverity?: boolean; // Whether to show the severity of the alert
  headerText?: string; // headerText to display in the alert title
  headerTextFontSize?: string | number; // headerText font size
  headerTextFontWeight?: string | number; // headerText font weight
  errors?: ErrorDetails[]; // An array of objects conforming to ErrorDetails
  showDetails?: boolean; // Whether to show the error details - pass full error in details
  buttonOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // The onClick event for the button (if any)
  buttonText?: string; // The buttonText for the button - must be used with buttonOnClick
  buttonIcon?: React.ReactNode; // The buttonIcon for the button - must be used with buttonOnClick
  sx?: SxProps<Theme>; // The sx prop spread to the Alert component
}

const ErrorAlerts = ({
  errors = [],
  showDetails = false,
  showSeverity = false,
  headerTextFontSize = '20px',
  headerTextFontWeight = '500',
  severity = 'error',
  headerText = '',
  sx,
  buttonOnClick,
  buttonText,
  buttonIcon,
}: ErrorAlertsProps) => {
  const theme = useTheme();
  return (
    <Alert
      severity={severity}
      action={
        buttonText && buttonOnClick ? (
          <Button
            color={severity}
            id="error-alert-button"
            variant="contained"
            disableElevation
            size="small"
            onClick={buttonOnClick}
            startIcon={buttonIcon}
          >
            {buttonText}
          </Button>
        ) : undefined
      }
      sx={{
        outline: `1px solid ${theme?.palette?.[severity]?.light}`,
        borderRadius: 1,
        p: 1,
        m: 0.5,
        '& .MuiAlert-action': {
          px: 0,
          py: 0.5,
          mx: 'auto',
          '& .MuiButton-startIcon': {
            mr: 0.5,
            p: 0,
          },
        },
        '& .MuiAlertTitle-root': {
          fontSize: '16px',
          fontWeight: '500',
          m: 0,
          p: 1,
          border: `1px solid ${alpha(theme?.palette?.[severity]?.main, 0.25)}`,
          backgroundColor: alpha(theme?.palette?.[severity]?.light, 0.25),
          borderRadius: 1,
        },
        '& .MuiAlert-icon': {
          m: 0,
          py: 1,
        },
        '& .MuiAlert-message': {
          alignSelf: 'center',
          flexGrow: 1,
          mx: 1,
          my: 0,
          p: 0,
        },
        '& #error-header-text': {
          fontSize: headerTextFontSize,
          fontWeight: headerTextFontWeight,
          p: 0.5,
        },
        ...(typeof sx === 'function' ? sx(theme) : sx),
      }}
    >
      {headerText && <Typography id="error-header-text">{headerText}</Typography>}
      {showSeverity && <AlertTitle>{capitalizeFirstLetter(severity)}</AlertTitle>}
      {errors.map((error: ErrorDetails, index) => (
        <Box
          key={index}
          id="errors-wrapper-box"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            py: 1,
          }}
        >
          {error?.status && (
            <Typography
              id="error-status"
              title="Error Status"
              fontSize="16px"
              fontWeight={500}
            >
              {error.status}
            </Typography>
          )}

          {error?.message && (
            <Typography
              id="error-message"
              title="Error Message"
              fontSize="14px"
            >
              {error.message}
            </Typography>
          )}

          {showDetails && error?.fullDetails && (
            <Accordion
              sx={{
                bgcolor: theme.palette.action.hover,
                border: `1px solid ${theme.palette.action.hover}`,
                boxShadow: 'none',
                '& .MuiAccordionSummary-root': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  '& .MuiAccordionSummary-content': {
                    m: 0,
                    p: 1,
                  },
                },
                '& .MuiAccordionDetails-root': {
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 1,
                  p: 1,
                  m: 0,
                },
                '& .MuiButtonBase-root': {
                  m: 0,
                  px: 1,
                  minHeight: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="error-details-content"
                id="error-details-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                >
                  Error Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  id="error-details-data"
                  component="pre"
                  whiteSpace="pre-wrap"
                  fontWeight="400"
                  fontSize="14px"
                  fontFamily="monospace"
                >
                  {JSON.stringify(error?.fullDetails, null, 2)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      ))}
    </Alert>
  );
};

export default ErrorAlerts;
