import { flatten } from 'flat';

// --- Helper Functions ---

/**
 * Flatten Objects for Square Bracket Syntax
 * https://github.com/hughsk/flat
 * e.g.  "preview[display]: "750w", handle[deep][nested][values]: "yes"
 **/
export function flattenObject<T extends Record<string, any>>(
  input: T,
  bracketed = true,
): Record<string, string> {
  const flattened = flatten<T, Record<string, any>>(input, {
    delimiter: '.',
    safe: true,
  });

  // Optionally return early without bracketed syntax
  if (!bracketed) {
    return flattened;
  }

  // Post-process each flattened key to convert dots into bracket notation
  // Example: "preview.size1" → "preview[size1]"
  const formatted = Object.fromEntries(
    Object.entries(flattened).map(([flatKey, value]) => {
      const bracketedKey = flatKey
        .split('.')
        .reduce((acc, segment, i) => (i === 0 ? segment : `${acc}[${segment}]`), '');
      return [bracketedKey, String(value)];
    }),
  );

  return formatted;
}

/** Process related content/children */
export function relatedContent(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.documentElement;
  const bloxInlineElements = element.querySelectorAll('blox-inline');
  const extractedData = [];
  for (const bloxInlineElement of bloxInlineElements) {
    if (bloxInlineElement.getAttribute('placeholder') === 'true') {
      continue; // Skip processing if placeholder is true
    }

    extractedData.push({
      uuid: bloxInlineElement.getAttribute('uuid'),
      app: bloxInlineElement.getAttribute('app'),
      type: 'child',
    });
  }

  const children = params.children
    ? [...params.children, ...extractedData]
    : extractedData;

  const transformedData = {
    ...params,
    children: children,
  };

  return transformedData;
}

/** Handle Removal of Various Attributes */
export function stripAttributes(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Get the root element of the document
  const elements = doc.documentElement;

  // Get all elements in the DOM
  const allElements = elements.querySelectorAll('*');

  // Iterate through each element
  allElements.forEach(function (element) {
    // Remove TipTap Inline Asset attributes so erroneous data is not saved
    element.removeAttribute('asset');
    // Remove fullPayload attribute
    element.removeAttribute('fullPayload');
    // Remove newInsert attribute
    element.removeAttribute('newInsert');
    // Remove hasRelated attribute
    element.removeAttribute('hasRelated');
  });

  // Serialize the modified document back to HTML string
  const modifiedHtmlString = elements.outerHTML;

  // Return the modified content
  return {
    ...params,
    content: modifiedHtmlString,
  };
}

/** Determine the items unique id for React-Admin's key prop */
export function determineUniqueId(item: any, resource: string) {
  switch (resource) {
    case 'editorial': {
      return item?.uuid;
    }
    case 'user': {
      // React-Admin Unique Key workaround - Concatenate subscription ID if exists.
      // /user/account/search could return multiple users with same UUID, but different subscription IDs rows
      // when payload has subscription == true in query, or view_by subscription, so concat if exists to get unique.
      const uniqueId = `${item?.uuid}${item?.subscription?.id || ''}`;
      return uniqueId;
    }
    case 'user/activities': {
      return item?.id; // This endpoint uses id as unique key.
    }
    case 'savedsearch': {
      return item?.id; // This endpoint uses id as unique key.
    }
    case 'experiments': {
      return item?.name; // This endpoint uses id as unique key.
    }
    case 'wire/channel/search': {
      try {
        const objectId = item?.object_id;
        if (objectId) {
          const parsedObject = JSON.parse(objectId);
          return parsedObject?.external_id || objectId;
        }
        return objectId;
      } catch (e) {
        return item?.object_id;
      }
    }
    default: {
      console.warn('[determineUniqueId] - defaulted!');
      return item?.uuid || item?.id || undefined;
    }
  }
}

export const commonPreviewPayload = {
  preview: {
    size1: '550w',
    size2: '750w',
    thumbnail: '550w',
    display: '750w',
  },
};

export const getResourceDetails = (resource: string, params: any, contexts?: any) => {
  let baseResource = resource;
  let subResource = '';
  let isGetList = 'search/';
  let isGetOne = '';
  let isGetMany = '';
  let isGetManyReference = '';
  let isCreate = '';
  let isUpdate = '';
  let isUpdateMany = '';
  let isDelete = '';
  let isDeleteMany = '';
  let id = `${params?.id}/`;
  let additionalPayload: any = {};
  let modifyPayload: ((payload: any) => void) | null = null;

  switch (resource) {
    case 'editorial':
      subResource = 'asset';

      additionalPayload = {
        ...commonPreviewPayload,
      };

      break;
    case 'user':
      subResource = 'account';
      break;
    case 'user/activities':
      baseResource = 'user';
      subResource = 'activities';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    case 'savedsearch':
      baseResource = 'core';
      subResource = 'savedsearch';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    case 'experiments':
      baseResource = 'core';
      subResource = 'experiment';
      isGetList = '';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    case 'wire/channel/search':
      baseResource = 'wire';
      subResource = `channel`;
      isGetList = `${params?.filter?.channel}/search/`;
      modifyPayload = (payload) => {
        delete payload.channel;

        return payload;
      };
      break;
    case 'wire/channel':
      baseResource = 'wire';
      subResource = `channel`;
      isGetList = '';
      modifyPayload = (payload) => {
        payload.sort = payload.sort === 'uuid' ? 'id' : payload.sort;
        delete payload.sort;

        return payload;
      };
      break;
    case 'wire/driver':
      baseResource = 'wire';
      subResource = `driver`;
      isGetList = '';
      break;
    case 'wire/driver/config':
      baseResource = 'wire';
      subResource = `driver`;
      isGetOne = `${params?.id}/config/`;
      break;
    case 'wire/result':
      id = '';
      baseResource = 'wire';
      subResource = `result`;
      additionalPayload = {
        channel_id: params?.meta?.channel_id,
        object_id: `${decodeURIComponent(params?.id)}`,
      };
      break;
    default:
      console.warn('[getResourceDetails] - No resourceType:', resource);
      break;
  }

  return {
    baseResource,
    subResource,
    id,
    isGetList,
    isGetOne,
    isGetMany,
    isGetManyReference,
    isCreate,
    isUpdate,
    isUpdateMany,
    isDelete,
    isDeleteMany,
    additionalPayload,
    modifyPayload,
  };
};
