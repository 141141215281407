import { flattenObject } from 'providers/functions';
import { fetchUtils } from 'react-admin';
import {
  CancelSubscriptionByIdParams,
  CancelSubscriptionByIdResult,
  CreateSubscriptionParams,
  CreateSubscriptionResult,
  GetServiceByIdParams,
  GetServiceByIdResult,
  GetServiceRatesResult,
  GetServiceTypesParams,
  GetServiceTypesResult,
  GetServicesParams,
  GetServicesResult,
  GetSubscriptionCancelReasonsResult,
  GetSubscriptionDataByIdParams,
  GetSubscriptionDataByIdResult,
  GetSubscriptionServiceByIdResult,
  GetSubscriptionServiceRatesCompareParams,
  GetSubscriptionServiceRatesCompareResult,
  GetUserSubscriptionTransactionsByIdParams,
  GetUserSubscriptionTransactionsByIdResult,
  GetUserSubscriptionsByIdParams,
  GetUserSubscriptionsByIdResult,
  RefundSubscriptionTransactionParams,
  RefundSubscriptionTransactionResult,
  ServiceItem,
  ServiceRate,
  ServiceTypeItem,
  SetSubscriptionChangeRateParams,
  SetSubscriptionChangeRateResult,
  SetSubscriptionDataByIdParams,
  SetSubscriptionDataByIdResult,
  TaxQuoteRequest,
  TaxQuoteResponse,
  UpdateSubscriptionPaymentMethodResult,
} from 'utility/types/dataProvider';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// ------------ SUBSCRIPTIONS PROVIDER -----------
// -----------------------------------------------
export const subscriptionsProvider = {
  // Get site subscription services
  getServices: async (params: GetServicesParams): Promise<GetServicesResult> => {
    try {
      const payload = {
        ...params,
      };
      const path = `/subscription/services/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetServicesResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data?.items?.map((item: ServiceItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getServices] Error:', error);
      throw error;
    }
  },

  getServiceById: async (params: GetServiceByIdParams): Promise<GetServiceByIdResult> => {
    try {
      const path = `/subscription/services/${params.id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetServiceByIdResult = {
        status: data?.status || status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[getServiceById] Error:', error);
      throw error;
    }
  },

  // Get site subscription service types
  getServiceTypes: async (
    params: GetServiceTypesParams,
  ): Promise<GetServiceTypesResult> => {
    try {
      const payload = {
        ...(params.activated && { activated: params.activated }),
        ...(params.available && { available: params.available }),
        ...(params.query && { query: params.query }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
        ...(params.page && { page: params.page }),
      };
      const path = `/subscription/services/types/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetServiceTypesResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data?.items?.map((item: ServiceTypeItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getServiceTypes] Error:', error);
      throw error;
    }
  },

  // Get site subscription service rates
  getServiceRates: async (id: number): Promise<GetServiceRatesResult> => {
    try {
      const path = `/subscription/services/${id}/rates/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetServiceRatesResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          facets: data?.data?.facets,
          items: data?.data?.items?.map((item: ServiceRate) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getServiceRates] Error:', error);
      throw error;
    }
  },

  // Get subscription tax quote
  getTaxQuote: async (
    serviceId: string,
    quoteData: TaxQuoteRequest,
  ): Promise<TaxQuoteResponse> => {
    try {
      const path = `/subscription/services/${serviceId}/quote/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(quoteData),
      });

      const result: TaxQuoteResponse = {
        status: data?.status || status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[getTaxQuote] Error:', error);
      throw error;
    }
  },

  // Create subscription
  createSubscription: async (
    params: CreateSubscriptionParams,
  ): Promise<CreateSubscriptionResult> => {
    try {
      const path = `/subscription/subscriptions/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      const result: CreateSubscriptionResult = {
        status: data?.status || status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[createSubscription] Error', error);
      throw error;
    }
  },

  // Get /subscription/members/{id}/subscriptions
  getUserSubscriptionsById: async ({
    query,
    path,
  }: GetUserSubscriptionsByIdParams): Promise<GetUserSubscriptionsByIdResult> => {
    try {
      const pathId = path?.id;

      if (!pathId) {
        throw new Error('Missing required parameters!');
      }

      const page = query?.page;
      const limit = query?.limit || 99;
      const sort = query?.sort;

      const payload = {
        ...(page && { page: query?.page }),
        ...(limit && { limit: query?.limit || 99 }),
        ...(sort && { sort: query?.sort }),
      };

      const flatBracketPayload = flattenObject(payload, true);

      const pathUrl = `/subscription/members/${pathId}/subscriptions/?${fetchUtils.queryParameters(
        flatBracketPayload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: pathUrl,
        method: 'GET',
      });

      const result: GetUserSubscriptionsByIdResult = {
        status: data?.status || status,
        data: { ...data?.data },
      };
      return result;
    } catch (error) {
      console.error('[getUserSubscriptionsById] Error:', error);
      throw error;
    }
  },

  // get /subscription/subscriptions/{id}/
  getSubscriptionDataById: async ({
    id,
  }: GetSubscriptionDataByIdParams): Promise<GetSubscriptionDataByIdResult> => {
    try {
      const path = `/subscription/subscriptions/${id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetSubscriptionDataByIdResult = {
        status: data?.status || status,
        data: { ...data?.data },
      };

      return result;
    } catch (error) {
      console.error('[getSubscriptionDataById] Error', error);
      throw error;
    }
  },

  // post subscription/subscriptions/{id} (expire time update)
  setSubscriptionDataById: async ({
    id,
    expire_time,
  }: SetSubscriptionDataByIdParams): Promise<SetSubscriptionDataByIdResult> => {
    try {
      if (!id || !expire_time) {
        throw new Error('Missing required paramaters!');
      }

      const path = `/subscription/subscriptions/${id}/?expire_time=${expire_time}`;

      const { status } = await httpClient({
        url: path,
        method: 'POST',
      });

      // success is a 204 no content status
      const result: SetSubscriptionDataByIdResult = {
        status: status,
      };

      return result;
    } catch (error) {
      console.error('[setSubscriptionDataById] Error', error);
      throw error;
    }
  },

  // get /subscription/subscriptions/{id}/transactions/
  getUserSubscriptionTransactionsById: async ({
    id,
    page,
    limit,
  }: GetUserSubscriptionTransactionsByIdParams): Promise<GetUserSubscriptionTransactionsByIdResult> => {
    try {
      const payload = {
        ...(page && { page: page }),
        ...(limit && { limit: limit > 100 ? 99 : limit }),
      };

      const path = `/subscription/subscriptions/${id}/transactions/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetUserSubscriptionTransactionsByIdResult = {
        status: data?.status,
        data: { ...data?.data },
      };

      return result;
    } catch (error) {
      console.error('[getUserSubscriptionTransactionsById] Error', error);
      throw error;
    }
  },

  // post - /subscription/subscriptions/{id}/cancel/?reason_code={reason_code}&reason={reason}
  cancelSubscriptionById: async ({
    id,
    reason_code,
    reason,
  }: CancelSubscriptionByIdParams): Promise<CancelSubscriptionByIdResult> => {
    try {
      if (!id || !reason_code) {
        throw new Error('Missing required paramaters!');
      }

      const basePath = `/subscription/subscriptions/${id}/cancel/`;
      const params = new URLSearchParams({
        ...(reason_code && { reason_code }),
        ...(reason && { reason: reason.trim() }),
      });

      const stringPath = `${basePath}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'POST',
      });

      // success is a 204 no content status
      const result: CancelSubscriptionByIdResult = {
        status: status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[cancelSubscriptionById] Error', error);
      throw error;
    }
  },

  // get - /subscription/introspection/cancel_reasons/
  getSubscriptionCancelReasons: async (): Promise<GetSubscriptionCancelReasonsResult> => {
    try {
      const path = `/subscription/introspection/cancel_reasons/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetSubscriptionCancelReasonsResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getSubscriptionCancelReasons] Error', error);
      throw error;
    }
  },

  // refund success is 204 - /subscription/subscriptions/{id}/transactions/{transaction_id}/refund/
  refundSubscriptionTransaction: async ({
    id,
    transactionId,
  }: RefundSubscriptionTransactionParams): Promise<RefundSubscriptionTransactionResult> => {
    try {
      if (!id || !transactionId) {
        throw new Error('Missing required parameters!');
      }

      const path = `/subscription/subscriptions/${id}/transactions/${transactionId}/refund/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
      });

      // Return the status and data. Should be Status 204 if successful.
      const result: RefundSubscriptionTransactionResult = {
        status: status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[refundSubscriptionTransaction] Error', error);
      throw error;
    }
  },

  // get /subscription/services/{id} - retrieve service data by id
  getSubscriptionServiceById: async (
    id: string,
  ): Promise<GetSubscriptionServiceByIdResult> => {
    try {
      if (!id) {
        throw new Error('Missing required parameters!');
      }
      const path = `/subscription/services/${id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetSubscriptionServiceByIdResult = {
        status: data?.status || status,
        data: data?.data,
      };
      return result;
    } catch (error) {
      console.error('[getSubscriptionServiceById] Error', error);
      throw error;
    }
  },

  // get subscription/services/{id}/rates/compare/
  getCompareSubscriptionServiceRates: async (
    params: GetSubscriptionServiceRatesCompareParams,
  ): Promise<GetSubscriptionServiceRatesCompareResult> => {
    const { id, rate_id, save_offers, allow_hidden, page, limit } = params;

    if (!id || !rate_id) {
      throw new Error('Missing required parameters!');
    }

    try {
      const basePath = `/subscription/services/${id}/rates/compare/`;

      const params = new URLSearchParams({
        rate_id: rate_id,
        ...(save_offers !== undefined && { save_offers: String(save_offers) }),
        ...(allow_hidden !== undefined && { allow_hidden: String(allow_hidden) }),
        ...(page && { page: String(page) }),
        ...(limit && { limit: String(limit) }),
      });

      const stringPath = `${basePath}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: GetSubscriptionServiceRatesCompareResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getCompareSubscriptionServiceRates] Error', error);
      throw error;
    }
  },

  // subscription/subscriptions/{id}/change_rate/ - 204 success
  setSubscriptionChangeRate: async (
    params: SetSubscriptionChangeRateParams,
  ): Promise<SetSubscriptionChangeRateResult> => {
    try {
      const { id, rate_id } = params;

      if (!id || !rate_id) {
        throw new Error('Missing required parameters!');
      }

      const path = `/subscription/subscriptions/${id}/change_rate/?rate_id=${rate_id}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
      });

      const result: SetSubscriptionChangeRateResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[setSubscriptionChangeRate] Error', error);
      throw error;
    }
  },

  // subscription/subscriptions/{id}/update_payment_method/ - 204 success
  updateSubscriptionPaymentMethod: async (
    id: string,
    paymentMethodId: string,
  ): Promise<UpdateSubscriptionPaymentMethodResult> => {
    try {
      if (!id || !paymentMethodId) {
        throw new Error('Missing required parameters!');
      }

      const url = `/subscription/subscriptions/${id}/update_payment_method/?payment_method=${paymentMethodId}`;

      const { data, status } = await httpClient({
        url: url,
        method: 'POST',
      });

      const result: UpdateSubscriptionPaymentMethodResult = {
        status: status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[updateSubscriptionPaymentMethod] error:', error);
      throw error;
    }
  },
};
