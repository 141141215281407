import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const CNNIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="28"
                fill="none"
                viewBox="0 0 511.9 237.2"
            >
                <path fill="currentColor" d="M204.3,1.3c-14.2,3.1-25.5,13-31.5,27.6-1.1,2.7-1.5,16.6-1.9,69.2l-.5,65.9-2.8,2.4-2.8,2.4h-25.2c-28.5,0-35-.9-45-6.2-18.1-9.6-29.1-31.2-25.8-51.1,2.3-13.8,9.2-25.1,20.4-33.1,11.3-8,15.6-9,43-9.4l23.7-.4v-27.8h-23c-17.1,0-25.1.4-30.8,1.6-30.2,6.2-54.4,30.1-60.7,60.2-1.9,8.6-1.9,23.8,0,32.4,3.1,15,9.6,26.8,21,38.4,9.4,9.5,18.1,15,31.5,19.6l9.5,3.3,45.2.3,45.3.3,2.5-2.5,2.5-2.5V40.3l2.3-3.9c3.3-5.8,6.7-7.9,13.4-8.3,6.8-.5,11.2,1.5,15.4,6.8,1.5,1.9,11.5,18.5,22.2,36.9s31.2,53.7,45.6,78.5c14.4,24.7,27.1,45.8,28.2,46.8,2.6,2.1,4.9,2.2,7.5.3,1.8-1.4,1.9-3.7,2.4-80.4.5-78.3.5-79.1,2.6-81.9,8-10.7,22.6-9.6,30.2,2.4,2.7,4.4,22.7,38.6,72.1,123.8,10.5,18.1,20.3,34,21.7,35.3,2.9,2.6,5.6,2.8,7.8.6s1.6-11.7,1.6-99V.8h-28l-.2,56.7-.3,56.8-16.9-29c-39.4-67.6-40.5-69.3-52.1-77-25.3-16.8-56.4-5.9-65.5,23-.9,2.8-1.5,16-1.9,43.2l-.6,39.3-17-29.3c-34.3-59.1-36.2-62.2-44.2-69.7-8.3-8-14.8-11.6-24.2-13.6-7.9-1.6-8.8-1.6-16.7.1Z" />
                <path fill="currentColor" d="M106.2,1.9C66.3,5.5,30.2,30.6,12,67.4c-32.8,66.5,4.7,146.9,76.9,165.4,4.1,1,12.2,2.4,17.9,2.9,13.4,1.4,86.6,1.4,95.1,0,13.5-2.1,25.1-9.9,30.8-20.6,5.3-10.2,5.5-11.2,6.1-52.6l.6-38.7,26,44.7c22.9,39.4,26.9,45.7,33.2,52.4,8.5,8.9,16,13.3,26.5,15.5,18.5,3.9,37.6-6.1,45.9-23.9l3.4-7.2.5-40.9c.4-29.8.8-40.5,1.6-39.5.6.8,12,20.2,25.3,43.2,26.6,45.8,30.5,51.6,38.2,57.7,22.6,17.9,50.4,14.3,65-8.3,7.1-10.9,6.9-7.8,6.9-118.1V.8h-28l-.2,98.8-.3,98.8-2.5,3.7c-6.6,9.6-19.5,10.3-27.6,1.6-1.7-1.9-10.7-16.5-19.9-32.4-14.7-25.4-30.7-52.9-64.8-111.5-4.9-8.5-10.1-16.7-11.5-18.3-3-3.1-6.2-3.5-8.5-.9-1.6,1.7-1.7,8.6-1.7,78.8v77l-2.2,4.4c-5.6,11-21.6,12-29,1.9-1.3-1.7-22.6-38-47.4-80.7-24.9-42.7-46.3-78.9-47.7-80.5-2.9-3.2-5.9-3.5-8.7-.7s-2,3.3-2,77.5-.2,75.9-1.6,78.8c-2.3,4.5-4.4,6.6-8.6,8.8-3.5,1.8-6.5,1.9-48.2,1.9s-50.6-.3-63.6-4.8c-38.7-13.4-63.8-53.4-59-93.9,4.7-39.4,32.4-69.6,71.3-77.8,4.9-1.1,13.7-1.5,31.3-1.5h24.4V.8h-20.2c-11.2.2-24.4.6-29.5,1.1Z" />
            </svg>
        </SvgIcon>
    );
};

export default CNNIcon;
