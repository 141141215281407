import { httpClient } from 'providers/clients/httpClient';
import { AddExperimentsResult } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ EXPERIMENTS PROVIDER -------------
// -----------------------------------------------
export const experimentsProvider = {
  updateExperiment: async (name: string, enabled: boolean): Promise<any> => {
    try {
      const params = {
        enabled: enabled,
      };

      const path = `/core/experiment/${name}/`;

      const response = await httpClient({
        url: path,
        method: 'PUT',
        data: JSON.stringify(params),
      });

      return response;
    } catch (error) {
      console.error('[updateExperiment] Error:', error);
      throw error;
    }
  },

  addExperiment: async (name: string): Promise<any> => {
    try {
      const params = {
        name: name,
      };
      const path = `/core/experiment/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      const result: AddExperimentsResult = { ...data.data }; // Key-value pair using the id as the key
      return result;
    } catch (error) {
      console.error('[addExperiment] Error:', error);
      throw error;
    }
  },

  deleteExperiment: async (name: string): Promise<any> => {
    try {
      const path = `/core/experiment/${name}/`;

      const response = await httpClient({
        url: path,
        method: 'DELETE',
      });

      return response;
    } catch (error) {
      console.error('[deleteExperiment] Error:', error);
      throw error;
    }
  },
};
