import { httpClient } from 'providers/clients/httpClient';
import { stringify } from 'query-string';
import {
  AnalysisRequest,
  AnalysisResponse,
  BinaryUploadParams,
  DeleteEditorialAssetIdLockPathParams,
  DeleteEditorialAssetIdLockResult,
  GetEditorialAssetIdLockParams,
  GetEditorialAssetIdLockResult,
  GetEditorialConfigResult,
  KeywordSuggestion,
  PutEditorialAssetIdLockParams,
  PutEditorialAssetIdLockResult,
} from '../../../utility/types/dataProvider';

// Default Cosmos API origin for asset locking
// "blox-admin" is what the API default origin uses
export const NXT_LOCK_ORIGIN = 'blox-admin';

// -----------------------------------------------
// ------------ EDITORIAL PROVIDER ---------------
// -----------------------------------------------
export const editorialProvider = {
  binaryUpload: async (resource: string, params: BinaryUploadParams) => {
    try {
      const path = `${resource}/`;
      const formData = new FormData();

      if (params.file) {
        formData.append('file', params.file);
      }

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: formData,
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[binaryUpload] Error:', error);
      throw error;
    }
  },

  binaryConvert: async (resource: string, params: any) => {
    try {
      const path = `${resource}/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(params),
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[binaryConvert] Error:', error);
      throw error;
    }
  },

  // Get editorial config
  getEditorialConfig: async (): Promise<GetEditorialConfigResult> => {
    try {
      const path = `/editorial/config/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const editorialConfig: GetEditorialConfigResult = data;
      return editorialConfig;
    } catch (error) {
      console.error('[getEditorialConfig] Error:', error);
      throw error;
    }
  },

  // Editorial semantic analysis
  semanticAnalysis: async ({
    request,
  }: {
    request: AnalysisRequest;
  }): Promise<AnalysisResponse> => {
    try {
      const path = `/editorial/semantic/analyze/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(request),
      });

      const responseData = data;
      const keywords: KeywordSuggestion[] = responseData.data.keywords.map(
        (keywordData: any) => ({
          tag: keywordData.tag,
          score: keywordData.score,
        }),
      );
      const response: AnalysisResponse = {
        status: 'success',
        data: {
          keywords: keywords,
          categories: responseData.data.categories || [],
          iptc: responseData.data.iptc || [],
          iab: responseData.data.iab || [],
        },
      };
      return response;
    } catch (error) {
      console.error('[semanticAnalysis] Error:', error);
      throw error;
    }
  },

  // Revision history list
  getAssetRevisionHistory: async (
    assetId: string,
    params: {
      page?: number;
      limit?: number;
    },
  ) => {
    try {
      let path = `/editorial/asset/${assetId}/revisions/`;

      if (params) {
        path += `?${stringify(params)}`;
      }

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      return data;
    } catch (error) {
      console.error('[getAssetRevisionHistory] Error:', error);
      throw error;
    }
  },

  // Get asset revision by id
  getAssetRevisionById: async (assetId: string, revisionId: string) => {
    try {
      const path = `/editorial/asset/${assetId}/revisions/${revisionId}/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      return { ...data, revisionId, assetId };
    } catch (error) {
      console.error('[getAssetRevisionById] Error:', error);
      throw error;
    }
  },

  // -------------------------------
  // Editorial Asset Locking
  // -------------------------------

  // GET /editorial/asset/{id}/lock/
  getAssetIdLock: async (
    params: GetEditorialAssetIdLockParams,
  ): Promise<GetEditorialAssetIdLockResult> => {
    try {
      const assetId = params?.path?.id;

      if (!assetId) {
        throw new Error('[getAssetIdLock] Asset ID is required.');
      }

      const url = `/editorial/asset/${assetId}/lock/`;

      const { data } = await httpClient<GetEditorialAssetIdLockResult>({
        url: url,
        method: 'GET',
      });

      const result = {
        status: data?.status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getAssetIdLock] Error:', error);
      throw error;
    }
  },

  // PUT /editorial/asset/{id}/lock/
  putAssetIdLock: async (
    params: PutEditorialAssetIdLockParams,
  ): Promise<PutEditorialAssetIdLockResult> => {
    try {
      const path = params?.path;
      const body = params?.body;

      if (!path.id) {
        throw new Error('[putAssetIdLock] Asset ID is required.');
      }

      // if missing body or origin is not our const
      // then create and/or set origin to our value
      if (!body || body?.origin !== NXT_LOCK_ORIGIN) {
        params.body = {
          origin: NXT_LOCK_ORIGIN,
        };
      }

      const url = `/editorial/asset/${path?.id}/lock/`;

      const { data, status } = await httpClient({
        url: url,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(body),
      });

      // Success is 204 No Content
      const result = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[putAssetIdLock] Error:', error);
      throw error;
    }
  },

  // DELETE /editorial/asset/{id}/lock/
  deleteAssetIdLock: async (
    params: DeleteEditorialAssetIdLockPathParams,
  ): Promise<DeleteEditorialAssetIdLockResult> => {
    try {
      const assetId = params?.path?.id;

      if (!assetId) {
        throw new Error('[deleteAssetIdLock] Asset ID is required.');
      }

      const url = `/editorial/asset/${assetId}/lock/`;

      const { data, status } = await httpClient({
        url: url,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      // Success is 204 No Content
      const result = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[deleteAssetIdLock] Error:', error);
      throw error;
    }
  },
};
