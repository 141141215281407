import { fetchUtils } from 'react-admin';
import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';
import { SyndicationAvailableChannelsResult } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ WIRE/SYNDICATION PROVIDER ----------
// -----------------------------------------------
export const wireSyndicationProvider = {
  // Get default saved search by application
  getAvailableChannels: async (): Promise<SyndicationAvailableChannelsResult> => {
    try {
      const url = `/wire/channel/`;
      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });
      return data; // Return the JSON payload as the default search
    } catch (error) {
      console.error('[dataProvider] getAvailableChannels() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },

  getChannelConfigurations: async (channelId: string): Promise<any> => {
    try {
      const url = `/wire/channel/${channelId}/search/config/`;
      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });
      return data; // Return the JSON payload as the default search
    } catch (error) {
      console.error(
        '[dataProvider] getChannelConfigurations() error:',
        error?.toString(),
      );
      throw error; // Reduce 404 spam in console.
    }
  },

  importChannelAsset: async (channelId: string, objectId: string): Promise<any> => {
    try {
      const payload = {
        object_id: objectId,
      };
      const url = `/wire/channel/${channelId}/import/`;
      const { data } = await httpClient({
        url: url,
        method: 'POST',
        data: JSON.stringify(payload),
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] importChannelAsset() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },

  getImportStatus: async (channelId: string, objects: string[]): Promise<any> => {
    try {
      // Extract the `external_id` or whatever ID you need from the parsed objects
      const objectIds = objects.map((obj) => obj);

      if (!objectIds || objectIds.length === 0) {
        throw new Error('Missing object_id array');
      }

      // Construct the payload to include object_id as an array
      const payload = {
        object_id: objectIds, // Send array of external_ids
      };

      const url = `/wire/channel/${channelId}/import/status/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] getImportStatus() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },

  getCategoryMap: async (channelId: string | undefined): Promise<any> => {
    try {
      const url = `/wire/channel/${channelId}/map/`;

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] getCategoryMap() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },

  updateCategoryMap: async (channelId: string | undefined, map: any): Promise<any> => {
    try {
      const payload = {
        section_map: {
          ...map,
        },
      };
      const url = `/wire/channel/${channelId}/map/`;

      const { data } = await httpClient({
        url: url,
        method: 'PUT',
        data: JSON.stringify(payload),
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] updateCategoryMap() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },

  deleteCategoryMap: async (channelId: string | undefined): Promise<any> => {
    try {
      const url = `/wire/channel/${channelId}/map/`;

      const { data } = await httpClient({
        url: url,
        method: 'DELETE',
      });

      const formattedResult = {
        data: { ...data },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] deleteCategoryMap() error:', error?.toString());
      throw error; // Reduce 404 spam in console.
    }
  },
};
