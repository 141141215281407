import { httpClient } from 'providers/clients/httpClient';
import {
  GetAssetPreviewResult,
  GetBlockPreviewResult,
  GetPagePreviewResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ PREVIEWS PROVIDER ----------------
// -----------------------------------------------
export const previewsProvider = {
  // Update the function to fetch the asset preview
  getAssetPreview: async (id: string, draft: boolean): Promise<GetAssetPreviewResult> => {
    try {
      const path = `/core/preview/asset/editorial/${id}/${draft ? '?draft=true' : ''}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetAssetPreviewResult = {
        status: 'success',
        data: { [id]: { body: data } }, // Key-value pair using the id as the key
      };

      return result;
    } catch (error) {
      console.error('[getAssetPreview] Error', error);
      throw error;
    }
  },

  // Get a preview rendering of a block instance
  getBlockPreview: async (
    map_id: string,
    block_id: string,
  ): Promise<GetBlockPreviewResult> => {
    try {
      const path = `/core/preview/block/${map_id}/${block_id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetBlockPreviewResult = {
        status: 'success',
        data: { body: data },
      };
      return result;
    } catch (error) {
      console.error('[getBlockPreview] Error', error);
      throw error;
    }
  },

  // Get a preview rendering of a front-end URl
  getPagePreview: async (
    url: string,
    urlmap?: string,
    date?: string,
    live?: boolean,
  ): Promise<GetPagePreviewResult> => {
    try {
      // Ensure url starts and ends with a slash
      if (!url.startsWith('/')) {
        url = `/${url}`;
      }
      if (!url.endsWith('/')) {
        url = `${url}/`;
      }

      // If date is not provided, use the current date to ensure the preview is not from cache
      if (!date) {
        const now = new Date();
        date = now.toISOString();
      }

      const urlParams = new URLSearchParams();
      urlParams.append('url', url);
      if (urlmap) urlParams.append('urlmap', urlmap);
      if (date) urlParams.append('date', date);
      if (live !== undefined) urlParams.append('live', live.toString());

      const path = `/core/preview/page/?${urlParams.toString()}`;

      const { data } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetPagePreviewResult = {
        status: 'success',
        data: { body: data },
      };
      return result;
    } catch (error) {
      console.error('[getPagePreview] Error', error);
      throw error;
    }
  },
};
