import { httpClient } from 'providers/clients/httpClient';
import { AppItem, BlockTemplateResponse, SkinItem } from 'utility/types/dataProvider';

export const templateInfoProvider = {
  // Get installed apps for a template library
  getInstalledApps: async (
    library: string,
    limit: number = 25,
    page: number = 0,
    sort: string = 'title',
  ): Promise<AppItem[]> => {
    try {
      const path = `/core/template/info/${library}/app/?limit=${limit}&page=${page}&sort=${sort}`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data;
    } catch (error) {
      console.error('[getInstalledApps] Error:', error);
      throw error;
    }
  },

  // Get installed URL skins for an app
  getInstalledSkins: async (
    library: string,
    app: string,
    limit: number = 25,
    page: number = 0,
    sort: string = 'title',
  ): Promise<SkinItem[]> => {
    try {
      const path = `/core/template/info/${library}/skin/${app}/?limit=${limit}&page=${page}&sort=${sort}`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data;
    } catch (error) {
      console.error('[getInstalledSkins] Error:', error);
      throw error;
    }
  },

  // Get information about a URL skin
  getSkinInfo: async (
    library: string,
    app: string,
    package_name: string,
  ): Promise<SkinItem> => {
    try {
      const path = `/core/template/info/${library}/skin/${app}/${package_name}/`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data;
    } catch (error) {
      console.error('[getSkinInfo] Error:', error);
      throw error;
    }
  },

  // Get installed block templates for a template library
  getInstalledBlockTemplates: async (
    library: string,
    sort: string = 'title',
    dir: string = 'asc',
    preview: string = '200w',
    limit: number = 25,
    page: number = 0,
    app?: string,
    query?: string,
  ): Promise<BlockTemplateResponse> => {
    try {
      const url = `/core/template/info/${library}/block/?sort=${sort}&dir=${dir}&limit=${limit}&page=${page}&preview=${preview}${
        app ? `&app=${app}` : ''
      }${query ? `&query=${query}` : ''}`;

      const { data } = await httpClient({
        url: url.toString(),
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error('[getInstalledBlockTemplates] Error:', error);
      throw error;
    }
  },

  // Get information about a block template
  getBlockTemplateInfo: async (
    library: string,
    package_name: string,
    preview?: string,
  ): Promise<any> => {
    try {
      const url = `/core/template/info/${library}/block/${package_name}/${
        preview ? `?preview=${preview}` : ''
      }`;

      const { data } = await httpClient({
        url: url.toString(),
        method: 'GET',
      });

      return data?.data;
    } catch (error) {
      console.error('[getBlockTemplateInfo] Error:', error);
      throw error;
    }
  },
};
