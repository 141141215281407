import {
  CreateAccessRuleParams,
  CreateAccessRuleResult,
  DeleteAccessRuleParams,
  DeleteAccessRuleResult,
  GetAccessCriteriaParams,
  GetAccessCriteriaResult,
  GetAccessMethodsParams,
  GetAccessMethodsResult,
  GetAccessOffersParams,
  GetAccessOffersResult,
  GetAccessRuleByIdParams,
  GetAccessRuleByIdResult,
  GetAccessRulesParams,
  GetAccessRulesResult,
  UpdateAccessRuleParams,
  UpdateAccessRuleResult,
} from 'utility/types/dataProvider';
import { httpClient } from 'providers/clients/httpClient';

// -----------------------------------------------
// -------------- Access Control -----------------
// -----------------------------------------------
export const accessControlProvider = {
  // get /access/criteria/
  getAccessCriteria: async (
    params?: GetAccessCriteriaParams,
  ): Promise<GetAccessCriteriaResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const path = `/access/criteria/`;
      const params = new URLSearchParams({
        ...(query && { query }),
        ...(page && { page: String(page) }),
        ...(limit && { limit: String(limit) }),
      });

      const stringPath = `${path}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: GetAccessCriteriaResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getAccessCriteria] Error', error);
      throw error;
    }
  },

  // get /access/offers/
  getAccessOffers: async (
    params?: GetAccessOffersParams,
  ): Promise<GetAccessOffersResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const path = `/access/offers/`;
      const params = new URLSearchParams({
        ...(query && { query: query }),
        ...(page && { page: String(page) }),
        ...(limit && { limit: String(limit) }),
      });

      const stringPath = `${path}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: GetAccessOffersResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getAccessOffers] Error', error);
      throw error;
    }
  },

  // get /access/methods/
  getAccessMethods: async (
    params?: GetAccessMethodsParams,
  ): Promise<GetAccessMethodsResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const path = `/access/methods/`;
      const params = new URLSearchParams({
        ...(query && { query: query }),
        ...(page && { page: String(page) }),
        ...(limit && { limit: String(limit) }),
      });
      const stringPath = `${path}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: GetAccessMethodsResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getAccessMethods] Error', error);
      throw error;
    }
  },

  // get /access/rules/
  getAccessRules: async (
    params?: GetAccessRulesParams,
  ): Promise<GetAccessRulesResult> => {
    const { type, query, is_enabled, dir, sort, page, limit = '99' } = params || {};

    try {
      const path = `/access/rules/`;
      const params = new URLSearchParams({
        ...(type && { type: type }),
        ...(query && { query: query }),
        ...(is_enabled && { is_enabled: String(is_enabled) }),
        ...(dir && { dir: dir }),
        ...(sort && { sort: sort }),
        ...(page && { page: page }),
        ...(limit && { limit: limit }),
      });

      const stringPath = `${path}?${params.toString()}`;

      const { data, status } = await httpClient({
        url: stringPath,
        method: 'GET',
      });

      const result: GetAccessRulesResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getAccessRules] Error', error);
      throw error;
    }
  },

  // get /access/rules/{id}/
  getAccessRuleById: async (
    params: GetAccessRuleByIdParams,
  ): Promise<GetAccessRuleByIdResult> => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error('Missing required parameter!');
      }

      const path = `/access/rules/${id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetAccessRuleByIdResult = {
        status: data?.status || status,
        data: data?.data,
        ...data,
      };

      return result;
    } catch (error) {
      console.error('[getAccessRuleById] Error', error);
      throw error;
    }
  },

  // post /access/rules/ (create)
  createAccessRule: async (
    params: CreateAccessRuleParams,
  ): Promise<CreateAccessRuleResult> => {
    try {
      const path = `/access/rules/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(params),
      });

      const result: CreateAccessRuleResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[createAccessRule] Error', error);
      throw error;
    }
  },

  // put /access/rules/{id}/ (update rule)
  updateAccessRule: async (
    params: UpdateAccessRuleParams,
  ): Promise<UpdateAccessRuleResult> => {
    try {
      const { id, ...body } = params;

      if (!id) {
        throw new Error('Missing required parameter: id');
      }

      const path = `/access/rules/${id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(body),
      });

      const result: UpdateAccessRuleResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[updateAccessRule] Error', error);
      throw error;
    }
  },

  // delete /access/rules/{id}/ (delete rule)
  deleteAccessRule: async (
    params: DeleteAccessRuleParams,
  ): Promise<DeleteAccessRuleResult> => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error('Missing required parameter: id');
      }

      const path = `/access/rules/${id}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'DELETE',
      });

      // Status code 204 if successful, not in json.status
      const result: DeleteAccessRuleResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[deleteAccessRule] Error', error);
      throw error;
    }
  },
};
