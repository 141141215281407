import { stringifyOptions } from 'providers/clients/httpClient';
import { httpClient } from 'providers/clients/httpClient';
import { fetchUtils } from 'react-admin';
import {
  GetKeywordListResult,
  GetSectionListResult,
  KeywordListItem,
  KeywordListSearchParams,
  SectionListItem,
  SectionListSearchParams,
  SectionTreeResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SITE TAXONOMY PROVIDER -----------
// -----------------------------------------------
export const siteTaxonomyProvider = {
  // Get keywords list
  getKeywordList: async (
    params: KeywordListSearchParams,
  ): Promise<GetKeywordListResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.page && { page: params.page }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
      };

      const path = `/core/keyword/list/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetKeywordListResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data?.items.map((item: KeywordListItem) => ({
            ...item,
          })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getKeywordList] Error', error);
      throw error;
    }
  },

  // Get site section tree
  getSectionTree: async (
    id?: number | null | undefined,
  ): Promise<SectionTreeResult['data']> => {
    try {
      const path = id ? `/core/section/tree/?parent=${id}` : `/core/section/tree/`;

      const { data } = await httpClient({
        url: path,
      });

      const result: SectionTreeResult = data;
      return result?.data; // Return section tree
    } catch (error) {
      console.error('[getSectionTree] Error', error);
      throw error;
    }
  },

  // Get section list
  getSectionList: async (
    params: SectionListSearchParams,
  ): Promise<GetSectionListResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.page && { page: params.page }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
      };

      const path = `/core/section/list/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetSectionListResult = {
        status: data?.status || status,
        data: {
          total: data?.data?.total,
          next: data?.data?.next,
          prev: data?.data?.prev,
          items: data?.data.items.map((item: SectionListItem) => ({
            ...item,
          })),
        },
      };
      return result;
    } catch (error) {
      console.error('[getSectionList] Error', error);
      throw error;
    }
  },
};
