import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const StringrIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="28"
                fill="none"
                viewBox="0 0 1421.4 430.8"
            >
                <path fill="currentColor" d="M617.4,1.5c-14.5,3.9-25.6,14.6-29.8,28.5-2,6.8-2.1,18.9-.1,25.2,4.2,13.9,15.6,24.3,30.5,27.8,7.5,1.8,21.1.8,28.1-2,19.7-7.8,29.9-28.5,24.4-49.5-5.8-22.4-30.2-36.1-53.1-30Z" />
                <path fill="currentColor" d="M247.1,76.5v34.5h-42v43.5l-4.1-6.8c-13.2-22-36.7-36.4-69-42.3-13-2.4-40.1-2.4-52.4,0-32.7,6.2-55.2,21.2-66.5,44.1-5.5,11-7.3,18.9-7.4,31.5-.1,19.3,4.9,31.8,17.8,44.6,14.3,14.2,25.5,18.6,68.4,26.9,36,7,41,8.4,46.2,13.4,3.5,3.3,4,4.3,4,8.3,0,7.5-6,14.2-15.4,17.1-6.2,1.8-25.9,1.4-32.1-.8-11.3-3.9-18.8-11-21.6-20.4-1-3.5-1.6-4.2-3.3-3.8-1.2.2-17.3,3.5-35.9,7.3l-33.8,6.9.6,3.5c4.5,24.8,24.8,45.9,53.3,55.6,37.6,12.7,86.2,10.9,117.7-4.6,21.6-10.5,34.2-25.5,39.6-47,1.8-7.1,2.1-10.3,1.6-21-.5-13.6-2.8-22.4-8.3-31.7-4.1-7.1-13.9-16.9-22.1-21.9-11.4-7.1-23.3-10.8-58.6-18-17.6-3.5-33.9-7.3-36.2-8.3-6.2-2.7-9.5-7.2-9.5-13,0-8.3,6.2-14.9,16.1-17,19.8-4.3,40.6,4.9,46.4,20.4,1,2.8,1.9,5.1,2.1,5.3.1.2,15-2.6,33.1-6.3l32.9-6.5h38.4v50.1c0,30.3.4,52.8,1.1,57.2,7.1,48.2,38.8,73.7,91.5,73.7s26.3-1.9,38.6-6,24.8-10,24.8-11.4-3.7-13.8-8.2-29.4c-6.6-22.5-8.6-28.2-9.8-27.8-.8.3-5.5,2-10.5,3.8-8.4,3-9.9,3.3-21.5,3.3s-12.7-.1-17.7-2.9c-2.9-1.6-6.4-4.6-7.8-6.6-5.7-8.2-5.8-9.6-6.3-58.8l-.4-45.2h69.2v-59h-69V42h-74v34.5Z" />
                <path fill="currentColor" d="M1021.9,104.5c-69.3,11.3-110.8,80.9-88.7,148.5,12.4,37.7,43.8,65.5,84.2,74.7,9.1,2,35.1,2.3,45.7.4,15.8-2.8,31.7-9.2,41.3-16.5l4.7-3.6v7.8c0,15.8-4.8,28-15,38.3-10.9,11-24,15.9-43,15.9s-31.1-5.2-45.4-19.2c-4.8-4.9-9-8.8-9.3-8.8-.6,0-48.6,25.8-57.1,30.7l-4.2,2.4,4.2,6.2c9.4,13.9,28,29.4,44.2,36.6,18.6,8.4,36,12,61.4,12.8,22.2.7,35-.5,51.7-4.7,22.7-5.7,39.5-14.5,53.8-28,13.7-13,21.6-25.5,27-42.5,5.3-17,5.2-14.7,5.2-134v-111h-73l-.3,6.7c-.2,3.8-.5,6.8-.7,6.8-.3,0-2.6-1.5-5.2-3.4-9.7-6.9-22.9-12.2-37.3-15.1-11.1-2.2-33.7-2.7-44.2-1ZM1068.9,164.5c9.7,2.3,17.9,7,25.2,14.4,10.7,10.8,15,21.4,15,36.6,0,24.5-14.8,44.1-38.5,51.1-7.8,2.3-22.8,2.3-31.4,0-23.7-6.2-39.1-26.3-39.1-51.1s4.5-26.1,14.2-36.1c7.5-7.9,18.9-13.9,29.8-15.7,5.9-1,19-.6,24.8.8Z" />
                <path fill="currentColor" d="M804.1,106c-18.2,3.9-33,11.3-45.2,22.9l-3.8,3.5v-21.4h-76v234h75.9l.4-65.3c.3-71.8.2-69.4,6.8-83.4,9.9-20.8,33.8-30.3,55.6-22.1,11.7,4.3,22,18.3,24.3,32.8.6,3.9,1,32.8,1,72.2v65.8h74.1l-.3-82.3-.3-82.2-2.7-9.6c-9.8-35.8-34-58.9-68.6-65.5-10.3-2-30.7-1.7-41.2.6Z" />
                <path fill="currentColor" d="M1337.9,108.1c-9.4,1.2-24.1,6.2-31.7,10.6-11.9,7.1-24.3,19.1-33.3,32.3-1.6,2.4-1.7,1.6-1.7-18.8v-21.2h-75.1v233h75v-43.3c0-54.6,1.1-62.9,10.2-81.2,5.5-10.9,16.3-21.7,26.8-26.8,11.8-5.8,21.1-7.8,40-8.5l16.5-.7,1.6-37c.9-20.4,1.6-37.1,1.5-37.2s-3.1-.7-6.6-1.2c-7.4-1.2-13.6-1.2-23.2,0Z" />
                <path fill="currentColor" d="M551.6,109.1c-24.9,3.1-46.9,16.9-62.5,39.1l-5,7.1v-43.3h-75v233h74.8l.5-46.8c.3-42.4.6-47.5,2.4-55.7,5.6-24.7,17.3-40.4,36.8-49.6,11.4-5.3,20.4-7.1,38.6-7.7l15.6-.5.7-11.1c1.4-26,2.8-62.7,2.3-63.2-.9-.9-12.2-2.4-17.2-2.3-2.7.1-8.1.5-12,1Z" />
                <path fill="currentColor" d="M591.1,228v117h75V111h-75v117Z" />
                <path fill="currentColor" d="M1366.7,260.1c-6.5,1.5-15.3,6.9-20.6,12.5-17.8,18.8-14.6,49.9,6.6,64.5,21.4,14.7,53.8,6.5,64.4-16.4,13.7-29.4-7.2-62.1-39.4-61.6-3.9.1-8.8.5-11,1Z" />
            </svg>
        </SvgIcon>
    );
};

export default StringrIcon;
