import { Theme as CreateTheme } from '@mui/material/styles/createTheme';
import { Theme } from '@mui/material';
import { bloxColors, bloxValues } from 'themes/constants';
import { SystemStyleObject } from '@mui/system';
import { CSSProperties } from 'react';

export type RectangularColorSpace =
  | 'srgb'
  | 'srgb-linear'
  | 'display-p3'
  | 'a98-rgb'
  | 'prophoto-rgb'
  | 'rec2020'
  | 'lab'
  | 'oklab'
  | 'xyz'
  | 'xyz-d50'
  | 'xyz-d65';
export type PolarColorSpace = 'hsl' | 'hwb' | 'lch' | 'oklch';
export type HueInterpolationMethod = 'shorter' | 'longer' | 'increasing' | 'decreasing';
export type ColorSpaceMethod =
  | `${RectangularColorSpace | PolarColorSpace} ${HueInterpolationMethod}?`
  | `${RectangularColorSpace | PolarColorSpace}`;

/**
 * Generates a CSS color-mix() function string for styling.
 * @param {string} baseColor - The first color in the mix.
 * @param {number} baseColorPercent - The percentage of the first color in the mix. Must be between 0 and 100.
 * @param {string} mixWithColor - The second color in the mix.
 * @param {string} [colorspaceMethod='srgb'] - The color space to use for mixing the colors, with optional hue method.
 * @returns {string} The CSS color-mix function as a string.
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-mix
 */
export function getColorMix(
  baseColor: string,
  baseColorPercent: number,
  mixWithColor: string,
  colorspaceMethod: ColorSpaceMethod = 'srgb',
): string {
  if (baseColorPercent < 0 || baseColorPercent > 100) {
    throw new Error('baseColorPercent must be between 0 and 100.');
  }
  return `color-mix(in ${colorspaceMethod}, ${baseColor} ${baseColorPercent}%, ${mixWithColor})`;
}

/** Generate a six digit hex color such as #2774AE or #FFD100, using simple `Math.random()`.
 * @returns {string} A six digit hex color string, hash included.
 */
export function getRandomHexColor(): string {
  const hexNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
  let hexColorString = '#';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * hexNumbers.length);
    hexColorString += hexNumbers[randomIndex];
  }
  return hexColorString;
}
export const baseTransitionMs = 225;
export const baseTransitionTiming = 'cubic-bezier(0, 0, 0.2, 1)';
export const baseTransition = `${baseTransitionMs}ms ${baseTransitionTiming}`;

/**
 * Bitwise inverts a hex color. #FFF -> #000 or #FF0000 -> #00FFFF
 * @param {string} hex - The hex color to invert.
 * @returns {string} - The inverted hex color.
 * @throws {Error} - Throws an error if the input is not a valid hex color.
 */
export function invertHexColor(hex: string): string {
  // Validate the hex color input
  const isValidHex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hex);
  if (!isValidHex) {
    throw new Error('Invalid hex color format. Please provide a valid hex color.');
  }

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // If the hex color is in the shorthand format (#RGB), convert it to the full format (#RRGGBB)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Parse the r, g, b values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Invert each color channel
  const invertedR = 255 - r;
  const invertedG = 255 - g;
  const invertedB = 255 - b;

  // Convert each channel back to a hex string and pad with zeros if necessary
  const invertedHex = `#${((1 << 24) + (invertedR << 16) + (invertedG << 8) + invertedB)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return invertedHex;
}

/**
 * Converts a string to a color based on the string's hash code.
 *
 * @param string
 * @returns string
 */

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  if (string.length === 0) return 'inherit';

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

/**
 * Returns text color based on the background color.
 *
 * @param backgroundColor
 * @param theme
 * @returns string
 */

export function getTextColor(backgroundColor: string, theme: Theme) {
  // Convert hex color to RGB components
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Calculate perceived brightness using luminance formula
  const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Choose white or black based on brightness threshold
  return brightness > 0.5 ? theme.palette.common.black : theme.palette.common.white; // Black for light backgrounds, White for dark backgrounds
}

// ----------------------------------------------
// LeftNav, PlusMenu, Bento Styles / Helper Fns
// Reuseable for hover colors and active states
// ----------------------------------------------
export const navLightBaseMixPct = 15;
export const navLightHoverMixPct = 25;
export const navDarkBaseMixPct = 25;
export const navDarkHoverMixPct = 35;
// Icons
export const navBaseIconSize = 24;
export const navSmallerIconSize = 20;
// Text
export const navTinyFontSize = '0.75rem'; // ~12px
export const navBaseFontSize = '0.875rem'; // ~14px
export const navLargeFontSize = '1rem'; // ~16px
// Gap/Padding/Margin/etc.
export const navSpaceFactor = 0.5;
export const navBorderRadiusPx = '6px';
//Widths
export const navGridSideBarCollapsed = 60;
export const navGridSideBarExpanded = 230;

// Colors
export function getNavTextColor(theme: Theme): string {
  const isDarkMode = theme.palette.mode === 'dark';
  return !isDarkMode ? bloxColors.middarkgray : bloxColors.midlightgray;
}

export function getMixPercent(isDarkMode: boolean, isHover: boolean): number {
  if (isDarkMode) {
    return isHover ? navDarkHoverMixPct : navDarkBaseMixPct;
  } else {
    return isHover ? navLightHoverMixPct : navLightBaseMixPct;
  }
}

export function getPrimaryBgColor(theme: Theme, isHover: boolean = false): string {
  const isDarkMode = theme.palette.mode === 'dark';
  const mixPct = getMixPercent(isDarkMode, isHover);
  return getColorMix(
    theme.palette.primary.light,
    mixPct,
    theme.palette.background.default,
    'srgb',
  );
}

export function getSuccessBgColor(theme: Theme, isHover: boolean = false): string {
  const isDarkMode = theme.palette.mode === 'dark';
  const mixPct = getMixPercent(isDarkMode, isHover);
  return getColorMix(
    theme.palette.success.light,
    mixPct,
    theme.palette.background.default,
    'srgb',
  );
}

export function getErrorBgColor(theme: Theme, isHover: boolean = false): string {
  const isDarkMode = theme.palette.mode === 'dark';
  const mixPct = getMixPercent(isDarkMode, isHover);
  return getColorMix(
    theme.palette.error.light,
    mixPct,
    theme.palette.background.default,
    'srgb',
  );
}

export function getWarningBgColor(theme: Theme, isHover: boolean = false): string {
  const isDarkMode = theme.palette.mode === 'dark';
  const mixPct = getMixPercent(isDarkMode, isHover);
  return getColorMix(
    theme.palette.warning.light,
    mixPct,
    theme.palette.background.default,
    'srgb',
  );
}

export function getInfoBgColor(theme: Theme, isHover: boolean = false): string {
  const isDarkMode = theme.palette.mode === 'dark';
  const mixPct = getMixPercent(isDarkMode, isHover);
  return getColorMix(
    theme.palette.info.light,
    mixPct,
    theme.palette.background.default,
    'srgb',
  );
}

export function getBoxShadows(theme: CreateTheme): string {
  return theme?.shadows[3];
}

export function getBorderString(theme: Theme): string {
  return `1px solid ${theme.palette.divider}`;
}

// common inputs popout/paper shared styles
export const getCommonPopoutSx = (theme: Theme): SystemStyleObject => ({
  borderRadius: 1,
  border: getBorderString(theme),
  boxShadow: getBoxShadows(theme),
});

export function getSolidThemeGray(theme: Theme): string {
  return theme.palette.mode === 'dark'
    ? theme.palette.grey[900]
    : theme.palette.grey[100];
}

// Preset styles
export function getInputInfoTextSx(theme: Theme): SystemStyleObject {
  return {
    fontSize: bloxValues.tinyFontSizeRem,
    color: theme.palette.text.secondary,
  };
}

export function getInputErrorTextSx(theme: Theme): SystemStyleObject {
  return {
    fontSize: bloxValues.tinyFontSizeRem,
    color: theme.palette.error.main,
  };
}

interface MockOutlinedInputReturn extends CSSProperties {
  borderColor: CSSProperties['borderColor'];
  borderWidth: CSSProperties['borderWidth'];
  color: CSSProperties['color'];
  transition: CSSProperties['transition'];
}

export function getMockOutlinedInputSx(
  theme: Theme,
  isErrored: boolean = false,
): MockOutlinedInputReturn {
  // when you need to mock the default MUI Outlined styles
  // couldn't find a way on MUIs docs to get these out of
  // the theme object, so these are via the color picker.
  const isDarkMode = theme.palette.mode === 'dark';
  const borderWidth = isErrored ? '2px' : '1px';
  const txtColor = isErrored ? theme.palette.error.main : theme.palette.text.primary;
  const transitions = theme.transitions.create(['border-color', 'border-width'], {
    duration: theme.transitions.duration.short,
  });
  const borderColor = isErrored
    ? theme.palette.error.main
    : isDarkMode
      ? '#474747'
      : '#c2c2c2';

  return {
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderStyle: 'solid',
    color: txtColor,
    transition: transitions,
  };
}

export const gridActionsColWidth = 30;