import { httpClient } from 'providers/clients/httpClient';
import { BlockSearchData } from 'utility/types/dataProvider';
import { AssetOverride } from 'utility/types/dataProvider';

export const blockLibraryProvider = {
  // Search blocks for a URL map
  getBlockSearch: async (
    map_id: string,
    query: string = '',
    limit: number = 10,
    page: number = 0,
  ): Promise<BlockSearchData> => {
    try {
      const queryString = new URLSearchParams({
        query,
        limit: limit.toString(),
        page: page.toString(),
      }).toString();

      const path = `/core/block/${map_id}/?${queryString}`;

      const { data } = await httpClient({
        url: path,
      });

      return data?.data as BlockSearchData;
    } catch (error) {
      console.error('[getBlockSearch] Error', error);
      throw error;
    }
  },

  // Get block management configuration details
  getBlockConfig: async () => {
    try {
      const url = `/core/block/config/`;

      const { data } = await httpClient({
        url: url,
      });

      return data?.data;
    } catch (error) {
      console.error('[getBlockConfig] Error', error);
      throw error;
    }
  },

  // Create a new block
  createBlock: async (map_id: string, blockData: any) => {
    try {
      const url = `/core/block/${map_id}/`;
      const { data } = await httpClient({
        url: url,
        method: 'POST',
        data: JSON.stringify(blockData),
      });

      return data?.data;
    } catch (error) {
      console.error('[createBlock] Error', error);
      throw error;
    }
  },

  // Update a block asset override
  updateAssetOverride: async (
    map_id: string,
    block_id: string,
    asset_id: string,
    overrideData: AssetOverride,
  ) => {
    try {
      const url = `/core/block/${map_id}/${block_id}/override/${asset_id}/`;
      const { data } = await httpClient({
        url: url,

        method: 'PUT',
        data: JSON.stringify(overrideData),
      });

      return data;
    } catch (error) {
      console.error('[updateAssetOverride] Error', error);
      throw error;
    }
  },

  // Perform asset search for an anonymous content block
  getBlockAssetSearch: async (
    map_id: string,
    limit: number = 50, // Max allowed in the block
    page: number = 0, // Not any paging after 50 for a block
    blockData: any,
  ) => {
    try {
      const url = `/core/block/${map_id}/assets/?limit=${limit}&page=${page}`;
      const { data } = await httpClient({
        url: url,
        method: 'POST',
        data: JSON.stringify(blockData),
      });

      return data?.data;
    } catch (error) {
      console.error('[getBlockAssetSearch] Error', error);
      throw error;
    }
  },
};
