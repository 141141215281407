import { httpClient } from 'providers/clients/httpClient';
import { GetWorkflowByIdResult, GetWorkflowsResult } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ WORKFLOWS PROVIDER ----------------
// -----------------------------------------------
export const workflowsProvider = {
  // Get all site workflows
  getWorkflows: async (): Promise<GetWorkflowsResult> => {
    try {
      const path = `/core/workflow/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetWorkflowsResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getWorkflows] Error:', error);
      throw error;
    }
  },

  // Retrieve a workflow
  getWorkflowById: async (workflowId: string): Promise<GetWorkflowByIdResult> => {
    try {
      const path = `/core/workflow/${workflowId}/`;

      const { data, status } = await httpClient({
        url: path,
        method: 'GET',
      });

      const result: GetWorkflowByIdResult = {
        status: data?.status || status,
        data: data?.data,
      };

      return result;
    } catch (error) {
      console.error('[getWorkflowById] Error:', error);
      throw error;
    }
  },
};
