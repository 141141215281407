import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import { bloxColors } from '../themes/constants';

export const BloxNXTIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="BloxNXTIcon"
        data-testid="BloxNXTIcon"
        data-name="BloxNXTIcon"
        className="bloxicon"
        preserveAspectRatio="xMidYMid meet"
        viewBox="79 80 190 210"
      >
        <path
          d="m231.48 262.36-46.47 26.65v-53.48l46.47 26.83zM246.87 181.87l-61.86 35.72V195.1l81.08-46.5v93.91l-19.22 11.02v-71.66zM208.43 102.04l46.31 26.91-46.31 26.74v-53.65zM131.03 128.95l61.86 35.71-19.48 11.25-80.81-46.97 81.33-46.95 19.16 11.13-62.06 35.83zM128.25 174.94l-46.46-26.65v53.48l46.46-26.83zM143.65 255.43l-61.86-35.72v22.49l81.07 46.5v-93.91l-19.21-11.02v71.66z"
          fill={bloxColors.logoblue}
        />
      </svg>
    </SvgIcon>
  );
};
