import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import {
  AUTH_MAX_RF_SEC,
  getActiveSiteViaZustand,
  getApiUrlViaZustand,
  getAuthTokenViaStorage,
  getUserManagerViaZustand,
  isJwtExpired,
  randomRefreshTime,
  reauthDialogError,
  reauthDialogLogout,
  useAuthStoreZustand,
} from 'providers/contexts/CosmosAuthEnvContext';
import type { StringifyOptions } from 'query-string';
import { fetchUtils } from 'react-admin';
import { ApiErrorResponse, GenericApiResponse } from 'utility/types/dataProvider';
import { v4 as uuidv4 } from 'uuid';

export const stringifyOptions: StringifyOptions = { arrayFormat: 'bracket' };

// Setup a lock name for the Web Locks API to try and prevent multiple
// silent sign-ins from happening at the same time across tabs.
const AUTH_REFRESH_LOCKNAME = 'AUTH_REFRESH_LOCKNAME';

// A mutable promise web lock to track when a token refresh in progress, which
// by default is null. When a token refresh is in progress, set this to the
// promise lock, which can be awaited. Then if multiple calls to renewAuth are made
// simultaneously, the first one will perform the refresh and the others will await
// the result of that promise. This is to prevent multiple calls to renew
// if multiple requests are made with an expired token at the same time.
// While a lock is held requests for the same lock from this execution context
// or from other tabs/workers, will be queued. The first queued request
// will be granted only when the lock is released.
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
let refreshAuthPromiseLock: Promise<string> | null = null;

/**
 * Helper function for interceptor to renew expired access token.
 * Ensures only one silent sign-in process occurs at a time.
 * Should timeout and release the lock if takes longer than 15 seconds.
 *
 * @returns The new bearer token if successful.
 * @throws Error if the renew fails or the stored token is still expired after renewal.
 */
export async function tryRenewAuth() {
  // If a token refresh is already in progress return the ongoing promise to await.
  if (refreshAuthPromiseLock) {
    return refreshAuthPromiseLock;
  }

  // Maximum execution time for the entire refresh process.
  const TOTAL_TIMEOUT_MS = 15000; // 15 sec seems safe
  let lockAbortController = new AbortController();

  // Use promise race to enforce a total execution timeout.
  // Update the global promise lock to the new promise race.
  try {
    refreshAuthPromiseLock = Promise.race<string>([
      new Promise<string>(async (resolve, reject) => {
        try {
          await navigator.locks.request(
            AUTH_REFRESH_LOCKNAME,
            { mode: 'exclusive', signal: lockAbortController.signal },
            async () => {
              try {
                // First check if the token duration is valid for greater than the max
                // rand refresh threshold upon acquiring the lock in case another tab has
                // refreshed the auth token for us while we were waiting in the queue.
                const existingToken = await getAuthTokenViaStorage();
                const existingExpired = isJwtExpired(existingToken, AUTH_MAX_RF_SEC);
                if (existingToken && !existingExpired) {
                  return resolve(existingToken); // Refreshed already
                }

                // Ensure sign-in silent method is available.
                const userManager = getUserManagerViaZustand();
                if (!userManager?.signinSilent) {
                  throw new Error('[tryRenewAuth] Sign in method is missing.');
                }

                // Silent renew which should return a user and update the storage.
                const renewedUser = await userManager.signinSilent();
                if (!renewedUser) {
                  throw new Error('[tryRenewAuth] Sign in did not return a user.');
                }

                // Pull the updated token from storage.
                const refreshedToken = await getAuthTokenViaStorage();
                if (!refreshedToken) {
                  throw new Error('[tryRenewAuth] No token found in storage.');
                }

                // Confirm the new token just pulled is not near expiry.
                const newTokenExpired = isJwtExpired(refreshedToken, AUTH_MAX_RF_SEC);
                if (newTokenExpired) {
                  throw new Error('[tryRenewAuth] New token was expired.');
                }

                // If all checks pass, resolve with the new token.
                resolve(refreshedToken);
              } catch (error) {
                // If any error occurs, reject with error.
                reject(error);
              }
            },
          );
        } catch (error) {
          console.error('[tryRenewAuth] Error:', error);
          if (lockAbortController.signal.aborted) {
            reject(new Error('Web Lock Aborted.'));
          } else {
            reject(error);
          }
        }
      }),

      // Start a race to abort the entire refresh process if it takes too long.
      // If the entire process takes too long then we reject.
      // This is a safety against deadlock in case something stalls out e.g. await signinSilent();
      new Promise((resolve, reject) =>
        setTimeout(() => {
          lockAbortController.abort('Refresh exceeded maximum time.');
          reject(new Error('Refresh exceeded maximum time.'));
        }, TOTAL_TIMEOUT_MS),
      ),
    ]);

    return refreshAuthPromiseLock;
  } catch (error) {
    console.error('[tryRenewAuth] Caught:', error);
    throw error;
  } finally {
    // Always reset the promise to null when done.
    refreshAuthPromiseLock = null;
    lockAbortController = new AbortController();
  }
}

/**
 * The httpClient uses axios instance provided to invoke the API requests.
 * Main axios instance will attempt to get the active site, api url, and auth token
 * from zustand/storage and set the necessary headers for the request.
 *
 * You should be able to use this anywhere below the CosmosRAProvidersContext
 * as the values should all be set by that point in the app.
 *
 * Above that point you can pass the rawAxiosInstance to the httpClient function
 * and set the neccesary headers manually via the config object.
 *
 * Always try to get the fresh tokens via auth in storage or zustand store
 * within interceptor, dont rely on passing down params that depend on context.
 * Under certain conditions this caused stale closures in some useQuery functions back when
 * we passed in the auth object, it used expired initial tokens, as the props were closed over
 * and not updated even when the context states were updated, even though logging the component
 * states seemed to indicate that the vars were updated and fresh vars were passed in.
 *
 * There is more documentation on this in the CMSADMIN-1097 / CMSADMIN-1047 tickets, and
 * See CosmosRAProvidersContext for more details on passing vars to dataProvider funcs.
 * See CosmosAuthEnvContext for related code on auth and renewal process and effects.
 *
 * https://stackoverflow.com/questions/75948978/react-query-fetches-stale-queries-with-expired-access-token
 * https://github.com/TanStack/query/issues/6734
 * https://github.com/TanStack/query/discussions/931
 */

// The primary axios configuration object
// https://axios-http.com/docs/req_config
export const mainAxiosConfig: AxiosRequestConfig = {
  validateStatus: (status) => {
    const isValid = status >= 200 && status < 300;
    return isValid;
  },
  paramsSerializer: (paramsObj) => {
    const serialized = fetchUtils.queryParameters(paramsObj, stringifyOptions);
    return serialized;
  },
  headers: new AxiosHeaders({
    Accept: 'application/json',
  }),
};

// The axios main instance with interceptors middleware for auth, headers, etc.
// https://axios-http.com/docs/instance
export const mainAxiosInstance: AxiosInstance = axios.create(mainAxiosConfig);

// Axios interceptors to dynamically set headers and handle common errors
// You can intercept requests or responses before they are handled by then or catch.
// https://axios-http.com/docs/interceptors
export const mainAxiosInterceptor = mainAxiosInstance.interceptors.request.use(
  async (config) => {
    // Ensure base URL is available
    const baseURL = getApiUrlViaZustand();
    if (!baseURL) {
      throw new Error('[interceptor] No API URL.');
    }

    // Ensure active site is available
    const activeSite = getActiveSiteViaZustand();
    if (!activeSite) {
      throw new Error('[interceptor] No active site.');
    }

    // If no token exists we must be logged out and need to fully reauth
    let bearerToken = await getAuthTokenViaStorage();
    if (!bearerToken) {
      const reauthDialog = useAuthStoreZustand?.getState()?.reauthDialog;
      const setDialogState = useAuthStoreZustand?.getState()?.setReauthDialog;
      if (!reauthDialog) {
        setDialogState(reauthDialogLogout);
      }
      throw new Error('[interceptor] Requires authentication.');
    }

    // Check if the token is expired or near expiry and should be refreshed
    const shouldRefreshToken = isJwtExpired(bearerToken, randomRefreshTime);
    if (shouldRefreshToken) {
      try {
        // Await the token refresh process using the global lock promise.
        bearerToken = await tryRenewAuth();
      } catch (error) {
        console.error('[interceptor] Refresh Error:', error);
        // If we catch an error here open the reauth dialog as the renew failed or timed out
        // so at this point for safety we need to sign out as to update the useAuth hook states
        const userManager = getUserManagerViaZustand();
        // Remove the user via the userManager to update useAuth hook and also
        // this should remove the storage and emit a StorageEvent to other tabs
        if (userManager?.removeUser) {
          await userManager.removeUser();
        } else {
          console.error('[interceptor] Remove User method missing.');
        }

        // Open a reauth dialog if not already open
        const reauthDialog = useAuthStoreZustand?.getState()?.reauthDialog;
        const setDialogState = useAuthStoreZustand?.getState()?.setReauthDialog;
        if (!reauthDialog) {
          setDialogState(reauthDialogError);
        }

        throw error;
      }
    }

    // If all above checks pass, we can proceed with the API request...

    // Generate a unique request ID for each request
    const xReqId = uuidv4();
    // Set the base URL for the request
    config.baseURL = baseURL;
    // Set headers for the request
    config.headers.set('Authorization', bearerToken);
    config.headers.set('X-Blox-Domain', activeSite);
    config.headers.set('X-Request-Id', xReqId);

    // Mimic old httpclient react-admin fetchJson Content-Type headers setting
    // https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/dataProvider/fetch.ts
    const hasBody = Boolean(config?.data);
    const hasContentType = config?.headers?.has('Content-Type');
    const isGetMethod = !config?.method || config?.method?.toUpperCase() === 'GET';
    const isFormData = config?.data instanceof FormData;
    const shouldSetContentType =
      hasBody && !hasContentType && !isGetMethod && !isFormData;

    if (shouldSetContentType) {
      config.headers.set('Content-Type', 'application/json');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 *  Utility to assert API response data.status is success
 *  Throws to createErrorResponse if the status is not success
 **/
export async function assertResponseSuccess<T = any>(data: GenericApiResponse<T>) {
  if (data?.status && data?.status !== 'success') {
    throw createErrorResponse(data);
  }
}

/**
 * Create a structured error response object from the API response data.
 *
 * @param data The data response from the API
 * @param axiosError The axios error object
 * @returns A structured error response object
 */
export const createErrorResponse = (
  data: any,
  axiosError?: AxiosError,
): ApiErrorResponse => {
  const customError: ApiErrorResponse = {
    status: data?.status,
    message: data?.message,
    details: data?.details,
    ...data, // Any additional info api included in the response.
    // Adding axios error data in client-side for more info
    axiosStatus: axiosError?.status,
    axiosCode: axiosError?.code,
    axiosMessage: axiosError?.message,
    axiosMethod: axiosError?.config?.method,
    axiosUrl: axiosError?.config?.url,
    axiosBaseUrl: axiosError?.config?.baseURL,
  };
  return customError;
};

/**
 * The httpClient function is a wrapper around axios that handles API requests.
 * It will automatically handle the response data and status, and throw errors.
 * By default will use the mainAxiosInstance and mainAxiosConfig which does
 * the auth token, site, and request ID headers setup for you. You can override
 * by passing the rawaxiosInstance and rawaxiosConfig as needed.
 *
 * @param config The axios request configuration object
 * @param axiosInstance  Override the axios instance if needed
 * @returns The full AxiosResponse on success
 * @throws The ApiErrorResponse response object on failure or a generic error as-is on other errors
 */
export async function httpClient<T = any>(
  config: AxiosRequestConfig = mainAxiosConfig, // The axios request configuration object
  axiosInstance: AxiosInstance = mainAxiosInstance, // Override the axios instance if needed
) {
  try {
    if (!config?.url) {
      throw new Error('[httpClient] No URL provided.');
    }
    // Perform the API request with an interceptor
    const response = await axiosInstance.request<T>(config);

    // Return the full AxiosResponse on success
    return response;
  } catch (error: any) {
    console.error('[httpClient] Error:', error);

    // Axios will throw based on validateStatus range
    // So if the error is an Axios error and maybe had response data then
    // a network fetch went through but the http code was considered an error...
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      const responseData = error?.response?.data as ApiErrorResponse;
      const errorResponse = createErrorResponse(responseData, axiosError);
      return Promise.reject(errorResponse);
    }

    // For any other errors throw as received
    return Promise.reject(error);
  }
}

/**
 * This is a more manual version of the axios interceptor/instance
 * that you can pass to httpclient when you want to use less middlewares.
 *
 * Careful when using this to not create stale closures with headers.
 * Ensure if using in useQuery that props to the queryFn/mutation is passed
 * in and part of the queryKey, or fetched in the body they are when invoked.
 */
export const rawAxiosConfig: AxiosRequestConfig = {
  validateStatus: (status) => {
    const isValid = status >= 200 && status < 300; // default
    return isValid;
  },
  paramsSerializer: (paramsObj) => {
    const serialized = fetchUtils.queryParameters(paramsObj, stringifyOptions);
    return serialized;
  },
  headers: new AxiosHeaders({
    Accept: 'application/json',
  }),
};

export const rawAxiosInstance: AxiosInstance = axios.create(rawAxiosConfig);

export const rawAxiosInterceptor = rawAxiosInstance.interceptors.request.use(
  async (config) => {
    // Mimic old httpclient react-admin fetchJson Content-Type headers setting
    // https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/dataProvider/fetch.ts
    const hasBody = Boolean(config?.data);
    const hasContentType = config?.headers?.has('Content-Type');
    const isGetMethod = !config?.method || config?.method?.toUpperCase() === 'GET';
    const isFormData = config?.data instanceof FormData;
    const shouldSetContentType =
      hasBody && !hasContentType && !isGetMethod && !isFormData;

    if (shouldSetContentType) {
      config.headers.set('Content-Type', 'application/json');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
