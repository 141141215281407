import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { isEqual } from 'lodash';
import { parse } from 'query-string';
import { SetValueConfig } from 'react-hook-form/dist/types/form';
import { isArray, isNumber, isPlainObject, isString, keys, omitBy } from 'remeda';
import { stripHtml } from 'string-strip-html';
import { AnyRecord, AssetType } from './types/cosmosTypes';
import { CountryRegionData } from 'react-country-region-selector';
import {
  EditorialAssetSearchResultItem,
  EditorialSearchQueryParams,
  RelatedAsset,
  UserAccountSummary,
} from './types/dataProvider';
import DOMPurify from 'dompurify';
import { ISO_8601 } from './constants';

//Icons for Wire Drivers
import { BloxNXTIcon } from 'assets/BloxNXTIcon';
import CNNIcon from 'assets/brands/CNN';
import WordPressIcon from 'assets/brands/WordPress';
import StringrIcon from 'assets/brands/Stringr';
import APNewsIcon from 'assets/brands/APNews';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RssFeedIcon from '@mui/icons-material/RssFeed';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(advancedFormat);

/**
 * DOMPurify.sanitize function exported.
 * Fixes the issue with the DOMPurify export defs.
 */
export const DOMSanitize = DOMPurify.sanitize;

type StorageType = 'localStorage' | 'sessionStorage';
/**
 * Remove all items with a specified prefix from session and local storage.
 * @param {string} prefix - The prefix to match keys against. e.g. 'oidc.'
 * @param {StorageType} stores - The stores to clear. Defaults to ['localStorage', 'sessionStorage'].
 */
export const clearPrefixedStorage = (
  prefix: string,
  stores: StorageType[] = ['localStorage', 'sessionStorage'],
) => {
  if (!prefix || !isString(prefix)) {
    console.warn(`[clearPrefixedStorage] Prefix is not a string: ${prefix}`);
    return;
  }

  if (!stores || !isArray(stores)) {
    console.warn(`[clearPrefixedStorage] Stores is not an array: ${stores}`);
    return;
  }

  // Clear from localStorage
  if (stores.includes('localStorage')) {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => {
        localStorage.removeItem(key);
      });
  }

  // Clear from sessionStorage
  if (stores.includes('sessionStorage')) {
    Object.keys(sessionStorage)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => {
        sessionStorage.removeItem(key);
      });
  }
};

/**
 * Capitalizes the first letter of a given string and leaves the rest of the string unchanged.
 * @param {string} str - The input string to capitalize.
 * @returns {string} The modified string with the first letter capitalized.
 */

export function capitalizeFirstLetter(str?: string): string {
  if (!str) return '';
  const stringToTransform = String(str);
  return stringToTransform.charAt(0).toUpperCase() + stringToTransform.slice(1);
}

type BaseFormatDateParams = {
  date?: string | number | Date;
  shiftTz?: string;
  tzKeepLocalTime?: boolean;
  utcMode?: boolean;
};

type DayJSFormatMode = {
  format: 'ISO8601' | string; // Dayjs format strings or 'ISO8601' for .toISOString()
  locales?: never; // Disallow in Dayjs mode
  relative?: never; // Disallow in Dayjs mode
};

type IntlFormatMode = {
  format?: Intl.DateTimeFormatOptions; // Intl format object mode
  locales?: string | string[]; // Allowed in Intl mode
  relative?: RelativeOptions; // Allowed in Intl mode (see code for details)
};

type RelativeOptions = DayJSRelativeMode | CommentsRelativeMode;

type DayJSRelativeMode = {
  mode: 'dayjs';
  withoutSuffix?: boolean; // Only for dayjs mode
};

type CommentsRelativeMode = {
  mode: 'comments';
  withoutSuffix?: never; // Disallow in comments mode
};

type FormatDateParams = BaseFormatDateParams & (DayJSFormatMode | IntlFormatMode);

const defaultFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

/**
 * Check if a date is able to be converted to a valid date object
 * by dayjs. Returns true if the date is valid, false otherwise.
 *
 * Return false for falsy inputs like empty strings, null, undefined.
 */
export function isValidDate(date?: string | number | Date): boolean {
  if (!date) {
    return false; // ensure dayjs doesn't default to current date
  }
  const isValid = dayjs(date).isValid();
  return isValid;
}

/**
 * Formats a date based on the provided options.
 *
 * @param {FormatDateParams['date']} date
 * The date to be formatted.
 * Can pass in a number or Date object as well. Will be parsed by dayjs.
 * Generally expected to be ISO-8601 UTC string "2024-10-21T13:48:35+00:00"
 *
 * @param {FormatDateParams['shiftTz']} shiftTz
 * Specify tz to shift the date into the specified TZ identifier e.g. "America/Chicago".
 * e.g. If the API provides a timezone (generally in UTC), specify via sessionConfig
 * sites timezone "America/Los_Angeles" to shift the date to the correct timezone.
 * This option will be set in the Intl.DateTimeFormat timeZone if not provided in format.
 *
 *  @param {FormatDateParams['format']} format
 * Optional custom format to use for formatting.
 * If you pass an Intl.DateTimeFormatOptions it will use the Intl.DateTimeFormat constructor.
 * When using the object formatter you may also pass in locales and relative options.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * If you pass a string it will use the dayjs format method.
 * If you explicitly want ISO8601 format, pass 'ISO8601' as the format.
 * This will not use intl and is not compatible with locales or relative options.
 * https://day.js.org/docs/en/display/format
 *
 * @param {FormatDateParams['tzKeepLocalTime']} tzKeepLocalTime
 * If shifting timezone, keep the local time when shifting the date?
 * This is useful for inputs format/parse. Default false.
 * Details: https://github.com/iamkun/dayjs/issues/1149
 *
 * @param {FormatDateParams['utcMode']} utcMode
 * Default True. Setup the dayjs parse in UTC mode.
 * This is generally the desired as the API should send in UTC.
 * https://day.js.org/docs/en/plugin/utc
 *
 * @param {FormatDateParams['locales']} locales
 * Optional locales argument for the Intl.DateTimeFormat constructor.
 * Default undefined will use the browsers default locale.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {FormatDateParams['relative']} relative
 * If comments relative formatting should be applied:
 * Will override format and produce a string "7:00 PM Today" if within +/- 1 day.
 * If not within range, will use the passed in format options.
 *
 * If 'dayjs' string passed, will use the dayjs relativeTime plugin to format the output.
 * Formats the output directly using the dayjs relativeTime plugin e.g. " 5 minutes ago"
 * Additional options can be passed in the relative object, eg. withoutSuffix: true
 * https://day.js.org/docs/en/plugin/relative-time
 *
 * @returns {string}
 * The formatted date string if valid.
 * Returns empty string if missing info or invalid.
 *
 * @description
 * Notes: the API generally sends ISO-8601 UTC normalized (sometimes without the Z, eg +00:00)
 * We generally assume that the frontend should shift that time to the correct TZ for display.
 * The sites timezone can be pulled from useCosmos / sessionConfig?.time_zone / "America/Los_Angeles"
 * https://stackoverflow.com/questions/33114386/datetime-iso-8601-without-timezone-component
 * https://townnews.atlassian.net/browse/CMSADMIN-1126?focusedCommentId=321419
 *
 * Examples:
 * Recieve API: 2021-03-17T22:00:00+00:00
 * Shifting TZ: 2021-03-17T15:00:00-07:00 (eg to America/Los_Angeles)
 * Sending back API generally is in ISO UTC normalized eg.:
 * dayjs(selectedDate).tz(siteTimeZone).startOf('day').toISOString();
 * Local String: 2022-05-17T15:00:00-05:00 -> to ISO (UTC): 2022-05-17T20:00:00.000Z
 *
 * The same applies for most DateTime Inputs, you can see an examples in Dates.tsx
 * The catch with these is that they are in datetime-local format without a tz.
 * On the format prop (record -> input)
 * (val) => formatDate({ date: val, format: 'datetime-local', shiftTz: siteTz });
 * On the parse prop (input -> record)
 * (val) => formatDate({ date: val, format: ISO_8601, tzKeepLocalTime: true, utcMode: false });
 * Since datetime-local has no tz you keep local time and turn off utc mdoe for this one,
 * else when the user moves the date around it will jump around by the offset...
 * With these two, the inputs date should be shifted to site tz for display
 * and then when setting back into the record, it should be shifted back to ISO UTC.
 *
 */

export function formatDate({
  date,
  shiftTz,
  utcMode = true,
  format = defaultFormat,
  tzKeepLocalTime = false,
  locales = undefined,
  relative = undefined,
}: FormatDateParams): string {
  if (!date) {
    return '';
  }

  const dateIsValid = dayjs(date).isValid();
  if (!dateIsValid) {
    console.error('[formatDate] Dayjs invalid date:', date);
    return '';
  }

  // By default dayjs parses and displays in local (browser) tz
  // setup the date in UTC mode if utcMode is true
  let dayJsDate = utcMode ? dayjs.utc(date) : dayjs(date);

  // Shift dayjs into specified timezone if provided
  if (shiftTz) {
    dayJsDate = dayJsDate.tz(shiftTz, tzKeepLocalTime);
  }

  // If format is a string use dayjs formatting mode
  const useDayjsFormat = isString(format);
  if (useDayjsFormat) {
    switch (format) {
      case ISO_8601: {
        return dayJsDate.toISOString(); // handle ISO8601 request
      }
      default: {
        return dayJsDate.format(format); // custom dayjs format
      }
    }
  }

  // Setup JS Date for intl formatters
  const jsDate = dayJsDate.toDate();

  // Setup intlFormatter based on the provided options
  // Note: timeZone should generally be the same as shiftTz
  // as the jsDate would be in the browsers timezone with an offset
  const intlFormatter = new Intl.DateTimeFormat(locales, {
    ...format,
    timeZone: format?.timeZone || shiftTz,
  });

  // Handle relative "basic" or "custom" modes formatting
  switch (relative?.mode) {
    // Handle basic dayjs relative formatting (5 minutes ago)
    // Skips the formatter and uses the dayjs relativeTime plugin
    case 'dayjs': {
      const basicRelDate = dayJsDate.fromNow(relative?.withoutSuffix);
      return basicRelDate;
    }

    // Handle comments mode relative formatting +/- 1d (8:20 AM Today)
    case 'comments': {
      const today = dayjs().tz(shiftTz).startOf('day');
      const yesterday = today.subtract(1, 'day');
      const tomorrow = today.add(1, 'day');

      // Override format options to only show the time part
      // also keep subset of other options from the original format
      const customFmtOpts: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: format?.hour12,
        second: format?.second,
        timeZone: format?.timeZone || shiftTz, // See comment intlFormatter
        timeZoneName: format?.timeZoneName,
        fractionalSecondDigits: format?.fractionalSecondDigits,
        calendar: format?.calendar,
        formatMatcher: format?.formatMatcher,
        localeMatcher: format?.localeMatcher,
        numberingSystem: format?.numberingSystem,
      };

      const timeFormatter = new Intl.DateTimeFormat(locales, customFmtOpts);

      if (dayJsDate.isSame(yesterday, 'day')) {
        const timeString = timeFormatter.format(jsDate);
        return `${timeString} Yesterday`;
      }

      if (dayJsDate.isSame(today, 'day')) {
        const timeString = timeFormatter.format(jsDate);
        return `${timeString} Today`;
      }

      if (dayJsDate.isSame(tomorrow, 'day')) {
        const timeString = timeFormatter.format(jsDate);
        return `${timeString} Tomorrow`;
      }

      // If no custom relative case matches fall through to standard format.
      break;
    }
  }

  // Final default to the standard intl formatter
  const finalDateString = intlFormatter.format(jsDate);
  return finalDateString;
}

/**
 * Compare two UTC dates with dayjs in UTC mode.
 *
 * - True if the first date is newer than the second.
 * - False if the second date is newer than the first.
 * - If one date is invalid or falsy, the valid date "wins":
 *   - If only the first is valid, returns true.
 *   - If only the second is valid, returns false.
 *
 * Edge cases:
 *  - If dates are identical returns false.
 *  - If both dates are invalid, throws an error.
 *  - In strict mode, throws an error if either date is invalid.
 *
 * @param first - The first date input (string, number, or Date)
 * @param second - The second date input (string, number, or Date)
 * @param ifSame - A value to return if the dates are identical.
 * @param strict - Strict mode to throw err if either date is invalid.
 * @returns boolean indicating whether the first date is newer than the second or
 * the specified value (defaults to false) if the dates are identical.
 * @throws Error if both dates are invalid or in strict mode if either date is invalid.
 */

interface CompareDatesParams {
  first?: string | number | Date;
  second?: string | number | Date;
  ifSame?: boolean | string;
  strict?: boolean;
}

export function compareDates({
  first,
  second,
  ifSame = false,
  strict = false,
}: CompareDatesParams) {
  // Use dayjs to parse the provided date inputs
  const date1 = first ? dayjs.utc(first) : dayjs.utc('invalid');
  const date2 = second ? dayjs.utc(second) : dayjs.utc('invalid');

  // Determine validity of each parsed date
  const isDate1Valid = date1.isValid();
  const isDate2Valid = date2.isValid();

  const eitherDateInvalid = !isDate1Valid || !isDate2Valid;
  if (strict && eitherDateInvalid) {
    throw new Error('[compareDates] One or more dates are invalid.');
  }

  // If both dates are invalid, throw an error...
  if (!isDate1Valid && !isDate2Valid) {
    throw new Error('[compareDates] Both dates are invalid.');
  }

  // In our non-strict use case where dates may be missing
  // If one date is invalid and the other is valid
  // then the valid date is the winner...

  // First date wins
  if (isDate1Valid && !isDate2Valid) {
    return true;
  }

  // Second date wins
  if (!isDate1Valid && isDate2Valid) {
    return false;
  }

  // If dates are identical, return specified value
  const datesAreIdentical = date1.isSame(date2);
  if (datesAreIdentical) {
    return ifSame;
  }

  // compare the dates
  const compare = date1.isAfter(date2);

  return compare;
}

export interface DebounceOptions {
  /** If true, the function will be called on the leading edge of the timeout. Default is false. */
  leading?: boolean;
}

/**
 * Debounces a function, ensuring that it's only called once after the specified delay.
 * For a better hook version of this see: useDebouncedCallback from mantine
 * @param func - The function to debounce.
 * @param delay - The amount of time in milliseconds to wait before invoking the function.
 * @param options - Optional configuration for the debounce behavior.
 * @returns A debounced version of the specified function.
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
  options: DebounceOptions = {},
): (...funcArgs: Parameters<T>) => void {
  let timerId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>) => {
    if (!timerId && options.leading) {
      func(...args);
    }
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => func(...args), delay);
  };
}

/**
 * Checks if a given asset type has an editor that is enabled.
 *
 * @param {checkType} checkType - String to check. e.g. 'article', 'image', etc.
 * @returns {boolean} True if the asset type is in the list of enabled types; false otherwise.
 *
 * @example
 * // Returns true if 'article' is in the list of enabled asset editor types
 * isAssetTypeEditorEnabled('article');
 */

export const editorEnabledAssetTypes: AssetType[] = [
  'article',
  'image',
  'file',
  'audio',
  'html',
  'table',
  'collection',
  'link',
  'poll',
  'video',
];

export const isAssetTypeEditorEnabled = (checkType: any): boolean => {
  if (typeof checkType !== 'string') {
    return false;
  }
  const checkTypeLowercased = checkType.toLowerCase();
  return editorEnabledAssetTypes.some((type) => type === checkTypeLowercased);
};

/**
 * Removes query parameters from a URL string.
 * @param urlString - The URL string to remove query parameters from.
 * @returns The URL string without any query parameters.
 * Console logs an error if the input is not a string or if the URL cannot be parsed and returns the original string.
 */
export const removeQueryParamsFromUrlString = (urlString: string | undefined) => {
  if (typeof urlString !== 'string') {
    console.warn(`Expected string but got ${urlString} typeof (${typeof urlString})`);
    return urlString; // Guard against non string values
  }
  try {
    if (
      urlString.startsWith('blob:') ||
      urlString.startsWith('<iframe') ||
      urlString.startsWith('<video')
    ) {
      return urlString; // Blobs, iframes, and video HTML tags shouldnt have query params...
    }
    let parsedUrl = new URL(urlString); // Parse the URL, this can throw.
    return `${parsedUrl.origin}${parsedUrl.pathname}`; // Construct and return the URL without query parameters
  } catch (error) {
    console.error(
      '[removeQueryParamsFromUrlString] Unsuccessful, returning original string:',
      urlString,
    );
    return urlString; // Return the original string if we can't parse it.
  }
};

/**
 * Checks if a given URL string is a valid URL. Optionally checks if the URL's protocol
 * is among the allowed protocols if a list is provided.
 *
 * @param urlString - The URL string to check.
 * @param protocols - Optional array of protocols to check against, e.g., ['http:', 'https:']
 * @param baseString - Optional base URL to use for resolving the URL when urlString is relative.
 * @returns True if the URL is valid; if protocols are provided, true only if the URL's protocol matches one of the specified protocols.
 * @example isValidUrl('http://example.com', ['http:', 'https:']) { ... }
 */
export const isValidUrl = (
  urlString: string,
  protocols?: string[],
  baseString?: string,
): boolean => {
  try {
    const url = new URL(urlString, baseString);
    if (protocols && protocols.length) {
      return protocols.includes(url.protocol);
    }
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Returns the route from a given path, removing the domain.
 *
 * @param path
 * @returns Route
 */
export const getRoute = (path: string) => {
  const pathSections = path.split('/').filter((section) => section !== '');
  // Remove the first section (domain)
  pathSections.shift();
  // Join the remaining sections back together
  const route = '/' + pathSections.join('/');
  return route;
};

/**
 * Extracts the site/default site part from a given pathname.
 *
 * @param {string} pathname - The pathname to extract the site/default site part from.
 * @returns {string} The extracted site/default site part.
 */
export const getDomainFromPath = (pathname: string): string => {
  // Assuming the site/default site part is the first segment of the pathname
  const segments = pathname.split('/');
  if (segments.length >= 2) {
    return segments[1]; // Adjust the index based on the actual structure of your path
  }
  return '';
};

/**
 * Converts a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The title case string.
 */
export const toTitleCase = (str: string): string => {
  // Skip conjunctions, articles, and prepositions
  const skipWords = new Set([
    'and',
    'as',
    'but',
    'for',
    'if',
    'nor',
    'or',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'at',
    'by',
    'in',
    'of',
    'off',
    'on',
    'per',
    'to',
    'up',
    'via',
  ]);
  // More rules could likely be added (also internationalization is not considered here)

  let result = ''; // Initialize an empty string to store the result

  // Split the string into words
  const words =
    str
      .split(/\b/)
      // Convert snake_case to space-separated words.
      ?.map((word) =>
        word.replace(/^_*(.)|_+(.)/g, (match, str1, str2) =>
          str1 ? str1.toUpperCase() : ' ' + str2.toUpperCase(),
        ),
      ) || [];

  // Convert the first word to uppercase and append it to the result
  if (words.length > 0 && /^\w+$/.test(words[0])) {
    result += words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  } else {
    result += words[0]; // Keep non-word parts unchanged
  }

  // Process the rest of the words
  for (let i = 1; i < words.length; i++) {
    const part = words[i];
    if (/^\w+$/.test(part)) {
      // Convert to uppercase if not in skipWords set
      result += skipWords.has(part.toLowerCase())
        ? part.toLowerCase()
        : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    } else {
      // Keep non-word parts unchanged
      result += part;
    }
  }

  // Ensure the final word is capitalized
  if (words.length > 1 && /^\w+$/.test(words[words.length - 1])) {
    result =
      result.slice(0, -words[words.length - 1].length) +
      words[words.length - 1].charAt(0).toUpperCase() +
      words[words.length - 1].slice(1).toLowerCase();
  }
  return result;
};

/**
 * Updates the document title based on the current route and active Blox domain.
 *
 * @param record - The record to use for the title.
 * @param assetType - The asset type to use for the title.
 */
export const updateDocumentTitle = (record?: any, assetType?: string) => {
  let title = '';
  const stinger = 'BLOX NXT';
  const activeBloxDomain = getDomainFromPath(window.location.pathname);
  const route = getRoute(window.location.pathname);

  if (!assetType) {
    const queryParams = parse(window.location.search);
    const rawAssetType = queryParams.type;
    assetType =
      rawAssetType && typeof rawAssetType === 'string'
        ? toTitleCase(rawAssetType)
        : 'Asset';
  } else {
    assetType = toTitleCase(assetType);
  }

  switch (true) {
    case route === '/' || route === '':
      title = `Dashboard - ${activeBloxDomain}`;
      break;
    case route === '/site-select':
      title = 'Content Management / Search';
      break;
    case route.startsWith('/editorial/create'):
      title = `New ${assetType}`;
      break;
    case route.startsWith('/editorial/edit'):
      if (!record) return;
      const assetTitle = record?.title || '';
      title = assetTitle;
      break;
    case route.startsWith('/editorial/search'):
      title = 'Content Management / Editorial / Search';
      break;
    case route === '/design':
      title = 'Design';
      break;
    case route.startsWith('/design/page-builder'):
      title = 'Design / Page Builder';
      break;
    case route === '/user/dashboard':
      title = 'Contacts / Dashboard';
      break;
    case route.startsWith('/user/edit'):
      if (!record) return;
      const isNormal = record?.account_type === 'normal';
      const firstName = record?.first_name;
      const lastName = record?.last_name;
      const email = record?.email;
      const identifier = firstName && lastName ? `${firstName} ${lastName}` : email;
      title = isNormal ? `User: ${identifier}` : `Admin: ${identifier}`;
      break;
    case route.startsWith('/user/create'):
      title = `Create User`;
      break;
    case route.startsWith('/user/account/search'):
      title = 'Contacts / Search';
      break;
    case route.startsWith('/search'):
      title = 'Saved Searches';
      break;
    case route.startsWith('/user/admin/search'):
      title = 'Admin Accounts / Search';
      break;
    case route.startsWith('/feeds/search'):
      title = 'Content Management / Feeds / Search';
      break;
    default:
      break;
  }

  if (title) {
    document.title = `${title} - ${stinger}`;
  } else {
    document.title = stinger;
  }
};

/**
 * Clean string from html tags and blox-inline tags
 * @param {string} input - The input string to clean.
 * @returns {string} - The cleaned string.
 */
export const cleanStringFromHtmlAndBloxInline = (input: string): string => {
  const regex = /<blox-inline([^>]*)>.*?<\/blox-inline>/g;
  const removedBloxInline = input.replace(regex, '');
  return stripHtml(removedBloxInline).result;
};

/**
 * Replace Href with javascript:void
 * @param {string} htmlString - The input string to clean.
 * @returns {string} - The new html.
 */
export const replaceHrefWithVoid = (htmlString: string) => {
  return htmlString.replace(
    /<a\s+([^>]*?)href="[^"]*"(.*?)>/gi,
    '<a $1 href="javascript:void(0);"$2>',
  );
};

/**
 * Truncate a string to a specified length and add an ellipsis
 * if the string is longer than the specified length.
 *
 * @param input - The input string to truncate.
 * @param length - The maximum length of the truncated string.
 * @returns The truncated string with an ellipsis if necessary.
 */
export const truncateString = (input: string, length: number): string => {
  return input.length > length ? `${input.slice(0, length).trim()}\u2026` : input.trim();
};

/** Find links in an HTML string */
export function findLinks(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const anchorTags = doc.getElementsByTagName('a');
  const links = [];

  for (let i = 0; i < anchorTags.length; i++) {
    const href = anchorTags[i].getAttribute('href');
    if (href) {
      links.push(href);
    }
  }

  return links;
}

/**
 * Format currency string to symbol.
 * @param {number} amountInCents - The amount to format in minor units (like cents).
 * @param {string} currencyCode - Will default to USD if falsy... The currency code to use for formatting. (USD, GBP, EUR, etc.)
 * @param {string} [locale] - Set the locale to use for formatting. Falls back to the Intl resolved options locale.
 * @returns {string} The formatted currency string. e.g., $123,456.79 for USD.
 */
export const formatCurrency = (
  amountInCents: number | string | undefined,
  currencyCode?: string | undefined,
  locale?: string | undefined,
): string | undefined => {
  const amount =
    typeof amountInCents === 'string' ? parseInt(amountInCents) : amountInCents;

  if (!hasValue(amount)) {
    console.warn(`[formatCurrency] - Amount has no value: ${amountInCents}`);
    return undefined; // return empty str or undefined if no value
  }

  if (!isNumber(amount)) {
    console.warn(`[formatCurrency] - Amount is not a number: ${amountInCents}`);
    return undefined;
  }

  // Make sure amountInCents is not out of range and is
  if (amount < Number.MIN_SAFE_INTEGER || amount > Number.MAX_SAFE_INTEGER) {
    console.warn(`[formatCurrency] - Amount is out of range: ${amount}`);
    return undefined; // Return an empty string if the amount is out of range
  }

  const amountInDollars = amount / 100;

  const usingLocale = locale || new Intl.NumberFormat()?.resolvedOptions()?.locale;

  const formatter = new Intl.NumberFormat(usingLocale, {
    style: 'currency',
    currency: currencyCode || 'USD', // Default to USD if no currency code is provided. Bewarned, this may be incorrect for some use cases...
    notation: 'standard',
  });
  return formatter.format(amountInDollars);
};

/** Formats a string to display string helper function
 *  mainly used in select dropdowns. Will capitalize the first letter
 *  of the string and return if no match is found.
 * e.g.  * 'html' -> 'HTML'
 */
export const formatAssetString = (assetType: string): string => {
  if (!assetType) {
    return assetType;
  }
  const type = assetType.toLowerCase();
  switch (type) {
    case '*': {
      return 'All';
    }
    case 'html': {
      return 'HTML';
    }
    case 'youtube': {
      return 'YouTube';
    }
    case 'pdf': {
      return 'PDF';
    }
    default: {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
  }
};

/** AssetType + Wildcard as workaround for MUI Select "All" */
export type SearchTypes = AssetType | '*';

//** String Array of SearchTypes */
export const searchTypes: SearchTypes[] = [
  '*', // All if you pass to backend search query type[]: '*'
  'article',
  'image',
  'collection',
  'audio',
  'file',
  'flash',
  'html',
  'link',
  'pdf',
  'poll',
  'table',
  'video',
  'youtube',
  'zip',
];

export interface SearchDropdownOptionsType {
  value: SearchTypes;
  label: string;
}

export const searchDropdownOptions: SearchDropdownOptionsType[] = [
  ...searchTypes.map((type) => ({
    value: type,
    label: formatAssetString(type),
  })),
];

export const searchInitialQueryState: EditorialSearchQueryParams = {
  query: '',
  type: ['*'], // All asset types, asterisk is workaround for MUI Select.
  deleted: 'false', // Only show non-deleted records.
};

export const relatedAssetOptions: string[] = ['children', 'siblings', 'parents'];

/** setValue react-hook-form commonly used config */
export const shouldValidDirtyTouch: SetValueConfig = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

/** Transform BloxSearchResultDataItem to RelatedAsset for Collections */
export const searchItemToRelatedAsset = (
  item: EditorialAssetSearchResultItem,
): RelatedAsset => {
  if (!item.uuid) {
    throw new Error(
      '[searchItemToRelatedAsset]: Item is missing a UUID: ' + String(item),
    );
  }
  return {
    // per slack discussion w/ Brian, all assets from the /editorial/ search endpoint will be 'editorial'
    // but 'app' isn't returned in /editorial/asset/search/ - here defaults to 'editorial' it here in case the app isnt defined
    app: item?.app || 'editorial',
    uuid: item?.uuid,
    title: item?.title,
    type: item?.type,
    start_time: item?.start_time,
    authors: item?.authors,
    previews: {
      display: {
        url: item?.previews?.['size1']?.url,
      },
    },
  };
};

/** Format a single UserAccountSummary objecttries to concatenate
 *  the first name and last name, else uses the screen name or falls back to email address
 */
export const formatAuthor = (author?: Partial<UserAccountSummary>): string => {
  if (!author) {
    return '';
  }

  const hasMinimumInfo =
    author?.first_name || author?.last_name || author?.screen_name || author?.email;

  if (!hasMinimumInfo) {
    return '';
  }

  const formatted =
    [author.first_name, author.last_name].filter(Boolean).join(' ') ||
    author.screen_name ||
    author.email;

  if (!formatted) {
    return '';
  }

  return String(formatted).trim();
};

/** Provided an array of `UserAccountSummary` objects, uses maps using formatAuthor formula.
 * e.g. expected output: "Mike Smith, some_username, Rob" */
export const getAuthorsStringFormatted = (authors: UserAccountSummary[]): string => {
  if (authors.length === 0) return '';
  return authors.map(formatAuthor).join(', ');
};

/**
 * Provided an array of `UserAccountSummary` objects, using the formatAuthor formula,
 * tries to concatenate the first name and last name, else uses the screen name.
 * If there are more than one author, it appends " ...(x more)" where x is the count of remaining authors.
 *
 * e.g. expected output: "Mike Smith... (x more)"
 */
export const getAuthorsStringTruncated = (authors: UserAccountSummary[]): string => {
  if (authors.length === 0) return '';
  const formattedName = formatAuthor(authors[0]);
  if (authors.length > 1) {
    return `${formattedName}... (${authors.length - 1} more)`;
  }
  return formattedName;
};

/**
 * Pass in a React.DragEvent and try to parse the dataTransfer as JSON.
 * Returns the parsed JSON or false if it fails.
 */
export const tryParseDropEventAsJson = (event: React.DragEvent): any => {
  try {
    return JSON.parse(event?.dataTransfer?.getData('application/json'));
  } catch (error) {
    console.error(
      '[tryParseDropEventAsJson] - Failed to parse dropped data as JSON:',
      error,
    );
    return false;
  }
};

/**
 * Converts a date string into a pretty-readable format with dayJS.
 * @param dateString - The date string to convert.
 * @returns A pretty-readable string representing the relative time.
 * @example dateToPrettyFormat('2023-07-09T14:30:00Z') => '5 minutes ago', 'Today at 1:00AM', 'one year ago'
 */
export const dateToPrettyFormat = (dateString: string) => {
  const now = dayjs();
  const date = dayjs(dateString);

  if (date.isSame(now, 'day')) {
    const diffInMinutes = now.diff(date, 'minute');
    if (diffInMinutes < 1) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return diffInMinutes === 1
        ? `${diffInMinutes} minute ago`
        : `${diffInMinutes} minutes ago`;
    } else {
      return `today at ${date.format('h:mm A')}`;
    }
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return `yesterday at ${date.format('h:mm A')}`;
  } else {
    return date.fromNow();
  }
};

/**
 * Converts a card type string to a formatted card name.
 *
 * @param {string} cardType - The type of the card (e.g., 'visa', 'master', 'american_express', 'discover').
 * @returns {string} The formatted card name.
 *
 * @example
 * getCardName('visa'); // returns 'Visa'
 * getCardName('master'); // returns 'Mastercard'
 * getCardName('american_express'); // returns 'American Express'
 * getCardName('discover'); // returns 'Discover'
 * getCardName('other_card'); // returns 'Other Card'
 */
export const getCardName = (cardType: string | null): string => {
  if (cardType === null || cardType === undefined) {
    return 'Unknown';
  }

  switch (cardType) {
    case 'visa':
      return 'Visa';
    case 'master':
      return 'Mastercard';
    case 'american_express':
      return 'American Express';
    case 'discover':
      return 'Discover';
    default:
      return cardType.charAt(0).toUpperCase() + cardType.slice(1);
  }
};

/**
 * Formats a phone number string to a standard format.
 *
 * @param {string} value - The phone number string to format.
 * @returns {string} The formatted phone number string.
 *
 * @example
 * formatPhoneNumber('1234567890'); // returns '(123) 456-7890'
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10,
  )}`;
};

/**
 * Returns `true` if the card is considered expired given the expiration year and month.
 * The month from the backend is assumed to be 1-based (1 = January, 12 = December).
 */
export const isCardExpired = (
  expirationYear: number | null,
  expirationMonth: number | null,
): boolean => {
  // If either field is missing, treat it as not expired or handle it however your business logic requires
  if (!expirationYear || !expirationMonth) {
    return false;
  }

  // In JavaScript, months are zero-based: 0 = Jan, 11 = Dec
  // We want to compare to the last day of the given month
  // e.g. if expirationMonth = 4, that's April, so in JS that is 3 (April is 3 if zero-based).
  const lastDayOfExpMonth = new Date(expirationYear, expirationMonth, 0); // day=0 means "one day less than 1st"
  const today = new Date();

  return today > lastDayOfExpMonth;
};

/** Uses remeda's omitBy to remove null or undefined values from an object
 *
 * `note: will not remove empty arrays, or empty objects`
 *
 * `use removeAllEmptyNullUndefinedValues to also remove empty arrays and objects`
 *
 * @param obj - The object to remove null or undefined values from.
 * @returns The object with keys that have values of null or undefined removed.
 *
 * @description omitBy - Creates a shallow copy of the data, and then removes any keys that the predicate rejects.
 * Symbol keys are not passed to the predicate and would be passed through to the output as-is.
 **/
export function removeNullOrUndefinedValues<T extends AnyRecord>(obj: T): Partial<T> {
  return omitBy(obj, (value) => value === null || value === undefined) as Partial<T>;
}

/**
 * Removes any keys of an object that have values such as
 * null, undefined, empty strings, empty arrays, or empty objects.
 * @example
 * const obj = { key1: null, key2: undefined, key3: '', key4: [], key5: {}, key6: 'hello' };
 * const cleanedObj = removeNullUndefinedEmptyValues(obj);
 * console.log(cleanedObj); // { key6: 'hello' }
 */
export function removeAllEmptyNullUndefinedValues<T extends AnyRecord>(
  obj: T,
): Partial<T> {
  return omitBy(obj, (value) => {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === 'string' && value.trim() === '') {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0
    ) {
      return true;
    }
    return false;
  }) as Partial<T>;
}

/**
 * Returns a boolean indicating if string, array, or object has a length greater than 0.
 * @description null or undefined values return false.
 * @param checkObjects Optional parameter to check both string and symbol key length in objects.
 */
export function hasLength(value: unknown, checkObjects?: boolean): boolean {
  if (value === null || value === undefined) {
    return false;
  }

  // Handle strings
  if (isString(value)) {
    const check = value?.length > 0;
    return check;
  }

  // Handle arrays
  if (isArray(value)) {
    const check = value?.length > 0;
    return check;
  }

  // Handle plain objects if checkObjects enabled - not concerned with symbol keys
  if (checkObjects && isPlainObject(value)) {
    const keylen = keys(value)?.length;
    const check = keylen > 0;
    return check;
  }

  // fallback...
  return Boolean(value);
}

/**
 * Deduplicates an array of strings.
 * @param arr - The array of strings to deduplicate.
 * @returns  A new array with duplicate strings removed.
 */
export function dedupeStrArr(arr: string[]): string[] {
  if (!arr) return [];
  if (!Array.isArray(arr)) {
    console.warn('[dedupeStrArr]: Expected an array of strings, got:', arr);
    return [];
  }
  return [...new Set(arr)];
}

/**
 * `hasValue` checks if a value is not null, undefined, or an empty string.
 * e.g. a "0" value is considered a valid value.
 **/
export function hasValue<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined && value !== '';
}

/**
 *
 * @param countryName
 * @returns
 */
export const getCountryCode = (countryName: string): string => {
  const country = CountryRegionData.find(([name]) => name === countryName);
  return country ? country[1] : '';
};

/**
 *
 * @param countryCode
 * @param regionCode
 * @returns
 */
export const getRegionCode = (countryCode: string, regionName: string): string => {
  const country = CountryRegionData.find(([, code]) => code === countryCode);
  if (!country) return '';

  const regions = country[2].split('|');
  const region = regions.find((r) => {
    const [name] = r.split('~');
    return name === regionName;
  });

  return region ? region.split('~')[1] : '';
};

export const getCountryName = (countryCode: string): string => {
  const country = CountryRegionData.find(([, code]) => code === countryCode);
  return country ? country[0] : '';
};

export const getRegionName = (countryCode: string, regionCode: string): string => {
  const country = CountryRegionData.find(([, code]) => code === countryCode);
  if (!country) return '';

  const regions = country[2].split('|');
  const region = regions.find((r) => {
    const [, code] = r.split('~');
    return code === regionCode;
  });

  return region ? region.split('~')[0] : '';
};

export const getCountryCodeFromName = (countryName: string): string => {
  const country = CountryRegionData.find(
    ([name]) => name.toLowerCase() === countryName.toLowerCase(),
  );
  return country ? country[1] : countryName;
};

export const getRegionCodeFromName = (country: string, regionName: string): string => {
  const countryData = CountryRegionData.find(([, code]) => code === country);
  if (!countryData) return regionName;

  const regions = countryData[2].split('|');
  const region = regions.find(
    (r) => r.split('~')[0].toLowerCase() === regionName.toLowerCase(),
  );
  return region ? region.split('~')[1] : regionName;
};

export const getObjectDiff = (previous: any, current: any, compareRef = false) => {
  return Object.keys(previous).reduce((result, key) => {
    if (!current.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(previous[key], current[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && previous[key] !== current[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(current));
};

export const getWireDriverIcons = (
  driver: string,
  size?: number | string,
): JSX.Element => {
  switch (driver) {
    case 'ap_media_api':
      return <APNewsIcon sx={{ fontSize: size }} />;
    case 'cnn':
      return <CNNIcon sx={{ fontSize: size }} />;
    case 'wordpress':
      return <WordPressIcon sx={{ fontSize: size }} />;
    case 'stringr':
      return <StringrIcon sx={{ fontSize: size }} />;
    case 'apnews':
      return <APNewsIcon sx={{ fontSize: size }} />;
    case 'youtube':
      return <YouTubeIcon sx={{ fontSize: size }} />;
    case 'atom':
      return <RssFeedIcon sx={{ fontSize: size }} />;
    default:
      return <BloxNXTIcon sx={{ fontSize: size }} />;
  }
};
export const getOrdinal = (num: number): string => {
  if (num === 0) return '0';
  const pr = new Intl.PluralRules('en', { type: 'ordinal' });
  const suffixes: Record<string, string> = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };

  const rule = pr.select(num);

  return num + (suffixes[rule] || 'th');
};
