import { httpClient } from 'providers/clients/httpClient';
import { stringify } from 'query-string';

import {
  GetRelatedContentStyleResponse,
  PostRelatedContentStyleResponse,
  RelatedContentStyle,
} from 'utility/types/dataProvider';

export type TGetRelatedContentStylesResult = {
  relatedContentStyles?: any[];
};

export type TGetRelatedContentStylesParams = {
  params?: {
    limit?: number;
    page?: number;
  };
};

// -----------------------------------------------
// ------- RELATED CONTENT STYLES PROVIDER -------
// -----------------------------------------------
export const relatedContentStylesProvider = {
  // Get all related content styles.
  getRelatedContentStyles: async ({
    params,
  }: TGetRelatedContentStylesParams): Promise<TGetRelatedContentStylesResult> => {
    try {
      let url = `/editorial/relatedcontentstyle/list/`;

      if (params) {
        url += `?${stringify(params)}`;
      }

      const { data } = await httpClient({
        url: url,
        method: 'GET',
      });

      const editorialConfig: TGetRelatedContentStylesResult = data;
      return editorialConfig;
    } catch (error) {
      console.error('[getRelatedContentStyles] Error', error);
      return { relatedContentStyles: [] };
    }
  },

  // Get related content style by id.
  getRelatedContentStyle: async (
    id: string,
  ): Promise<GetRelatedContentStyleResponse | null> => {
    try {
      const { data } = await httpClient({
        url: `/editorial/relatedcontentstyle/${id}/`,
        method: 'GET',
      });

      return data;
    } catch (error) {
      console.error('[getRelatedContentStyle] Error', error);
      return null;
    }
  },

  // Post related content style.
  postRelatedContentStyle: async (
    body: RelatedContentStyle,
  ): Promise<PostRelatedContentStyleResponse | null> => {
    try {
      const { data } = await httpClient({
        url: `/editorial/relatedcontentstyle/`,
        method: 'POST',
        data: JSON.stringify(body),
      });

      return data;
    } catch (error) {
      console.error('[postRelatedContentStyle] Error', error);
      return null;
    }
  },

  // Put related content style.
  putRelatedContentStyle: async (id: string, body: RelatedContentStyle): Promise<any> => {
    try {
      const { data } = await httpClient({
        url: `/editorial/relatedcontentstyle/${id}/`,
        method: 'PUT',
        data: JSON.stringify(body),
      });

      return data;
    } catch (error) {
      console.error('[putRelatedContentStyle] Error', error);
      return {};
    }
  },

  // Delete related content style.
  deleteRelatedContentStyle: async (id: string): Promise<any> => {
    try {
      const { data } = await httpClient({
        url: `/editorial/relatedcontentstyle/${id}/`,
        method: 'DELETE',
      });

      return data;
    } catch (error) {
      console.error('[deleteRelatedContentStyle] Error', error);
      return null;
    }
  },
};
