import { PropsWithChildren, memo } from 'react';
import { QueryClient, QueryClientProvider, UseQueryOptions } from 'react-query';

export type SubsetQueryOptions = Pick<
  UseQueryOptions<any, any>,
  'refetchOnMount' | 'refetchOnWindowFocus' | 'refetchOnReconnect'
>;

export const commonQueryOptions: SubsetQueryOptions = {
  refetchOnReconnect: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

// IMPORTANT: The query client must be stable - see examples:
// https://tanstack.com/query/v5/docs/eslint/stable-query-client
// If we need to set some static options we can set it here.
// Else we can use the queryClient.setDefaultOptions() method via hook.

export const queryClient = new QueryClient();

export const CosmosQueryClientWrapper = memo(({ children }: PropsWithChildren) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
});
CosmosQueryClientWrapper.displayName = 'CosmosQueryClientWrapper';

/**
 * Notes:
 *
 * Ensure upon site switching in CosmosContext that the queryClient
 * is reset and all query and cache is cleared. As this is to clear the cached
 * results as they correspond to the activeSite (x-blox-domain) of the fetch.
 *
 *
 * CMSADMIN-1045
 * Maybe something like this inside of a useEffect within CosmosContext, perhaps
 * once the initial getSessionConfigRaw is successful so we have the activeSite set...
 *
 * note it will affect all queries within the app and we should be careful so we may
 * need to have a way to exclude certain string like an 'exclude' or 'skipKey', some flag
 * that we can check for in the queryKey hash fn indicating to not add the authQueryKeys.
 *
 * May have to manually add some of these to the initCosmosQuery useQuery
 * like `site:${usingDomain}`,but not isAuthed as or userId, and maybe keepPreviousData: true...
 * as we would not want the key to change causing a refetch or unmount of child components...
 *
 *
 * Can confirm though that this method would allow us to add the authQueryKeys to the queryKey
 * of React-Admin components, as they currently seem to ignore the queryKey prop...
 *
 * // e.g. ... something like this
 *  const auth = useAuth();
 *  const { activeSite } = useCosmos();
 *  const authQueryKeys = getAuthQueryKeysArr({
 *    site: activeSite,
 *   .. and maybe some others?
 *  });
 *
 * const setDefaultOptions = async () => {
 *    queryClient.setDefaultOptions({
 *      queries: {
 *        queryKeyHashFn: (queryKey) => {
 *          // copy any existing queryKey, normalize to array
 *          const copyKeys = Array.isArray(queryKey) ? [...queryKey] : [queryKey];
 *          // add the authQueryKeys to the queryKey
 *          const updatedKeys = [...authQueryKeys, ...copyKeys];
 *          // hash the new queryKey with rq's hash fn
 *          const hashedNewKeys = hashQueryKey(updatedKeys);
 *          // Return the new queryKey
 *          return hashedNewKeys;
 *        },
 *      },
 *    });
 *  };
 *
 *
 */
