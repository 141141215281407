import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const WordPressIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="28"
                fill="none"
                viewBox="0 0 463.4 463.9"
            >
                <path fill="currentColor" d="M208.9,1c-29.3,2.7-60.8,12.5-88,27.4-20.6,11.2-34.2,21.5-52.6,39.8-27.2,27.1-45,55.8-56.9,92.1-24.7,74.8-8.5,159.2,42,219.4,6.9,8.2,25,26.2,33,32.8,14.5,11.9,36.9,25.3,54.9,32.8,72.8,30.5,153,23,218.8-20.3,13.1-8.7,20.7-14.9,33.6-27.6,37.4-36.6,59-78.8,67.8-132.1,2.6-15.4,2.6-51.5.1-67-10.7-64.9-43.7-118.4-96.5-156.2C321.2,10.7,264.4-4.3,208.9,1ZM263.4,12.4c71.4,9.8,135.8,56.7,167.4,121.8,15.8,32.4,22.4,61.7,22.4,98.1s-14.9,94-46.6,136c-8.2,10.8-29.1,31.5-40.7,40.2-44.9,33.6-100.6,49.6-154.1,44.3-103-10.1-184.8-88.3-199.5-190.5-2.3-16.1-2.3-44.9,0-61C27.1,98.1,110.5,19.5,214.3,10.8c12.7-1.1,34.8-.3,49.1,1.6Z" />
                <path fill="currentColor" d="M216.9,35.9c-67.4,4.5-130.3,46.5-160.9,107.6-2.8,5.7-5.1,10.6-5.1,10.9s20.7.4,46,.1l46-.7v10h-6.4c-3.6,0-7.5.4-8.8.9-4,1.5-7.6,6-8.8,11.2l-1.2,4.9,32.8,82.5c18.1,45.4,33.2,82.5,33.5,82.5s10.3-23.4,22.3-52.1l21.8-52-12.3-31.5c-9-22.9-13.3-32.6-15.7-35.4-4.3-5.1-14.2-9.9-23.1-11.3l-7.1-1.1v-8.6h137v9.8l-10,.7c-7.8.6-10.7,1.2-13.3,2.9-3.6,2.4-6.2,8.6-5.2,12.6,1.9,7.6,60,160.8,60.7,159.7,1-1.9,20.6-52,23.4-60.2,7.7-21.8,11-43.7,10.1-67.7-.7-18.3-2.8-29.4-7.5-39.8-8-17.6-11.1-36-7.9-47.1,3.1-10.5,11-18,20.8-19.7l4.4-.7-8-8.4c-40.4-42.3-97.5-64-157.5-60Z" />
                <path fill="currentColor" d="M419.7,175.8c-10.1,25.4-86.7,225.4-86.5,225.7.6.6,14.7-8.9,22.7-15.4,8.9-7.2,25.7-24.3,32.1-32.8,35.4-46.3,48.8-103.7,37.4-160.3-2.8-13.5-4.8-19.5-5.7-17.2Z" />
                <path fill="currentColor" d="M39.5,190c-3.8,17.5-4.9,31.6-4.2,50.8,1.7,48.1,20.4,92.3,54,127.8,11.4,12.1,27.1,24.6,42.7,34l3.7,2.2-6.8-16.7c-3.8-9.3-24.6-60.3-46.2-113.5s-39.6-96.8-39.9-96.8-1.8,5.5-3.3,12.2Z" />
                <path fill="currentColor" d="M203.7,340.1c-18,41.9-33,77-33.3,77.9-.5,1.3.5,2,5.7,3.6,32.3,10,69.7,10.8,103.8,2.3,15.5-3.9,17.4-4.7,16.6-6.9-5.8-15.8-59.2-153.2-59.6-153.2s-15.2,34.3-33.2,76.3Z" />
            </svg>
        </SvgIcon>
    );
};

export default WordPressIcon;
