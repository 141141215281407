import { httpClient } from 'providers/clients/httpClient';
import { UrlMapNode, UrlMapResponse } from 'utility/types/dataProvider';

export const urlMapProvider = {
  // Get site URL maps
  getUrlMap: async (limit: number): Promise<UrlMapResponse> => {
    // Force limit to at least one
    if (limit < 1) limit = 1;
    try {
      const path = `/core/urlmap/?limit=${limit}`;

      const { data } = await httpClient({
        url: path,
      });

      return data;
    } catch (error) {
      console.error('[getUrlMap] Error:', error);
      throw error;
    }
  },

  // Get a URL map's root ('/') node
  getUrlMapRootNode: async (map_id: string): Promise<UrlMapNode> => {
    try {
      const path = `/core/urlmap/${map_id}/root/`;

      const { data } = await httpClient({
        url: path,
      });

      return data;
    } catch (error) {
      console.error('[getUrlMapRootNode] Error:', error);
      throw error;
    }
  },

  // Get URL map node
  getUrlMapNode: async (map_id: string, url_id: string): Promise<UrlMapNode> => {
    try {
      const path = `/core/urlmap/${map_id}/nodes/${url_id}/`;

      const { data } = await httpClient({
        url: path,
      });

      return data;
    } catch (error) {
      console.error('[getUrlMapNode] Error:', error);
      throw error;
    }
  },

  // Create a new URL map node child
  createUrlMapNodeChild: async (
    map_id: string,
    url_id: string,
    body: UrlMapNode,
  ): Promise<UrlMapNode> => {
    try {
      const path = `/core/urlmap/${map_id}/nodes/${url_id}/children/`;

      const { data } = await httpClient({
        url: path,
        method: 'POST',
        data: JSON.stringify(body),
      });

      return data;
    } catch (error) {
      console.error('[createUrlMapNodeChild] Error:', error);
      throw error;
    }
  },

  // Update a URL map node
  updateUrlMapNode: async (
    map_id: string,
    url_id: string,
    body: UrlMapNode,
  ): Promise<any> => {
    try {
      const path = `/core/urlmap/${map_id}/nodes/${url_id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'PUT',
        data: JSON.stringify(body),
      });

      return data;
    } catch (error) {
      console.error('[updateUrlMapNode] Error:', error);
      throw error;
    }
  },

  // Delete a URL map node
  deleteUrlMapNode: async (map_id: string, url_id: string): Promise<void> => {
    try {
      const path = `/core/urlmap/${map_id}/nodes/${url_id}/`;

      const { data } = await httpClient({
        url: path,
        method: 'DELETE',
      });

      return data;
    } catch (error) {
      console.error('[deleteUrlMapNode] Error:', error);
      throw error;
    }
  },

  // Get URL map node children
  getUrlMapNodeChildren: async (
    map_id: string,
    url_id: string,
  ): Promise<UrlMapNode[]> => {
    try {
      const path = `/core/urlmap/${map_id}/nodes/${url_id}/children/`;

      const { data } = await httpClient({
        url: path,
      });

      return data;
    } catch (error) {
      console.error('[getUrlMapNodeChildren] Error:', error);
      throw error;
    }
  },

  // Perform a depth search of a URL map
  searchUrlMap: async (
    map_id: string,
    query?: string,
    app?: string,
    alias?: string,
  ): Promise<UrlMapNode[]> => {
    if (!map_id) {
      throw new Error('Missing required parameter: map_id');
    }

    try {
      const path = `/core/urlmap/${map_id}/depth/`;

      const params = new URLSearchParams({
        ...(alias && { alias }),
        ...(query && { query }),
        ...(app && { app }),
      });

      const stringPath = `${path}?${params.toString()}`;

      const { data } = await httpClient({
        url: stringPath,
      });

      return data;
    } catch (error) {
      console.error('[searchUrlMap] Error:', error);
      throw error;
    }
  },
};
