import { useSessionStorage } from '@mantine/hooks';
import { httpClient, rawAxiosInstance } from 'providers/clients/httpClient';
import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import NoCosmosPermissions from '../../components/generic/NoCosmosPermissions';
import {
  ApiErrorResponse,
  GetUserMeFullItem,
  SessionConfigResult,
  UserAccountGroups,
} from '../../utility/types/dataProvider';
import {
  AUTH_PREFIX,
  getAuthTokenViaStorage,
  getAuthTokenViaUser,
  getUserDefaultSite,
  useAuthStoreZustand,
} from './CosmosAuthEnvContext';
import { usersProvider } from './extensions/Users';
import { flushSync } from 'react-dom';
import { clearPrefixedStorage } from 'utility/functions';

// These functions will go through the standard httpclient to fetch
// So they will need activeSiteZustand, apiurl, and authtoken
// already set in the zustand store before you call them.
const { getActiveUserGroups, getUserMe } = usersProvider;

//----------------------------------------------------------------------
// Types and Consts
//----------------------------------------------------------------------
export type SessionConfigType = SessionConfigResult['data'];

export interface CosmosContextType {
  changeActiveSiteAsync: (newActiveSite: string) => Promise<string>;
  clearInvalidDomain: () => void;
  /** The currently 'active' site/domain. */
  activeSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigType;
  userInfo?: GetUserMeFullItem; // get /user/me/
  userGroups?: UserAccountGroups;
  userIsAdmin?: boolean;
  userPermissions?: { [key: string]: any } | null;
}

// Initial state - certain other values in context are derived.
const baseCosmosState: CosmosStateType = {
  renderReady: false, // prevent no permissions flicker
  initStatus: undefined, // Track init status for effect
  activeSite: '',
  sessionConfig: undefined,
  userInfo: undefined,
  userGroups: undefined,
};

interface CosmosStateType {
  renderReady: boolean;
  activeSite: string;
  initStatus: 'success' | 'invalid' | 'failed' | undefined; // Track init status from effect
  sessionConfig?: SessionConfigType;
  userInfo?: GetUserMeFullItem;
  userGroups?: UserAccountGroups;
}

// Custom return type for the initialSessionConfig useQuery.
// Status:
// - success: the domain we tried was valid and we got the data
// - invalid: the domain we tried was invalid and you need to fallback to oidc site
// - failed: the oidc fallback site failed to initialize the app, so cosmos is unable to init
type InitialStateUpdate = {
  initStatus: 'success' | 'invalid' | 'failed'; // tried domain status for effect
  badSite: string; // if bad status return the site that failed/invalid for invalidDomain storage
  activeSite: string; // empty string until success
  sessionConfig?: SessionConfigType; // undefined until success
};

interface InitCosmosQueryFnParams {
  domain?: string;
  isFallbackDomain?: boolean;
  apiUrl?: string;
}

//----------------------------------------------------------------------
// Helper Functions
//----------------------------------------------------------------------
export function getAddressBarDomain(pathname: string) {
  return pathname?.split('/')[1]?.toLowerCase()?.trim();
}

function checkCosmosAccess(
  sessionConfig?: SessionConfigType,
  userInfo?: GetUserMeFullItem,
) {
  if (!sessionConfig || !userInfo) {
    return false; // No session or user info, can't determine access yet.
  }
  const hasCosmos = Boolean(sessionConfig?.software_privileges?.core?.includes('cosmos'));
  const isStaffUser = Boolean(userInfo?.account_type === 'staff');
  // Staff always allowed, otherwise must have cosmos privileges.
  return isStaffUser || hasCosmos;
}

//----------------------------------------------------------------------
// Query Functions
//----------------------------------------------------------------------

/**
 * Get user's session config via the rawHttpClient function
 *
 * It will route through the rawHttpClient function rather than the httpClient function,
 * as the httpClient would need certain states in storage before it can be used.
 *
 * This is necessary for the initial call as the app must determine if the url in the
 * address bar is valid for the user to set the activeSite in the context.
 * If it fails, we assume that the user is not authorized to the site
 * and we retry using the x-blox-domain in their oidc user jwt profile.
 */

interface GetSessionConfigRawParams {
  activeSite?: string;
  apiUrl?: string;
}

async function getSessionConfigRaw({
  activeSite,
  apiUrl,
}: GetSessionConfigRawParams): Promise<SessionConfigResult> {
  try {
    if (!activeSite || !apiUrl) {
      throw new Error('[getSessionConfigRaw] Missing required parameters.');
    }

    const fullUrl = `${apiUrl}/core/session/config/`;

    const authToken = await getAuthTokenViaStorage();

    const { data, status } = await httpClient(
      {
        url: fullUrl,
        headers: {
          Authorization: authToken,
          'X-Blox-Domain': activeSite,
        },
      },
      rawAxiosInstance,
    );

    const result: SessionConfigResult = {
      status: data?.status || status,
      data: data?.data,
    };

    return result;
  } catch (error) {
    console.error('[getSessionConfigRaw] error:', error);
    throw error;
  }
}

// Intialize Cosmos - get /core/session/config/
async function initCosmosQueryFn({
  domain,
  isFallbackDomain,
  apiUrl,
}: InitCosmosQueryFnParams): Promise<InitialStateUpdate> {
  if (!domain || !apiUrl) {
    throw new Error('[initCosmosQueryFn] Missing required parameters.');
  }

  try {
    // Try using the domain that was passed to the function
    const sessionConfigResult = await getSessionConfigRaw({
      activeSite: domain,
      apiUrl: apiUrl,
    });

    // If successful the domain we tried was valid.
    const successState: InitialStateUpdate = {
      initStatus: 'success',
      sessionConfig: sessionConfigResult?.data,
      activeSite: String(domain),
      badSite: '',
    };
    return successState;
  } catch (error) {
    // If the fetch was unsuccesful return an invalid state update
    // additionally, if flag for "isFallbackSite" was true, set this a 'failure' status
    const failedState: InitialStateUpdate = {
      initStatus: isFallbackDomain ? 'failed' : 'invalid',
      badSite: String(domain),
      activeSite: '',
      sessionConfig: undefined,
    };
    return failedState;
  }
}

//----------------------------------------------------------------------
// CosmosContext Provider and Hooks
//----------------------------------------------------------------------
export const CosmosContext = createContext<CosmosContextType | undefined>(undefined);

export const useCosmos = () => {
  const context = useContext(CosmosContext);
  if (!context) {
    throw new Error('useCosmos must be used within a CosmosProvider!');
  }
  return context;
};

export const CosmosProvider = ({ children }: PropsWithChildren) => {
  const { envState, currentUser, activeSiteZustand, setActiveSiteZustand } =
    useAuthStoreZustand();
  const apiUrl = envState?.apiUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [cosmosState, setCosmosState] = useState<CosmosStateType>(baseCosmosState);

  // session storage for invalid domain as app must nav to reconfigure if it was invalid
  const [invalidDomain, setInvalidDomain] = useSessionStorage<string>({
    key: 'invalidDomain',
    defaultValue: '',
  });

  // Check if the user has access to cosmos
  const canAccessCosmos = useMemo(() => {
    return checkCosmosAccess(cosmosState?.sessionConfig, cosmosState?.userInfo);
  }, [cosmosState?.sessionConfig, cosmosState?.userInfo]);

  // Setup some variables for init process
  const addressBarDomain = getAddressBarDomain(location.pathname);
  const authBloxCMSDefaultSite = getUserDefaultSite(currentUser);
  const authToken = getAuthTokenViaUser(currentUser);

  // Domain we will use for our init query on app load and if the user switches sites.
  // Use address bar domain or fallback to the oidc default site if bar is empty
  const [domainForQuery, setDomainForQuery] = useState<string>(() => {
    const initialDomain = addressBarDomain || authBloxCMSDefaultSite;
    return initialDomain;
  });

  // Mutation - Swap to the domain string provided.
  const { mutateAsync: changeActiveSiteAsync, status: mutateSiteStatus } = useMutation({
    retry: false,
    mutationFn: async (newActiveSite: string) => {
      // Wait to make this a real async fn and allow states time to reconfig
      // basically allows this fn to go into "loading" state for other checks...
      await new Promise((resolve) => setTimeout(resolve, 300));

      // Navigate to the new site requested
      navigate(`/${newActiveSite?.toLowerCase()}`, {
        replace: false,
        state: {
          fromSite: addressBarDomain?.toLowerCase(),
          newSite: newActiveSite?.toLowerCase(),
        },
      });

      // Return string for onSettled to set the newActiveSite init refetch.
      return newActiveSite;
    },
    // onMutate docs: function will fire before the mutation function is fired
    // and is passed the same variables the mutation function would receive
    onMutate: async (variables: string) => {
      // On swapped site - Clear/reset/invalidate any errors,
      // queries, states as we are about to refetch / reinitialize.

      // Empty domainForQuery to disable refetch while switching
      flushSync(() => {
        setDomainForQuery('');
      });

      try {
        // Cancel any outgoing queries
        await queryClient.cancelQueries(
          {
            active: true,
            stale: true,
            fetching: true,
            inactive: true,
          },
          {
            revert: false,
            silent: true,
          },
        );

        // Invalidate all and without refetching
        await queryClient.invalidateQueries(
          {
            active: true,
            stale: true,
            fetching: true,
            inactive: true,
            refetchActive: false,
            refetchInactive: false,
          },
          {
            throwOnError: false,
            cancelRefetch: true,
          },
        );

        // Remove all queries from the cache
        queryClient.removeQueries({
          exact: false,
          active: true,
          inactive: true,
          fetching: true,
          stale: true,
        });
      } catch (error) {
        console.error('[CosmosContext -onMutate] Error:.', error);
      }

      // Important: ensure queryClient cache is cleared as app will reconfigure / reload
      queryClient.clear();

      // Clear any invalid domain / storages... reinit will set new values if needed.
      setInvalidDomain('');

      // Also reset zustand active site to be safe
      setActiveSiteZustand('');

      // Reset the cosmos state to initial state
      setCosmosState(baseCosmosState);

      // Now go to the mutationFn...
      return variables;
    },
    onSettled: async (result) => {
      // mutationFn returns newActiveSite string so set it as the new
      // domainForQuery once routing is done and to re-enable the init query.
      if (!result) {
        // Best to hard reset to root if things error here.
        console.error('[CosmosContext - onSettled] Error: falsy newActiveSite:', result);
        clearPrefixedStorage(AUTH_PREFIX, ['localStorage']);
        window.sessionStorage.clear();
        window.location.replace(`${window.location.origin}/`);
      } else {
        setDomainForQuery(result?.toLowerCase());
      }
    },
    onError: (error, variables, context) => {
      // Should not error as the mutations just a navigate call, logging anyway for posterity.
      console.error('[CosmosContext  - Mutation] onError...', error);
    },
  });

  // Enable init query once required values are available
  // If active site is set we already ran the fetch.
  // Edge case dont rerun if midway through site switching...
  const initQueryEnabled = Boolean(
    domainForQuery && // must have domain set to query
      !cosmosState.activeSite && // state not already set
      !activeSiteZustand && // also ensure zustand was cleared
      currentUser &&
      apiUrl &&
      authToken,
  );

  const {
    data: initCosmosQueryData,
    error: initCosmosQueryError,
    status: initCosmosQueryStatus,
  } = useQuery<InitialStateUpdate, ApiErrorResponse>({
    queryKey: [`site:${domainForQuery}`, 'CosmosProvider', 'getSessionConfigRaw'],
    queryFn: async () => {
      const result = await initCosmosQueryFn({
        domain: domainForQuery,
        isFallbackDomain: Boolean(domainForQuery === authBloxCMSDefaultSite),
        apiUrl: apiUrl,
      });
      return result;
    },
    enabled: initQueryEnabled,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state initCosmosQuery once it's available or changes.
  useEffect(() => {
    if (!initCosmosQueryData) return;

    const initStatus = initCosmosQueryData?.initStatus;
    const activeSite = initCosmosQueryData?.activeSite;
    const sessionConfig = initCosmosQueryData?.sessionConfig;
    const badSite = initCosmosQueryData?.badSite;

    if (initStatus === 'success') {
      // Update states as fetch was valid
      // Update the state with the the new/initial data fetched and processed.
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        activeSite: activeSite,
        sessionConfig: sessionConfig,
      }));
      setActiveSiteZustand(activeSite); // Copy to zustand for use in httpclient
      // usingDomain could have fallbacked to use the OIDC site, for example if
      // there was an empty string in the addressBarDomain and we fallback to oidc default site
      // so if the determined active site is not the addressBarDomain then
      // navigate to the site that we used to initialize the app to correct the address bar
      if (addressBarDomain !== activeSite) {
        navigate(`/${activeSite}`, {
          replace: true,
          state: {
            fromSite: addressBarDomain,
            newSite: activeSite,
          },
        });
      }
    }

    if (initStatus === 'invalid') {
      // Update the cosmos state - invalid means we need to try again
      // using the oidc fallback site... likely the user typed in a domain that
      // was invalid so we need to re-run the initCosmosQuery with the oidc site
      // call navigate with the oidc site to change queryKey and refetch
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        activeSite: '',
        sessionConfig: undefined,
      }));
      setActiveSiteZustand(''); // Match cosmos states
      setInvalidDomain(badSite); // Store the bad site for error display
      setDomainForQuery(authBloxCMSDefaultSite); // retry with oidc site
    }

    if (initStatus === 'failed') {
      // this means even the fallback oidc site has failed
      // and cosmos cant proceed with initialization at all
      console.error('[CosmosProvider] - Failed to initialize domain.');
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        activeSite: '',
        sessionConfig: undefined,
      }));
      setActiveSiteZustand(''); // Match cosmos states
      setInvalidDomain(badSite);
    }
  }, [
    queryClient,
    setActiveSiteZustand,
    initCosmosQueryData,
    addressBarDomain,
    setInvalidDomain,
    authBloxCMSDefaultSite,
    navigate,
  ]);

  // Enable post-init queries once the activeSite is set and initStatus is success
  const enablePostInitQueries = Boolean(
    apiUrl &&
      activeSiteZustand &&
      cosmosState?.activeSite &&
      cosmosState?.initStatus === 'success',
  );

  // Get current user info from /user/me/
  const {
    data: userMeQueryData,
    error: userMeQueryError,
    status: userMeQueryStatus,
  } = useQuery<GetUserMeFullItem, ApiErrorResponse>({
    queryKey: [`site:${cosmosState?.activeSite}`, 'CosmosProvider', 'getUserMe'],
    queryFn: async () => {
      const userInfo = await getUserMe();
      const userData: GetUserMeFullItem = {
        ...userInfo?.data,
      };
      return userData;
    },
    enabled: enablePostInitQueries,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userMeData once it's available or changes.
  useEffect(() => {
    if (userMeQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userInfo: userMeQueryData,
      }));
    }
  }, [userMeQueryData]);

  // Get current user groups from /user/me/groups
  const {
    data: userGroupsQueryData,
    error: userGroupsQueryError,
    status: userGroupsQueryStatus,
  } = useQuery<UserAccountGroups, ApiErrorResponse>({
    queryKey: [
      `site:${cosmosState?.activeSite}`,
      'CosmosProvider',
      'getActiveUserGroups',
    ],
    queryFn: async () => {
      const userGroups = await getActiveUserGroups();
      return userGroups;
    },
    enabled: enablePostInitQueries,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userGroupsQueryData once it's available or changes.
  useEffect(() => {
    if (userGroupsQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userGroups: userGroupsQueryData,
      }));
    }
  }, [userGroupsQueryData]);

  // Context value
  const contextValue = useMemo(() => {
    return {
      activeSite: cosmosState?.activeSite,
      sessionConfig: cosmosState?.sessionConfig,
      userInfo: cosmosState?.userInfo,
      userGroups: cosmosState?.userGroups,
      userIsAdmin: cosmosState?.sessionConfig?.user?.site_admin || false,
      userPermissions: cosmosState?.sessionConfig?.user?.permissions || null,
      changeActiveSiteAsync: changeActiveSiteAsync,
      invalidDomain: invalidDomain,
      clearInvalidDomain: () => {
        setInvalidDomain('');
      },
    };
  }, [cosmosState, changeActiveSiteAsync, invalidDomain, setInvalidDomain]);

  // --------------- Rendering / Returns ---------------
  // Check if all queries are successful and set cosmosState.ready to render
  // as to prevent no permissions flicker while loading/checking.
  useEffect(() => {
    const allReady = Boolean(
      initCosmosQueryStatus === 'success' &&
        userMeQueryStatus === 'success' &&
        userGroupsQueryStatus === 'success' &&
        mutateSiteStatus !== 'loading',
    );
    // All queries are done and ready to render
    if (allReady && !cosmosState.renderReady) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        renderReady: true,
      }));
    }
    // Unready if these were ready and they became not ready
    if (!allReady && cosmosState.renderReady) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        renderReady: false,
      }));
    }
  }, [
    cosmosState,
    initCosmosQueryStatus,
    mutateSiteStatus,
    userMeQueryStatus,
    userGroupsQueryStatus,
  ]);

  const anyErrors = Boolean(
    initCosmosQueryError ||
      userMeQueryError ||
      userGroupsQueryError ||
      cosmosState.initStatus === 'failed',
  );

  if (anyErrors) {
    const errorResults: ErrorDetails[] = [
      initCosmosQueryError,
      userMeQueryError,
      userGroupsQueryError,
    ]
      .filter(Boolean)
      .map((error) => {
        return {
          message: error?.message,
          status: error?.status,
          fullDetails: error,
        };
      });

    // If we had a total failure to initialize via any domain push extra error...
    if (cosmosState.initStatus === 'failed') {
      errorResults.push({
        message: 'Application failed to initialize.',
        status: 'Please try clearing the browser cache and try again.',
      });
    }

    console.error('[CosmosProvider Errors]:', errorResults);

    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        errors={errorResults}
        buttonOnClick={() => {
          // Best to try a hard reset if things fail this early.
          clearPrefixedStorage(AUTH_PREFIX, ['localStorage']);
          window.sessionStorage.clear();
          window.location.reload();
        }}
      />
    );
  }

  if (!cosmosState.renderReady || mutateSiteStatus === 'loading') {
    return <BloxLoadingSplash message="Please wait..." />;
  }

  if (!canAccessCosmos) {
    return <NoCosmosPermissions />;
  }

  // You can see this flash when we hit the back/forward button after changing sites
  // beacuse we need config fetched first in order to determine the the "route"
  // for cosmos to load, but the app was already mounted with activeSite/<routes>/
  // so this fallback should show a loader for brief the duration of a site switch
  // and if it takes too long, it will log a warning for us to investigate.
  if (cosmosState?.activeSite && addressBarDomain !== cosmosState?.activeSite) {
    return (
      <RouteWithTimeoutWarning
        activeSite={cosmosState?.activeSite}
        addressBarDomain={addressBarDomain}
        oidcDefaultSite={authBloxCMSDefaultSite}
      />
    );
  }

  return <CosmosContext.Provider value={contextValue}>{children}</CosmosContext.Provider>;
};

interface RouteWithTimeoutWarningProps {
  activeSite: string;
  addressBarDomain: string;
  oidcDefaultSite: string;
}

const RouteWithTimeoutWarning = memo(
  ({ activeSite, addressBarDomain, oidcDefaultSite }: RouteWithTimeoutWarningProps) => {
    const [isTimedOut, setIsTimedOut] = useState(false);

    // If nothing matches for 30 seconds, log and timeout.
    useEffect(() => {
      if (isTimedOut) return;
      const timeout = setTimeout(() => {
        setIsTimedOut(true);
        console.error(
          '[RouteWithTimeoutWarning] No route was matched for the greater than 10 seconds!\n',
          'Logging information for investigation:',
          '\nactiveSite:',
          activeSite,
          '\naddressBarDomain:',
          addressBarDomain,
          '\noidcDefaultSite:',
          oidcDefaultSite,
        );
      }, 30000);

      return () => {
        clearTimeout(timeout); // Cleanup timeout on unmount
      };
    }, [isTimedOut, setIsTimedOut, activeSite, addressBarDomain, oidcDefaultSite]);

    if (isTimedOut) {
      return (
        <ErrorAlerts
          headerText="Error"
          severity="error"
          buttonText="Reload"
          buttonOnClick={() => {
            // Best to try a hard reset to root if things fail.
            clearPrefixedStorage(AUTH_PREFIX, ['localStorage']);
            window.sessionStorage.clear();
            window.location.replace(`${window.location.origin}/`);
          }}
          errors={[
            {
              status: `Sorry, we were unable to load the application.`,
              message: `Please try clearing your browser cache and reloading the page.`,
            },
          ]}
        />
      );
    }

    // Show the loading splash while we wait for the site swithch to complate
    // and give react-router a path to render in the meantime fixing the
    // "no route matched" warning from react-router mid back/forward navigation
    // https://github.com/remix-run/react-router/discussions/10851
    return (
      <Routes>
        <Route
          path="*"
          element={<BloxLoadingSplash message="Loading sites..." />}
        />
      </Routes>
    );
  },
);

RouteWithTimeoutWarning.displayName = 'LoadingWithTimeout';
